import { DestroyResponse } from '../../../../../tsProtoCodegen/service';
import metadataWithJwt from '../../../../../utils/metadataWithJwt';
import serviceClient from '../../../../../utils/serviceClient';

const destroy = async (
  id: string,
  onPending: () => void,
  onSuccess: (res: DestroyResponse) => void,
  onError: (e: string) => void
) => {
  try {
    onPending();

    const res = await serviceClient.destroy(
      { id },
      { metadata: metadataWithJwt() }
    );

    onSuccess(res);
  } catch (e: any) {
    onError(e.details);
  }
};

export default destroy;
