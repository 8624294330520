import React, { PropsWithChildren, ReactElement } from 'react';

const ErrorElem: React.FC<PropsWithChildren & { show?: boolean | undefined }> = ({ children, show }): ReactElement => {
  if (show === false) return <></>;
  
  return (
    <div>{children}</div>
  );
};

export default ErrorElem;
