import React, { ReactElement } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import Create from '../Create/Create';
import copy from '../../../utils/copy';

const CreateModal: React.FC<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }): ReactElement => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create {copy.entities.ServiceGroup}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Create />
        </ModalBody>
      </ModalContent>
    </Modal>

  );
};

export default CreateModal;
