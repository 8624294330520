import { errorDetailsClientMiddleware } from 'nice-grpc-error-details';
import { createChannel, createClientFactory } from 'nice-grpc-web';
import { ApiDefinition } from '../../tsProtoCodegen/ServiceGroup';

const channel = createChannel(process.env.REACT_APP_SERVICE_GROUP_GRPC_URL as string);

const grpcClient = createClientFactory()
  .use(errorDetailsClientMiddleware)
  .create(ApiDefinition, channel);

export default grpcClient;
