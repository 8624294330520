
import { createChannel, createClientFactory } from 'nice-grpc-web';
import { APIDefinition } from '../../tsProtoCodegen/user';
import { errorDetailsClientMiddleware } from 'nice-grpc-error-details';

const channel = createChannel(process.env.REACT_APP_USER_SERVICE_GRPC_URL as string);

const grpcClient = createClientFactory()
  .use(errorDetailsClientMiddleware)
  .create(APIDefinition, channel);

export default grpcClient;
