import React, { ReactElement, useContext, useEffect, useState } from 'react';
import PaymentMethod from './PaymentMethod/PaymentMethods';
import { useDispatch, useSelector } from 'react-redux';
import store, { State } from '../../reduxStore/reduxStore';
import useBillingActivity from './useBillingActivity';
import Activity from './Activity/Activity';
import RemainingFunds from './RemainingFunds/RemainingFunds';
import { ShowResponse as CustomerShowResponse } from '../../tsProtoCodegen/billing.customer';
import notNull from './Customer/selectors/notNull';
import { useTitleContext } from '../../Contexts/TitleContext';
import { BalanceContext } from '../../Contexts/BalanceContext';
import fetchPaymentMethodsThunk from './PaymentMethod/redux/fetchPaymentMethodsThunk';
import { PaymentMethodState } from './PaymentMethod/redux/types';
import Title from '../../Components/Layouts/LoggedIn/Title';
import { Card, Grid, GridItem, Icon } from '@chakra-ui/react';
import Deposit from './Deposit/Deposit';
import { UserState } from '../User/userSlice';
import { BiCreditCard } from 'react-icons/bi';

const Billing: React.FC = (): ReactElement => {
  const { customer } = useSelector<State, UserState>(({ user }) => user);

  if (!customer) throw new Error('Customer not found');
  const { externalId: stripeCustomerId, id: customerId } = customer;

  const paymentMethodState = useSelector<State, PaymentMethodState>(state => state.paymentMethod);
  const [checked, setChecked] = useState<boolean>(false);
  const dispatch = useDispatch<typeof store.dispatch>();

  useEffect(() => {
    if (!checked) {
      setChecked(true);

      dispatch(fetchPaymentMethodsThunk(stripeCustomerId as string));
    }
  }, [checked, dispatch, stripeCustomerId]);


  const { setTitle } = useTitleContext();
  setTitle('Billing');

  const billingActivity = useBillingActivity(customerId);
  const { balance } = useContext(BalanceContext);

  return (
    <>
      <Title>Billing</Title>
      <Grid gap={6} textAlign='left'>
        <GridItem>
          <Card p={6}>
            <h2><Icon mb={-1} mr={3} as={BiCreditCard} />Payment Methods</h2>
            <PaymentMethod stripeCustomerId={stripeCustomerId} paymentMethodState={paymentMethodState} />
          </Card>
        </GridItem>
        <GridItem>
          <Card p={6}>
            <h2>Billing Activity</h2>
            <Activity {...billingActivity} />
          </Card>
        </GridItem>
        <GridItem>
          <Card p={6}>
            <h2>Funds available</h2>
            <RemainingFunds {...balance} />
          </Card>
        </GridItem>
        <GridItem>
          <Card p={6}>
            <h2>Add Funds</h2>
            <Deposit customerId={customerId} paymentMethodState={paymentMethodState} />
          </Card>
        </GridItem>
      </Grid>
    </>
  );
};

export default Billing;
