import React, { ReactElement, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { CreateRequest } from '../../../tsProtoCodegen/ServiceGroup';
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Spinner,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import Price from './Price';
import { DollarValue } from '../../../utils/centsToDollars';
import DnsResolver from './DnsResolver';
import copy from '../../../utils/copy';

type SetErrors = {
  (errors: string[]): void;
}

const Form: React.FC<{ userId: string, onSubmit: SubmitHandler<CreateRequest>, setErrors: SetErrors, submitting: boolean }> = ({ userId, onSubmit, submitting }): ReactElement => {
  const defaultValues: CreateRequest = {
    numberOfNodes: 3,
    nodeSize: '',
    userId,
    name: '',
    domain: '',
    subdomain: '',
    certbotAdminEmail: '',
    digitalOceanDns: { access_token: '' },
  };

  const {
    register,
    handleSubmit,
    getValues,
  } = useForm({ defaultValues });

  const [priceHourly, setPriceHourly] = useState<DollarValue | null>(null);

  const getPriceInput = () => {
    return {
      nodeSize: getValues('nodeSize'),
      numberOfNodes: getValues('numberOfNodes'),
    };
  };
  const [priceInput, setPriceInput] = useState<{ nodeSize: string, numberOfNodes: number }>(getPriceInput());

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>

        {submitting && <>Creating {copy.entities.ServiceGroup}...<Spinner /></>}

        <Grid gap={4} templateColumns='repeat(2, 1fr)' templateRows='repeat(4, 1fr'>
          <GridItem colSpan={2}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                isInvalid={true}
                type="text"
                placeholder="Name"
                {...register('name', { required: true, maxLength: 80 })}
              />
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl>
              <FormLabel>Subdomain</FormLabel>
              <Input type="text" placeholder="Sub Domain" {...register('subdomain', { max: 3, min: 2 })} />
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl>
              <FormLabel>Base Domain</FormLabel>
              <Input type="text" placeholder="mysite.com" {...register('domain', { required: true, })} />
            </FormControl>
          </GridItem>


          <GridItem colSpan={2}>
            <DnsResolver register={register} />
          </GridItem>

          <GridItem colSpan={2}>
            <FormControl>
              <FormLabel>Email for SSL Cert. Notifications</FormLabel>
              <Input
                errorBorderColor='crimson'
                isInvalid
                type="text"
                placeholder="SSL Certificate Renewal Email"
                {...register('certbotAdminEmail', { required: true, max: 3, min: 2 })}
              />
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl>
              <FormLabel>Number of Nodes</FormLabel>
              <NumberInput min={3} max={6}>
                <NumberInputField {...register('numberOfNodes', { required: true, max: 19, min: 3, onChange: () => setPriceInput(getPriceInput()) })} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl>
              <FormLabel>Node Size</FormLabel>
              <Select placeholder='Node size'{...register('nodeSize', { required: true, onChange: () => setPriceInput(getPriceInput()) })}>
                <option value="s-4vcpu-8gb">s-4vcpu-8gb</option>
                <option value="s-4vcpu-8gb-amd">s-4vcpu-8gb-amd</option>
                <option value="s-4vcpu-8gb-intel">s-4vcpu-8gb-intel</option>
                <option value="g-2vcpu-8gb">g-2vcpu-8gb</option>
                <option value="s-4vcpu-8gb-240gb-intel">s-4vcpu-8gb-240gb-intel</option>
                <option value="gd-2vcpu-8gb">gd-2vcpu-8gb</option>
                <option value="g-2vcpu-8gb-intel">g-2vcpu-8gb-intel</option>
                <option value="gd-2vcpu-8gb-intel">gd-2vcpu-8gb-intel</option>
                <option value="s-4vcpu-16gb-amd">s-4vcpu-16gb-amd</option>
                <option value="m-2vcpu-16gb">m-2vcpu-16gb</option>
                <option value="c-4">c-4-8GiB</option>
                <option value="s-4vcpu-16gb-320gb-intel">s-4vcpu-16gb-320gb-intel</option>
                <option value="s-8vcpu-16gb">s-8vcpu-16gb</option>
                <option value="m3-2vcpu-16gb">m3-2vcpu-16gb</option>
                <option value="c-4-intel">c-4-intel</option>
                <option value="s-8vcpu-16gb-amd">s-8vcpu-16gb-amd</option>
                <option value="s-8vcpu-16gb-intel">s-8vcpu-16gb-intel</option>
                <option value="c2-4vcpu-8gb-intel">c2-4vcpu-8gb-intel</option>
                <option value="g-4vcpu-16gb">g-4vcpu-16gb</option>
                <option value="s-8vcpu-16gb-480gb-intel">s-8vcpu-16gb-480gb-intel</option>
                <option value="m6-2vcpu-16gb">m6-2vcpu-16gb</option>
                <option value="gd-4vcpu-16gb">gd-4vcpu-16gb</option>
                <option value="g-4vcpu-16gb-intel">g-4vcpu-16gb-intel</option>
                <option value="gd-4vcpu-16gb-intel">gd-4vcpu-16gb-intel</option>
                <option value="so1_5-2vcpu-16gb">so1_5-2vcpu-16gb</option>
                <option value="s-8vcpu-32gb-amd">s-8vcpu-32gb-amd</option>
                <option value="m-4vcpu-32gb">m-4vcpu-32gb</option>
                <option value="c-8">c-8-16GiB</option>
                <option value="s-8vcpu-32gb-640gb-intel">s-8vcpu-32gb-640gb-intel</option>
                <option value="m3-4vcpu-32gb">m3-4vcpu-32gb</option>
                <option value="c-8-intel">c-8-intel</option>
                <option value="c2-8vcpu-16gb-intel">c2-8vcpu-16gb-intel</option>
                <option value="g-8vcpu-32gb">g-8vcpu-32gb</option>
                <option value="m6-4vcpu-32gb">m6-4vcpu-32gb</option>
                <option value="gd-8vcpu-32gb">gd-8vcpu-32gb</option>
                <option value="g-8vcpu-32gb-intel">g-8vcpu-32gb-intel</option>
                <option value="gd-8vcpu-32gb-intel">gd-8vcpu-32gb-intel</option>
                <option value="so1_5-4vcpu-32gb">so1_5-4vcpu-32gb</option>
                <option value="m-8vcpu-64gb">m-8vcpu-64gb</option>
                <option value="c-16">c-16</option>
                <option value="m3-8vcpu-64gb">m3-8vcpu-64gb</option>
                <option value="c-16-intel">c-16-intel</option>
                <option value="c2-16vcpu-32gb-intel">c2-16vcpu-32gb-intel</option>
                <option value="g-16vcpu-64gb">g-16vcpu-64gb</option>
                <option value="m6-8vcpu-64gb">m6-8vcpu-64gb</option>
                <option value="gd-16vcpu-64gb">gd-16vcpu-64gb</option>
                <option value="g-16vcpu-64gb-intel">g-16vcpu-64gb-intel</option>
                <option value="gd-16vcpu-64gb-intel">gd-16vcpu-64gb-intel</option>
                <option value="so1_5-8vcpu-64gb">so1_5-8vcpu-64gb</option>
                <option value="m-16vcpu-128gb">m-16vcpu-128gb</option>
                <option value="c-32">c-32-64GiB</option>
                <option value="m3-16vcpu-128gb">m3-16vcpu-128gb</option>
                <option value="c-32-intel">c-32-intel</option>
                <option value="c2-32vcpu-64gb-intel">c2-32vcpu-64gb-intel</option>
                <option value="c-48">c-48</option>
                {/* <option value="m-24vcpu-192gb">m-24vcpu-192gb</option>
                <option value="g-32vcpu-128gb">g-32vcpu-128gb</option>
                <option value="m6-16vcpu-128gb">m6-16vcpu-128gb</option>
                <option value="gd-32vcpu-128gb">gd-32vcpu-128gb</option>
                <option value="g-32vcpu-128gb-intel">g-32vcpu-128gb-intel</option>
                <option value="m3-24vcpu-192gb">m3-24vcpu-192gb</option>
                <option value="g-40vcpu-160gb">g-40vcpu-160gb</option>
                <option value="gd-32vcpu-128gb-intel">gd-32vcpu-128gb-intel</option>
                <option value="so1_5-16vcpu-128gb">so1_5-16vcpu-128gb</option>
                <option value="c-48-intel">c-48-intel</option>
                <option value="m-32vcpu-256gb">m-32vcpu-256gb</option>
                <option value="gd-40vcpu-160gb">gd-40vcpu-160gb</option>
                <option value="c2-48vcpu-96gb-intel">c2-48vcpu-96gb-intel</option>
                <option value="m6-24vcpu-192gb">m6-24vcpu-192gb</option>
                <option value="m3-32vcpu-256gb">m3-32vcpu-256gb</option>
                <option value="g-48vcpu-192gb-intel">g-48vcpu-192gb-intel</option>
                <option value="gd-48vcpu-192gb-intel">gd-48vcpu-192gb-intel</option>
                <option value="so1_5-24vcpu-192gb">so1_5-24vcpu-192gb</option>
                <option value="m6-32vcpu-256gb">m6-32vcpu-256gb</option>
                <option value="so1_5-32vcpu-256gb">so1_5-32vcpu-256gb</option> */}
              </Select>
            </FormControl>
          </GridItem>

          <GridItem colSpan={2} pt={4} textAlign='center'>
            <Price
              priceInput={priceInput}
              priceHourly={priceHourly}
              setPriceHourly={setPriceHourly}
            />
          </GridItem>

          <GridItem colSpan={2} pt={4} textAlign='center'>
            {!submitting && <Button type="submit">Create!</Button>}
            {submitting && <Button disabled>Submitting</Button>}

          </GridItem>

          <input type="hidden" placeholder="..." {...register('userId', { required: true })} />
        </Grid>
      </form >
    </>
  );
};

export default Form;
