import React, { ReactElement, useContext } from 'react';
import {
  Box,
  Text,
  Spinner,
  Grid,
  GridItem,
  Show
} from '@chakra-ui/react';
import NavButton from './NavButton';
import { BalanceContext, Balance } from '../../Contexts/BalanceContext';
import centsToDollars from '../../utils/centsToDollars';
import copy from '../../utils/copy';
import { colors } from '../../theme/theme';
import Logo from './Logo';

const getBalance = (balance: Balance) => {
  if (balance.loading || !balance.data) {
    return (
      <Box textAlign='center'>
        <Text>Available Balance:</Text>
        <Text color='brand.purple.100' fontSize='2xl'><Spinner /></Text>
      </Box>
    );
  }

  return (
    <Box textAlign='center'>
      <Text>Available Balance:</Text>
      <Text color='brand.purple.100' fontSize='2xl'>{centsToDollars(balance.data.balance).pretty}</Text>
    </Box>
  );
};

const Nav: React.FC = (): ReactElement => {
  const { balance } = useContext(BalanceContext);

  return (
    <Grid
      h='100%'
      bgColor={colors.brand.purple[700]}
      pt={6}
      templateRows='repeat(12, 1fr)'
    >
      <GridItem
        pl={8}
        rowSpan={3}
      >
        <Logo />
      </GridItem>
      <GridItem
        pl={12}
        rowSpan={8}
      >
        <NavButton
          to='/service-groups'
          icon='/images/icon-cube.svg'
        >{copy.entities.ServiceGroup}s</NavButton>

        <NavButton
          to='/billing'
          icon='/images/icon-dollar.svg'
        >Billing</NavButton>
      </GridItem>
      <GridItem>
        {getBalance(balance)}
      </GridItem>
    </Grid >

  );

};

export default Nav;
