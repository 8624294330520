import { Metadata } from 'nice-grpc-web';

const metadataWithJwt = (): Metadata => {
  const metadata = new Metadata();

  metadata.set('authorization', `Bearer ${localStorage.getItem('jwt')}`);

  return metadata;
};

export default metadataWithJwt;
