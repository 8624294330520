import {
  CreateRequest,
  CreateResponse,
} from '../../../../tsProtoCodegen/service';
import serviceClient from '../../../../utils/serviceClient';
import { Metadata, Status } from 'nice-grpc-web';
import { RemoteResource, RemoteResourceStatus } from '../Secrets/Secrets';

const onSubmit = async (
  req: CreateRequest,
  createService: CreateResponse & RemoteResource,
  setCreateService: React.Dispatch<
    React.SetStateAction<CreateResponse & RemoteResource>
  >,
  update: () => void
): Promise<void> => {
  try {
    setCreateService({
      status: RemoteResourceStatus.LOADING,
      error: undefined,
    });

    const res = await serviceClient.create(req, {
      metadata: new Metadata({
        authorization: `Bearer ${localStorage.getItem('jwt')}`,
      }),
    });

    setCreateService({ ...res, status: RemoteResourceStatus.LOADED });
    update();
  } catch (e: any) {
    if (e.code === Status.UNKNOWN) {
      setCreateService({
        status: RemoteResourceStatus.ERROR,
        error: 'There was an issue creating the service. Please try again.',
      });
    } else {
      setCreateService({
        status: RemoteResourceStatus.ERROR,
        error: e.details,
      });
    }
  }
};

export default onSubmit;
