import { ShowResponse } from '../tsProtoCodegen/ServiceGroup';

const getDomain = (serviceGroup: ShowResponse) => {
  const { subdomain, domain } = serviceGroup;

  if (subdomain && subdomain !== '') {
    return `${subdomain}.${domain}`;
  }

  return domain;
};
export default getDomain;
