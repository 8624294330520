/* eslint-disable */
import Long from "long";
import type { CallContext, CallOptions } from "nice-grpc-common";
import _m0 from "protobufjs/minimal";
import { ClusterPriceHourly, ShowResponse, Volume, WhereRequest } from "./ServiceGroup";

export const protobufPackage = "ServiceGroup";

export enum KafkaTopics {
  SERVICE_GROUP_CREATED = "SERVICE_GROUP_CREATED",
  SERVICE_GROUP_STATUS_UPDATED = "SERVICE_GROUP_STATUS_UPDATED",
  SERVICE_GROUP_K8S_CLUSTER_CREATION_INITIALIZED = "SERVICE_GROUP_K8S_CLUSTER_CREATION_INITIALIZED",
  SERVICE_GROUP_K8S_CLUSTER_CREATION_FAILED = "SERVICE_GROUP_K8S_CLUSTER_CREATION_FAILED",
  SERVICE_GROUP_K8S_CLUSTER_CREATION_FAILED_VERSION_SLUG = "SERVICE_GROUP_K8S_CLUSTER_CREATION_FAILED_VERSION_SLUG",
  SERVICE_GROUP_K8S_CLUSTER_CREATED = "SERVICE_GROUP_K8S_CLUSTER_CREATED",
  SERVICE_GROUP_K8S_CLUSTER_HELM_INSTALL_INITIALIZED = "SERVICE_GROUP_K8S_CLUSTER_HELM_INSTALL_INITIALIZED",
  SERVICE_GROUP_K8S_CLUSTER_READY = "SERVICE_GROUP_K8S_CLUSTER_READY",
  SERVICE_GROUP_DESTROYED_COMPLETELY = "SERVICE_GROUP_DESTROYED_COMPLETELY",
  SERVICE_GROUP_K8S_CLUSTER_CREATE_DATABASE = "SERVICE_GROUP_K8S_CLUSTER_CREATE_DATABASE",
  SERVICE_GROUP_DNS_SETUP_JOB_RUNNING = "SERVICE_GROUP_DNS_SETUP_JOB_RUNNING",
  SERVICE_GROUP_DNS_SETUP_SUCCESS = "SERVICE_GROUP_DNS_SETUP_SUCCESS",
  SERVICE_GROUP_DNS_SETUP_FAILED = "SERVICE_GROUP_DNS_SETUP_FAILED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function kafkaTopicsFromJSON(object: any): KafkaTopics {
  switch (object) {
    case 0:
    case "SERVICE_GROUP_CREATED":
      return KafkaTopics.SERVICE_GROUP_CREATED;
    case 1:
    case "SERVICE_GROUP_STATUS_UPDATED":
      return KafkaTopics.SERVICE_GROUP_STATUS_UPDATED;
    case 2:
    case "SERVICE_GROUP_K8S_CLUSTER_CREATION_INITIALIZED":
      return KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_CREATION_INITIALIZED;
    case 3:
    case "SERVICE_GROUP_K8S_CLUSTER_CREATION_FAILED":
      return KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_CREATION_FAILED;
    case 13:
    case "SERVICE_GROUP_K8S_CLUSTER_CREATION_FAILED_VERSION_SLUG":
      return KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_CREATION_FAILED_VERSION_SLUG;
    case 4:
    case "SERVICE_GROUP_K8S_CLUSTER_CREATED":
      return KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_CREATED;
    case 5:
    case "SERVICE_GROUP_K8S_CLUSTER_HELM_INSTALL_INITIALIZED":
      return KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_HELM_INSTALL_INITIALIZED;
    case 7:
    case "SERVICE_GROUP_K8S_CLUSTER_READY":
      return KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_READY;
    case 8:
    case "SERVICE_GROUP_DESTROYED_COMPLETELY":
      return KafkaTopics.SERVICE_GROUP_DESTROYED_COMPLETELY;
    case 9:
    case "SERVICE_GROUP_K8S_CLUSTER_CREATE_DATABASE":
      return KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_CREATE_DATABASE;
    case 10:
    case "SERVICE_GROUP_DNS_SETUP_JOB_RUNNING":
      return KafkaTopics.SERVICE_GROUP_DNS_SETUP_JOB_RUNNING;
    case 11:
    case "SERVICE_GROUP_DNS_SETUP_SUCCESS":
      return KafkaTopics.SERVICE_GROUP_DNS_SETUP_SUCCESS;
    case 12:
    case "SERVICE_GROUP_DNS_SETUP_FAILED":
      return KafkaTopics.SERVICE_GROUP_DNS_SETUP_FAILED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return KafkaTopics.UNRECOGNIZED;
  }
}

export function kafkaTopicsToJSON(object: KafkaTopics): string {
  switch (object) {
    case KafkaTopics.SERVICE_GROUP_CREATED:
      return "SERVICE_GROUP_CREATED";
    case KafkaTopics.SERVICE_GROUP_STATUS_UPDATED:
      return "SERVICE_GROUP_STATUS_UPDATED";
    case KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_CREATION_INITIALIZED:
      return "SERVICE_GROUP_K8S_CLUSTER_CREATION_INITIALIZED";
    case KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_CREATION_FAILED:
      return "SERVICE_GROUP_K8S_CLUSTER_CREATION_FAILED";
    case KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_CREATION_FAILED_VERSION_SLUG:
      return "SERVICE_GROUP_K8S_CLUSTER_CREATION_FAILED_VERSION_SLUG";
    case KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_CREATED:
      return "SERVICE_GROUP_K8S_CLUSTER_CREATED";
    case KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_HELM_INSTALL_INITIALIZED:
      return "SERVICE_GROUP_K8S_CLUSTER_HELM_INSTALL_INITIALIZED";
    case KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_READY:
      return "SERVICE_GROUP_K8S_CLUSTER_READY";
    case KafkaTopics.SERVICE_GROUP_DESTROYED_COMPLETELY:
      return "SERVICE_GROUP_DESTROYED_COMPLETELY";
    case KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_CREATE_DATABASE:
      return "SERVICE_GROUP_K8S_CLUSTER_CREATE_DATABASE";
    case KafkaTopics.SERVICE_GROUP_DNS_SETUP_JOB_RUNNING:
      return "SERVICE_GROUP_DNS_SETUP_JOB_RUNNING";
    case KafkaTopics.SERVICE_GROUP_DNS_SETUP_SUCCESS:
      return "SERVICE_GROUP_DNS_SETUP_SUCCESS";
    case KafkaTopics.SERVICE_GROUP_DNS_SETUP_FAILED:
      return "SERVICE_GROUP_DNS_SETUP_FAILED";
    case KafkaTopics.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function kafkaTopicsToNumber(object: KafkaTopics): number {
  switch (object) {
    case KafkaTopics.SERVICE_GROUP_CREATED:
      return 0;
    case KafkaTopics.SERVICE_GROUP_STATUS_UPDATED:
      return 1;
    case KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_CREATION_INITIALIZED:
      return 2;
    case KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_CREATION_FAILED:
      return 3;
    case KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_CREATION_FAILED_VERSION_SLUG:
      return 13;
    case KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_CREATED:
      return 4;
    case KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_HELM_INSTALL_INITIALIZED:
      return 5;
    case KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_READY:
      return 7;
    case KafkaTopics.SERVICE_GROUP_DESTROYED_COMPLETELY:
      return 8;
    case KafkaTopics.SERVICE_GROUP_K8S_CLUSTER_CREATE_DATABASE:
      return 9;
    case KafkaTopics.SERVICE_GROUP_DNS_SETUP_JOB_RUNNING:
      return 10;
    case KafkaTopics.SERVICE_GROUP_DNS_SETUP_SUCCESS:
      return 11;
    case KafkaTopics.SERVICE_GROUP_DNS_SETUP_FAILED:
      return 12;
    case KafkaTopics.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum CreateDatabaseError {
  CREATE_DATABASE_ERROR_UNSPECIFIED = "CREATE_DATABASE_ERROR_UNSPECIFIED",
  CREATE_DATABASE_ERROR_DUPLICATE = "CREATE_DATABASE_ERROR_DUPLICATE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function createDatabaseErrorFromJSON(object: any): CreateDatabaseError {
  switch (object) {
    case 0:
    case "CREATE_DATABASE_ERROR_UNSPECIFIED":
      return CreateDatabaseError.CREATE_DATABASE_ERROR_UNSPECIFIED;
    case 1:
    case "CREATE_DATABASE_ERROR_DUPLICATE":
      return CreateDatabaseError.CREATE_DATABASE_ERROR_DUPLICATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CreateDatabaseError.UNRECOGNIZED;
  }
}

export function createDatabaseErrorToJSON(object: CreateDatabaseError): string {
  switch (object) {
    case CreateDatabaseError.CREATE_DATABASE_ERROR_UNSPECIFIED:
      return "CREATE_DATABASE_ERROR_UNSPECIFIED";
    case CreateDatabaseError.CREATE_DATABASE_ERROR_DUPLICATE:
      return "CREATE_DATABASE_ERROR_DUPLICATE";
    case CreateDatabaseError.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function createDatabaseErrorToNumber(object: CreateDatabaseError): number {
  switch (object) {
    case CreateDatabaseError.CREATE_DATABASE_ERROR_UNSPECIFIED:
      return 0;
    case CreateDatabaseError.CREATE_DATABASE_ERROR_DUPLICATE:
      return 1;
    case CreateDatabaseError.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** Maintained in Schema.prisma */
export enum Status {
  CREATION_INITIALIZED = "CREATION_INITIALIZED",
  K8S_CLUSTER_CREATION_INITIALIZED = "K8S_CLUSTER_CREATION_INITIALIZED",
  K8S_CLUSTER_PROVISIONING = "K8S_CLUSTER_PROVISIONING",
  K8S_CLUSTER_CREATION_FAILED = "K8S_CLUSTER_CREATION_FAILED",
  K8S_CLUSTER_CREATED = "K8S_CLUSTER_CREATED",
  K8S_CLUSTER_INSTALLING_HELM_CHARTS = "K8S_CLUSTER_INSTALLING_HELM_CHARTS",
  K8S_CLUSTER_INSTALLING_HELM_CHARTS_FAILED = "K8S_CLUSTER_INSTALLING_HELM_CHARTS_FAILED",
  K8S_CLUSTER_HELM_CHARTS_INSTALLED = "K8S_CLUSTER_HELM_CHARTS_INSTALLED",
  K8S_CLUSTER_DESTROY_INITIALIZED = "K8S_CLUSTER_DESTROY_INITIALIZED",
  K8S_CLUSTER_DESTROYED = "K8S_CLUSTER_DESTROYED",
  K8S_CLUSTER_ORPHANED = "K8S_CLUSTER_ORPHANED",
  K8S_CLUSTER_READY_FAILED = "K8S_CLUSTER_READY_FAILED",
  READY = "READY",
  JENKINS_API_KEY_CREATION_FAILED = "JENKINS_API_KEY_CREATION_FAILED",
  K8S_CLUSTER_CREATING_SECRETS = "K8S_CLUSTER_CREATING_SECRETS",
  UPDATING = "UPDATING",
  K8S_CLUSTER_CREATING_SECRETS_FAILED = "K8S_CLUSTER_CREATING_SECRETS_FAILED",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function statusFromJSON(object: any): Status {
  switch (object) {
    case 0:
    case "CREATION_INITIALIZED":
      return Status.CREATION_INITIALIZED;
    case 1:
    case "K8S_CLUSTER_CREATION_INITIALIZED":
      return Status.K8S_CLUSTER_CREATION_INITIALIZED;
    case 2:
    case "K8S_CLUSTER_PROVISIONING":
      return Status.K8S_CLUSTER_PROVISIONING;
    case 3:
    case "K8S_CLUSTER_CREATION_FAILED":
      return Status.K8S_CLUSTER_CREATION_FAILED;
    case 4:
    case "K8S_CLUSTER_CREATED":
      return Status.K8S_CLUSTER_CREATED;
    case 5:
    case "K8S_CLUSTER_INSTALLING_HELM_CHARTS":
      return Status.K8S_CLUSTER_INSTALLING_HELM_CHARTS;
    case 6:
    case "K8S_CLUSTER_INSTALLING_HELM_CHARTS_FAILED":
      return Status.K8S_CLUSTER_INSTALLING_HELM_CHARTS_FAILED;
    case 7:
    case "K8S_CLUSTER_HELM_CHARTS_INSTALLED":
      return Status.K8S_CLUSTER_HELM_CHARTS_INSTALLED;
    case 8:
    case "K8S_CLUSTER_DESTROY_INITIALIZED":
      return Status.K8S_CLUSTER_DESTROY_INITIALIZED;
    case 9:
    case "K8S_CLUSTER_DESTROYED":
      return Status.K8S_CLUSTER_DESTROYED;
    case 10:
    case "K8S_CLUSTER_ORPHANED":
      return Status.K8S_CLUSTER_ORPHANED;
    case 11:
    case "K8S_CLUSTER_READY_FAILED":
      return Status.K8S_CLUSTER_READY_FAILED;
    case 12:
    case "READY":
      return Status.READY;
    case 13:
    case "JENKINS_API_KEY_CREATION_FAILED":
      return Status.JENKINS_API_KEY_CREATION_FAILED;
    case 14:
    case "K8S_CLUSTER_CREATING_SECRETS":
      return Status.K8S_CLUSTER_CREATING_SECRETS;
    case 15:
    case "UPDATING":
      return Status.UPDATING;
    case 16:
    case "K8S_CLUSTER_CREATING_SECRETS_FAILED":
      return Status.K8S_CLUSTER_CREATING_SECRETS_FAILED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Status.UNRECOGNIZED;
  }
}

export function statusToJSON(object: Status): string {
  switch (object) {
    case Status.CREATION_INITIALIZED:
      return "CREATION_INITIALIZED";
    case Status.K8S_CLUSTER_CREATION_INITIALIZED:
      return "K8S_CLUSTER_CREATION_INITIALIZED";
    case Status.K8S_CLUSTER_PROVISIONING:
      return "K8S_CLUSTER_PROVISIONING";
    case Status.K8S_CLUSTER_CREATION_FAILED:
      return "K8S_CLUSTER_CREATION_FAILED";
    case Status.K8S_CLUSTER_CREATED:
      return "K8S_CLUSTER_CREATED";
    case Status.K8S_CLUSTER_INSTALLING_HELM_CHARTS:
      return "K8S_CLUSTER_INSTALLING_HELM_CHARTS";
    case Status.K8S_CLUSTER_INSTALLING_HELM_CHARTS_FAILED:
      return "K8S_CLUSTER_INSTALLING_HELM_CHARTS_FAILED";
    case Status.K8S_CLUSTER_HELM_CHARTS_INSTALLED:
      return "K8S_CLUSTER_HELM_CHARTS_INSTALLED";
    case Status.K8S_CLUSTER_DESTROY_INITIALIZED:
      return "K8S_CLUSTER_DESTROY_INITIALIZED";
    case Status.K8S_CLUSTER_DESTROYED:
      return "K8S_CLUSTER_DESTROYED";
    case Status.K8S_CLUSTER_ORPHANED:
      return "K8S_CLUSTER_ORPHANED";
    case Status.K8S_CLUSTER_READY_FAILED:
      return "K8S_CLUSTER_READY_FAILED";
    case Status.READY:
      return "READY";
    case Status.JENKINS_API_KEY_CREATION_FAILED:
      return "JENKINS_API_KEY_CREATION_FAILED";
    case Status.K8S_CLUSTER_CREATING_SECRETS:
      return "K8S_CLUSTER_CREATING_SECRETS";
    case Status.UPDATING:
      return "UPDATING";
    case Status.K8S_CLUSTER_CREATING_SECRETS_FAILED:
      return "K8S_CLUSTER_CREATING_SECRETS_FAILED";
    case Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function statusToNumber(object: Status): number {
  switch (object) {
    case Status.CREATION_INITIALIZED:
      return 0;
    case Status.K8S_CLUSTER_CREATION_INITIALIZED:
      return 1;
    case Status.K8S_CLUSTER_PROVISIONING:
      return 2;
    case Status.K8S_CLUSTER_CREATION_FAILED:
      return 3;
    case Status.K8S_CLUSTER_CREATED:
      return 4;
    case Status.K8S_CLUSTER_INSTALLING_HELM_CHARTS:
      return 5;
    case Status.K8S_CLUSTER_INSTALLING_HELM_CHARTS_FAILED:
      return 6;
    case Status.K8S_CLUSTER_HELM_CHARTS_INSTALLED:
      return 7;
    case Status.K8S_CLUSTER_DESTROY_INITIALIZED:
      return 8;
    case Status.K8S_CLUSTER_DESTROYED:
      return 9;
    case Status.K8S_CLUSTER_ORPHANED:
      return 10;
    case Status.K8S_CLUSTER_READY_FAILED:
      return 11;
    case Status.READY:
      return 12;
    case Status.JENKINS_API_KEY_CREATION_FAILED:
      return 13;
    case Status.K8S_CLUSTER_CREATING_SECRETS:
      return 14;
    case Status.UPDATING:
      return 15;
    case Status.K8S_CLUSTER_CREATING_SECRETS_FAILED:
      return 16;
    case Status.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface StatusUpdatedResponse {
  serviceGroup: ShowResponse | undefined;
  previousStatus: Status;
}

export interface PriceHourlyRequest {
  numberOfNodes: number;
  nodeSize: string;
}

export interface PriceHourlyResponse {
  clusterPriceHourly: ClusterPriceHourly | undefined;
  numberOfNodes: number;
  nodeSize: string;
  volumes: Volume[];
}

export interface HoursActiveRequest {
  id: string;
}

export interface HoursActiveResponse {
  hoursActive: number;
  serviceGroup: ShowResponse | undefined;
}

export interface JenkinsCredentialsRequest {
  serviceGroupId: string;
}

export interface JenkinsCredentialsResponse {
  username: string;
  password: string;
}

export interface UpdateSecretWhereRequest {
  where: WhereRequest | undefined;
  secretName: string;
  dataJson: string;
}

export interface UpdateSecretWhereResponse {
}

export interface DestroyCompletelyRequest {
  where: WhereRequest | undefined;
}

function createBaseStatusUpdatedResponse(): StatusUpdatedResponse {
  return { serviceGroup: undefined, previousStatus: Status.CREATION_INITIALIZED };
}

export const StatusUpdatedResponse = {
  encode(message: StatusUpdatedResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serviceGroup !== undefined) {
      ShowResponse.encode(message.serviceGroup, writer.uint32(10).fork()).ldelim();
    }
    if (message.previousStatus !== Status.CREATION_INITIALIZED) {
      writer.uint32(16).int32(statusToNumber(message.previousStatus));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StatusUpdatedResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStatusUpdatedResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.serviceGroup = ShowResponse.decode(reader, reader.uint32());
          break;
        case 2:
          message.previousStatus = statusFromJSON(reader.int32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StatusUpdatedResponse {
    return {
      serviceGroup: isSet(object.serviceGroup) ? ShowResponse.fromJSON(object.serviceGroup) : undefined,
      previousStatus: isSet(object.previousStatus)
        ? statusFromJSON(object.previousStatus)
        : Status.CREATION_INITIALIZED,
    };
  },

  toJSON(message: StatusUpdatedResponse): unknown {
    const obj: any = {};
    message.serviceGroup !== undefined &&
      (obj.serviceGroup = message.serviceGroup ? ShowResponse.toJSON(message.serviceGroup) : undefined);
    message.previousStatus !== undefined && (obj.previousStatus = statusToJSON(message.previousStatus));
    return obj;
  },

  fromPartial(object: DeepPartial<StatusUpdatedResponse>): StatusUpdatedResponse {
    const message = createBaseStatusUpdatedResponse();
    message.serviceGroup = (object.serviceGroup !== undefined && object.serviceGroup !== null)
      ? ShowResponse.fromPartial(object.serviceGroup)
      : undefined;
    message.previousStatus = object.previousStatus ?? Status.CREATION_INITIALIZED;
    return message;
  },
};

function createBasePriceHourlyRequest(): PriceHourlyRequest {
  return { numberOfNodes: 0, nodeSize: "" };
}

export const PriceHourlyRequest = {
  encode(message: PriceHourlyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.numberOfNodes !== 0) {
      writer.uint32(8).int64(message.numberOfNodes);
    }
    if (message.nodeSize !== "") {
      writer.uint32(18).string(message.nodeSize);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PriceHourlyRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePriceHourlyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.numberOfNodes = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.nodeSize = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PriceHourlyRequest {
    return {
      numberOfNodes: isSet(object.numberOfNodes) ? Number(object.numberOfNodes) : 0,
      nodeSize: isSet(object.nodeSize) ? String(object.nodeSize) : "",
    };
  },

  toJSON(message: PriceHourlyRequest): unknown {
    const obj: any = {};
    message.numberOfNodes !== undefined && (obj.numberOfNodes = Math.round(message.numberOfNodes));
    message.nodeSize !== undefined && (obj.nodeSize = message.nodeSize);
    return obj;
  },

  fromPartial(object: DeepPartial<PriceHourlyRequest>): PriceHourlyRequest {
    const message = createBasePriceHourlyRequest();
    message.numberOfNodes = object.numberOfNodes ?? 0;
    message.nodeSize = object.nodeSize ?? "";
    return message;
  },
};

function createBasePriceHourlyResponse(): PriceHourlyResponse {
  return { clusterPriceHourly: undefined, numberOfNodes: 0, nodeSize: "", volumes: [] };
}

export const PriceHourlyResponse = {
  encode(message: PriceHourlyResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clusterPriceHourly !== undefined) {
      ClusterPriceHourly.encode(message.clusterPriceHourly, writer.uint32(10).fork()).ldelim();
    }
    if (message.numberOfNodes !== 0) {
      writer.uint32(16).int64(message.numberOfNodes);
    }
    if (message.nodeSize !== "") {
      writer.uint32(26).string(message.nodeSize);
    }
    for (const v of message.volumes) {
      Volume.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PriceHourlyResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePriceHourlyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.clusterPriceHourly = ClusterPriceHourly.decode(reader, reader.uint32());
          break;
        case 2:
          message.numberOfNodes = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.nodeSize = reader.string();
          break;
        case 4:
          message.volumes.push(Volume.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PriceHourlyResponse {
    return {
      clusterPriceHourly: isSet(object.clusterPriceHourly)
        ? ClusterPriceHourly.fromJSON(object.clusterPriceHourly)
        : undefined,
      numberOfNodes: isSet(object.numberOfNodes) ? Number(object.numberOfNodes) : 0,
      nodeSize: isSet(object.nodeSize) ? String(object.nodeSize) : "",
      volumes: Array.isArray(object?.volumes) ? object.volumes.map((e: any) => Volume.fromJSON(e)) : [],
    };
  },

  toJSON(message: PriceHourlyResponse): unknown {
    const obj: any = {};
    message.clusterPriceHourly !== undefined && (obj.clusterPriceHourly = message.clusterPriceHourly
      ? ClusterPriceHourly.toJSON(message.clusterPriceHourly)
      : undefined);
    message.numberOfNodes !== undefined && (obj.numberOfNodes = Math.round(message.numberOfNodes));
    message.nodeSize !== undefined && (obj.nodeSize = message.nodeSize);
    if (message.volumes) {
      obj.volumes = message.volumes.map((e) => e ? Volume.toJSON(e) : undefined);
    } else {
      obj.volumes = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<PriceHourlyResponse>): PriceHourlyResponse {
    const message = createBasePriceHourlyResponse();
    message.clusterPriceHourly = (object.clusterPriceHourly !== undefined && object.clusterPriceHourly !== null)
      ? ClusterPriceHourly.fromPartial(object.clusterPriceHourly)
      : undefined;
    message.numberOfNodes = object.numberOfNodes ?? 0;
    message.nodeSize = object.nodeSize ?? "";
    message.volumes = object.volumes?.map((e) => Volume.fromPartial(e)) || [];
    return message;
  },
};

function createBaseHoursActiveRequest(): HoursActiveRequest {
  return { id: "" };
}

export const HoursActiveRequest = {
  encode(message: HoursActiveRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HoursActiveRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHoursActiveRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): HoursActiveRequest {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: HoursActiveRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial(object: DeepPartial<HoursActiveRequest>): HoursActiveRequest {
    const message = createBaseHoursActiveRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseHoursActiveResponse(): HoursActiveResponse {
  return { hoursActive: 0, serviceGroup: undefined };
}

export const HoursActiveResponse = {
  encode(message: HoursActiveResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.hoursActive !== 0) {
      writer.uint32(8).int64(message.hoursActive);
    }
    if (message.serviceGroup !== undefined) {
      ShowResponse.encode(message.serviceGroup, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HoursActiveResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHoursActiveResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.hoursActive = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.serviceGroup = ShowResponse.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): HoursActiveResponse {
    return {
      hoursActive: isSet(object.hoursActive) ? Number(object.hoursActive) : 0,
      serviceGroup: isSet(object.serviceGroup) ? ShowResponse.fromJSON(object.serviceGroup) : undefined,
    };
  },

  toJSON(message: HoursActiveResponse): unknown {
    const obj: any = {};
    message.hoursActive !== undefined && (obj.hoursActive = Math.round(message.hoursActive));
    message.serviceGroup !== undefined &&
      (obj.serviceGroup = message.serviceGroup ? ShowResponse.toJSON(message.serviceGroup) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<HoursActiveResponse>): HoursActiveResponse {
    const message = createBaseHoursActiveResponse();
    message.hoursActive = object.hoursActive ?? 0;
    message.serviceGroup = (object.serviceGroup !== undefined && object.serviceGroup !== null)
      ? ShowResponse.fromPartial(object.serviceGroup)
      : undefined;
    return message;
  },
};

function createBaseJenkinsCredentialsRequest(): JenkinsCredentialsRequest {
  return { serviceGroupId: "" };
}

export const JenkinsCredentialsRequest = {
  encode(message: JenkinsCredentialsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serviceGroupId !== "") {
      writer.uint32(10).string(message.serviceGroupId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JenkinsCredentialsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJenkinsCredentialsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.serviceGroupId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): JenkinsCredentialsRequest {
    return { serviceGroupId: isSet(object.serviceGroupId) ? String(object.serviceGroupId) : "" };
  },

  toJSON(message: JenkinsCredentialsRequest): unknown {
    const obj: any = {};
    message.serviceGroupId !== undefined && (obj.serviceGroupId = message.serviceGroupId);
    return obj;
  },

  fromPartial(object: DeepPartial<JenkinsCredentialsRequest>): JenkinsCredentialsRequest {
    const message = createBaseJenkinsCredentialsRequest();
    message.serviceGroupId = object.serviceGroupId ?? "";
    return message;
  },
};

function createBaseJenkinsCredentialsResponse(): JenkinsCredentialsResponse {
  return { username: "", password: "" };
}

export const JenkinsCredentialsResponse = {
  encode(message: JenkinsCredentialsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.username !== "") {
      writer.uint32(10).string(message.username);
    }
    if (message.password !== "") {
      writer.uint32(18).string(message.password);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JenkinsCredentialsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJenkinsCredentialsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.username = reader.string();
          break;
        case 2:
          message.password = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): JenkinsCredentialsResponse {
    return {
      username: isSet(object.username) ? String(object.username) : "",
      password: isSet(object.password) ? String(object.password) : "",
    };
  },

  toJSON(message: JenkinsCredentialsResponse): unknown {
    const obj: any = {};
    message.username !== undefined && (obj.username = message.username);
    message.password !== undefined && (obj.password = message.password);
    return obj;
  },

  fromPartial(object: DeepPartial<JenkinsCredentialsResponse>): JenkinsCredentialsResponse {
    const message = createBaseJenkinsCredentialsResponse();
    message.username = object.username ?? "";
    message.password = object.password ?? "";
    return message;
  },
};

function createBaseUpdateSecretWhereRequest(): UpdateSecretWhereRequest {
  return { where: undefined, secretName: "", dataJson: "" };
}

export const UpdateSecretWhereRequest = {
  encode(message: UpdateSecretWhereRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.where !== undefined) {
      WhereRequest.encode(message.where, writer.uint32(10).fork()).ldelim();
    }
    if (message.secretName !== "") {
      writer.uint32(18).string(message.secretName);
    }
    if (message.dataJson !== "") {
      writer.uint32(26).string(message.dataJson);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateSecretWhereRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateSecretWhereRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.where = WhereRequest.decode(reader, reader.uint32());
          break;
        case 2:
          message.secretName = reader.string();
          break;
        case 3:
          message.dataJson = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UpdateSecretWhereRequest {
    return {
      where: isSet(object.where) ? WhereRequest.fromJSON(object.where) : undefined,
      secretName: isSet(object.secretName) ? String(object.secretName) : "",
      dataJson: isSet(object.dataJson) ? String(object.dataJson) : "",
    };
  },

  toJSON(message: UpdateSecretWhereRequest): unknown {
    const obj: any = {};
    message.where !== undefined && (obj.where = message.where ? WhereRequest.toJSON(message.where) : undefined);
    message.secretName !== undefined && (obj.secretName = message.secretName);
    message.dataJson !== undefined && (obj.dataJson = message.dataJson);
    return obj;
  },

  fromPartial(object: DeepPartial<UpdateSecretWhereRequest>): UpdateSecretWhereRequest {
    const message = createBaseUpdateSecretWhereRequest();
    message.where = (object.where !== undefined && object.where !== null)
      ? WhereRequest.fromPartial(object.where)
      : undefined;
    message.secretName = object.secretName ?? "";
    message.dataJson = object.dataJson ?? "";
    return message;
  },
};

function createBaseUpdateSecretWhereResponse(): UpdateSecretWhereResponse {
  return {};
}

export const UpdateSecretWhereResponse = {
  encode(_: UpdateSecretWhereResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateSecretWhereResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateSecretWhereResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): UpdateSecretWhereResponse {
    return {};
  },

  toJSON(_: UpdateSecretWhereResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<UpdateSecretWhereResponse>): UpdateSecretWhereResponse {
    const message = createBaseUpdateSecretWhereResponse();
    return message;
  },
};

function createBaseDestroyCompletelyRequest(): DestroyCompletelyRequest {
  return { where: undefined };
}

export const DestroyCompletelyRequest = {
  encode(message: DestroyCompletelyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.where !== undefined) {
      WhereRequest.encode(message.where, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DestroyCompletelyRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDestroyCompletelyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.where = WhereRequest.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DestroyCompletelyRequest {
    return { where: isSet(object.where) ? WhereRequest.fromJSON(object.where) : undefined };
  },

  toJSON(message: DestroyCompletelyRequest): unknown {
    const obj: any = {};
    message.where !== undefined && (obj.where = message.where ? WhereRequest.toJSON(message.where) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<DestroyCompletelyRequest>): DestroyCompletelyRequest {
    const message = createBaseDestroyCompletelyRequest();
    message.where = (object.where !== undefined && object.where !== null)
      ? WhereRequest.fromPartial(object.where)
      : undefined;
    return message;
  },
};

export type PrivateDefinition = typeof PrivateDefinition;
export const PrivateDefinition = {
  name: "Private",
  fullName: "ServiceGroup.Private",
  methods: {
    priceHourly: {
      name: "priceHourly",
      requestType: PriceHourlyRequest,
      requestStream: false,
      responseType: PriceHourlyResponse,
      responseStream: false,
      options: {},
    },
    hoursActive: {
      name: "hoursActive",
      requestType: HoursActiveRequest,
      requestStream: false,
      responseType: HoursActiveResponse,
      responseStream: false,
      options: {},
    },
    jenkinsCredentials: {
      name: "jenkinsCredentials",
      requestType: JenkinsCredentialsRequest,
      requestStream: false,
      responseType: JenkinsCredentialsResponse,
      responseStream: false,
      options: {},
    },
    updateSecretWhere: {
      name: "updateSecretWhere",
      requestType: UpdateSecretWhereRequest,
      requestStream: false,
      responseType: UpdateSecretWhereResponse,
      responseStream: false,
      options: {},
    },
    /** This should only be used in testing */
    destroyCompletely: {
      name: "destroyCompletely",
      requestType: DestroyCompletelyRequest,
      requestStream: false,
      responseType: ShowResponse,
      responseStream: false,
      options: {},
    },
  },
} as const;

export interface PrivateServiceImplementation<CallContextExt = {}> {
  priceHourly(
    request: PriceHourlyRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<PriceHourlyResponse>>;
  hoursActive(
    request: HoursActiveRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<HoursActiveResponse>>;
  jenkinsCredentials(
    request: JenkinsCredentialsRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<JenkinsCredentialsResponse>>;
  updateSecretWhere(
    request: UpdateSecretWhereRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<UpdateSecretWhereResponse>>;
  /** This should only be used in testing */
  destroyCompletely(
    request: DestroyCompletelyRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<ShowResponse>>;
}

export interface PrivateClient<CallOptionsExt = {}> {
  priceHourly(
    request: DeepPartial<PriceHourlyRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<PriceHourlyResponse>;
  hoursActive(
    request: DeepPartial<HoursActiveRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<HoursActiveResponse>;
  jenkinsCredentials(
    request: DeepPartial<JenkinsCredentialsRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<JenkinsCredentialsResponse>;
  updateSecretWhere(
    request: DeepPartial<UpdateSecretWhereRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<UpdateSecretWhereResponse>;
  /** This should only be used in testing */
  destroyCompletely(
    request: DeepPartial<DestroyCompletelyRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<ShowResponse>;
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
