import React, { ReactElement } from 'react';
import SessionCheck from '../SessionCheck/SessionCheck';
import { Box, Image, Button } from '@chakra-ui/react';

const Login: React.FC = (): ReactElement => {
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_REDIRECT_URL;

  return (
    <SessionCheck>
      <Box className='login-logo' mb='3rem' p='.2rem' pos='relative' zIndex={1}>
        <Image src='images/logo-full.svg' pb='0' width='130px' />
        <h1>App Lattice</h1>
        <h2>Microservice App Platform</h2>
      </Box>
      <a
        href={`https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scopes=repo write:packages`}
      >
        <Button mt={[20, 0]} variant='accent' leftIcon={<Image src='/images/logo-github.svg' />} height='80px' pl='1rem' pr='1rem' fontSize='1.5rem'>
          Login with GitHub
        </Button>
      </a>
      <Box textAlign='center' p={6}>
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/S2OuWAWOAc4?si=WxwlmqnIeplWYK8R"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </Box>

    </SessionCheck>
  );
};

export default Login;
