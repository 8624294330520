import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../reduxStore/reduxStore';
import { ShowResponse } from '../../tsProtoCodegen/user';
import userNotNull from '../User/selectors/userNotNull';
import { useTitleContext } from '../../Contexts/TitleContext';
import copy from '../../utils/copy';
import { Link } from 'react-router-dom';
import { Box, Button, Icon } from '@chakra-ui/react';
import Title from '../../Components/Layouts/LoggedIn/Title';
import { WhereResponse } from '../../tsProtoCodegen/ServiceGroup';
import { RemoteResource, RemoteResourceStatus } from '../ServiceGroup/Show/Secrets/Secrets';
import serviceGroupsFetch from '../ServiceGroup/List/serviceGroupsFetch';
import { FaGithub } from 'react-icons/fa';


const Home: React.FC = (): ReactElement => {
  const user = useSelector<State, ShowResponse>(userNotNull);
  const [{ status, serviceGroups }, setServiceGroups] = useState<WhereResponse & RemoteResource>({ serviceGroups: [], status: RemoteResourceStatus.INIT });

  useEffect(() => {
    if (status === RemoteResourceStatus.INIT) {
      serviceGroupsFetch(setServiceGroups);
    }
  }, [status]);

  const welcome = () => {

    if (status === RemoteResourceStatus.LOADED) {
      if (serviceGroups.length === 0) {
        return (
          <Link to='/service-groups/create'><Button variant='flashy' size='lg'>Create an {copy.entities.ServiceGroup}</Button></Link >
        );
      }

      return <Link to='/service-groups' ><Button variant='flashy' size='lg'>View your {copy.entities.ServiceGroup}s</Button></Link>;
    };
    return <></>;
  };

  return (
    <>
      <Title>Home</Title>
      <Box display='flex' textAlign='center' flexDirection='column' fontSize={22} justifyContent='center' justifyItems='center' alignItems='center' h='300px'>
        <Box pb={8}>Welcome, <Icon as={FaGithub} ml={1} mr={1} mb={-1} /><strong>{user.githubUsername}</strong>. Create something awesome!</Box>
        {welcome()}
      </Box>
    </>
  );
};

export default Home;
