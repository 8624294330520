import React from 'react';
import { Spinner } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { CiBuildStatus } from '../../../../../tsProtoCodegen/service';

const buildStatusMap: { [key in CiBuildStatus | 'default']: { image: string, label: string | ReactElement } } = {
  'default': {
    image: '/images/icon-circle-yellow.svg',
    label: 'init',
  },

  [CiBuildStatus.UNRECOGNIZED]: {
    image: '/images/icon-circle-yellow.svg',
    label: 'init',
  },
  [CiBuildStatus.CI_BUILD_STATUS_SUCCESS]: {
    image: '/images/icon-circle-green.svg',
    label: 'Success!',
  },
  [CiBuildStatus.CI_BUILD_STATUS_UNSPECIFIED]: {
    image: '/images/icon-circle-yellow.svg',
    label: 'init',
  },
  [CiBuildStatus.CI_BUILD_STATUS_PENDING]: {
    image: '/images/icon-circle-yellow.svg',
    label: <>Building... <Spinner size='sm' /></>,
  },
  [CiBuildStatus.CI_BUILD_STATUS_FAILED]: {
    image: '/images/icon-circle-red.svg',
    label: 'Build Failed',
  },

};

export default buildStatusMap;