import React, { ReactElement, useContext } from 'react';

export type TitleContextType = {
  title: string | ReactElement;
  setTitle: (title: string | ReactElement) => void;
};
export const TitleContext = React.createContext<TitleContextType>({
  title: '',
  setTitle: () => null,
});

export const useTitleContext = () => {
  const ctx = useContext(TitleContext);

  if (!ctx) throw new Error('not in TitleContext.');
  if (!ctx.setTitle) {
    throw new Error('TitleContextType.setTitle needs to be defined.');
  }

  return ctx;
};
