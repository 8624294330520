import { Box, Button, Flex, Show } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import copy from '../../../utils/copy';

const Title: React.FC<{ openModal: () => void, showCreateButton?: boolean }> = ({ openModal, showCreateButton }): ReactElement => {
  return (
    <Flex pb={3} >
      <Box flex={1}>
        <h1>{copy.entities.ServiceGroup}s</h1>
      </Box>
      {showCreateButton && (
        <Flex
          justifyContent='flex-end'
          alignItems='center'
          flex={1}
        >
          <Button
            maxW={20}
            variant='flashy'
            onClick={openModal}
          >Create {copy.entities.ServiceGroup}</Button>
        </Flex>
      )}
    </Flex>
  );
};

export default Title;
