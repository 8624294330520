import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
const outline = defineStyle({
  background: 'brand.purple.200',
  color: 'brand.purple.800',
  border: '1px solid brand.purple.300',
  boxShadow: '2px 2px 2px 0px rgba(0,0,0,0.3) inset',
  ':focus': {
    background: 'brand.purple.50',
  },
});

export const textareaTheme = defineStyleConfig({
  variants: { outline },
});
