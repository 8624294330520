import React, { ReactElement, useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select
} from '@chakra-ui/react';
import { UseFormRegister } from 'react-hook-form';
import { CreateRequest } from '../../../tsProtoCodegen/ServiceGroup';
import { colors } from '../../../theme/theme';

type DnsResolverProps = {
  register: UseFormRegister<CreateRequest>,
}

export type Resolver = {
  className: string;
  label: string;
}

export type Resolvers = {
  [key: string]: Resolver,
}

const resolvers: Resolvers = {
  'digitalOcean': {
    className: 'DigitalOcean',
    label: 'Digital Ocean',
  },
  'route53': {
    className: 'Route53',
    label: 'Route53',
  },
  'cloudFlare': {
    className: 'CloudFlare',
    label: 'Cloud Flare',
  },
  'azure': {
    className: 'AzureDNS',
    label: 'Azure DNS',
  },
  'google': {
    className: 'Google',
    label: 'Google',
  },
};

const ResolverApiKeyInput: React.FC<{
  resolver: Resolver | undefined,
  register: UseFormRegister<CreateRequest>,
}> = ({ resolver, register }) => {
  if (!resolver) {
    return <></>;
  }

  if (resolver === resolvers.digitalOcean) {
    return (
      <>
        <FormLabel>API Key</FormLabel>
        <Input
          type="password"
          placeholder="DNS API Key"
          {...register('digitalOceanDns.access_token', { required: true })}
        />
      </>
    );
  }

  return <FormLabel>Sorry! We have not implemented the {resolver.label} resolver yet.</FormLabel>;

};

const DnsResolver: React.FC<DnsResolverProps> = ({ register }): ReactElement => {
  const [resolver, setResolver] = useState<Resolver | undefined>();

  return (
    <Box border='1px solid' p={3} borderColor={colors.brand.purple[400]}>
      <FormControl>
        <Grid gap={4} templateColumns='repeat(2, 1fr)'>
          <GridItem>
            <FormLabel>DNS Resolver</FormLabel>
            <Select placeholder='Select DNS Resolver'
onChange={(e) => {
              if (e.currentTarget.value === '') {
                setResolver(undefined);
              }
              if (
                e.currentTarget.value === 'digitalOcean' ||
                e.currentTarget.value === 'route53' ||
                e.currentTarget.value === 'cloudFlare' ||
                e.currentTarget.value === 'azure' ||
                e.currentTarget.value === 'google'
              ) {
                setResolver(resolvers[e.currentTarget.value]);
              }
            }}
            >
              {Object.keys(resolvers).map((key: string) => {
                return (<option key={`resolver-${key}`} value={key}>{resolvers[key].label}</option>);
              })}
            </Select>
          </GridItem>
          <GridItem>
            <ResolverApiKeyInput register={register} resolver={resolver} />
          </GridItem>
        </Grid>
      </FormControl>
    </Box >
  );
};

export default DnsResolver;
