import React, { PropsWithChildren } from 'react';
import Authorize from './Authorize';
import { Box } from '@chakra-ui/react';

const Container: React.FC<PropsWithChildren> = ({ children }) => {
  return (<Box textAlign='center' pt={8} > {children}</Box >);
};

const routes = [{
  path: '/user/authorize',
  element: <Container><Authorize /></Container>
}];
export default routes;
