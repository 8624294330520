import React from 'react';
import Logout from './Logout';

const routes = [
  {
    element: <Logout />,
    path: '/logout',
  },
];

export default routes;
