import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Grid,
  GridItem,
  Divider,
  Box,
  Icon
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { CreateOrUpdateSecretsRequest } from './Secrets';
import { FaTrash } from 'react-icons/fa';

const SecretFields: React.FC<{
  register: UseFormRegister<CreateOrUpdateSecretsRequest & { values: string }>,
  index: number,
  remove: (index: number) => void,
}> = ({ register, index, remove }): ReactElement => {
  return (
    <>
      <Grid gap={4} templateColumns='repeat(2, 1fr)'>
        <GridItem colSpan={2}>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              id={`secrets.${index}.name`}
              {...register(`secrets.${index}.name`, { required: true })}
            />
          </FormControl>
        </GridItem>
        <Input
          type="hidden"
          id={`secrets.${index}.type`}
          {...register(`secrets.${index}.type`, { required: true })}
        />
        <GridItem colSpan={2}>
          <FormControl>
            <FormLabel>JSON data</FormLabel>
            <Textarea
              placeholder='JSON Data'
              id={`secrets.${index}.values`}
              {...register(`secrets.${index}.values`, { required: true })}
            ></Textarea>
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <Box p={2} textAlign='center'>
            <Button borderRadius='full' type="button" onClick={() => remove(index)} aria-label={'Remove Secret'}>
              <Icon as={FaTrash} mr={2} /> Delete Secret
            </Button>
          </Box>
        </GridItem>
      </Grid >
      <Divider />
    </>
  );
};

export default SecretFields;
