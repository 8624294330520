import React, { ReactElement, useState } from 'react';
import { PaymentMethodState } from '../PaymentMethod/redux/types';
import { DepositRequest, DepositResponse } from '../../../tsProtoCodegen/billing.customer';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Spinner
} from '@chakra-ui/react';
import { RemoteResource, RemoteResourceStatus } from '../../ServiceGroup/Show/Secrets/Secrets';
import ErrorElem from '../../../Components/ErrorElem/ErrorElem';
import billingCustomerApiClient from '../billingCustomerApiClient';
import { DeepPartial } from '../../../tsProtoCodegen/certmanager/dns/providers';
import metadataWithJwt from '../../../utils/metadataWithJwt';
import copy from '../../../utils/copy';
import { Link } from 'react-router-dom';

type DepositParams = {
  paymentMethodState: PaymentMethodState,
  customerId: string
}
const Deposit: React.FC<DepositParams> = ({ paymentMethodState, customerId: id }): ReactElement => {
  const [deposit, setDeposit] = useState<DeepPartial<DepositResponse> & RemoteResource>({ status: RemoteResourceStatus.INIT });

  const {
    register,
    handleSubmit,
  } = useForm<DepositRequest>({ defaultValues: { amount: 0, id } });

  if (paymentMethodState.loading) {
    return <></>;
  }
  if (paymentMethodState.paymentMethods?.length && paymentMethodState.paymentMethods?.length < 1) {
    return <>Please add a payment method so you can add some funds to your account.</>;
  }

  const { status } = deposit;
  const submit = async (req: DepositRequest) => {
    if (status !== RemoteResourceStatus.LOADING) {
      setDeposit({ status: RemoteResourceStatus.LOADING, error: undefined });
      try {
        const res = await billingCustomerApiClient.deposit({ ...req }, { metadata: metadataWithJwt() });
        setDeposit({ status: RemoteResourceStatus.LOADED, ...res });
      } catch (e: any) {
        setDeposit({ status: RemoteResourceStatus.ERROR, error: e.details });
      }
    }
  };

  if (status === RemoteResourceStatus.LOADED) {
    return <>Thank you for your deposit! <Link className='underline' to='/service-groups/create'>You should be able to create a {copy.entities.ServiceGroup}.</Link></>;
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      {(deposit?.status === RemoteResourceStatus.LOADING) && <Spinner />}

      <ErrorElem show={deposit?.status === RemoteResourceStatus.ERROR}>There was an issue depositing the funds. Try again please</ErrorElem>
      <FormControl>
        <FormLabel>Amount in USD</FormLabel>
        <Input
          type="text"
          placeholder="$"
          {...register('amount', { required: true })}
        />
      </FormControl>
      <FormControl>
        <Input type="hidden" placeholder="..." {...register('id', { required: true })} />
      </FormControl>
      <Box textAlign='center' pt={6}>
        <Button disabled={deposit?.status === RemoteResourceStatus.LOADING} type='submit'>Submit</Button>
      </Box>

    </form>
  );
};

export default Deposit;
