import React, { ReactElement, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import serviceGroupFetch from './serviceGroupFetch';
import serviceGroupDestroy from './serviceGroupDestroy';
import { DestroyResponse, ShowResponse } from '../../../tsProtoCodegen/ServiceGroup';
import Title from './Title';
import Diagram from './Diagram/Diagram';
import ServiceCreateForm from './ServiceCreateForm/ServiceCreateForm';
import copy from '../../../utils/copy';
import { RemoteResource, RemoteResourceStatus } from './Secrets/Secrets';
import { Box, Spinner } from '@chakra-ui/react';
import { ServiceListProvider } from '../../Service/List/ServiceListContext';

const Show: React.FC = (): ReactElement => {
  const [serviceGroup, setServiceGroup] = useState<ShowResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [destroy, setDestroy] = useState<{ res?: DestroyResponse | undefined } & RemoteResource>({ status: RemoteResourceStatus.INIT });
  const [showCreateService, setShowCreateService] = useState<boolean>(false);

  const { id } = useParams();

  useEffect(() => {
    if (serviceGroup === null) {
      serviceGroupFetch(id as string, (serviceGroup?: ShowResponse, error?: string) => {
        if (error) {
          setError(error);
        }
        if (!error && serviceGroup) {
          setServiceGroup(serviceGroup);
        }
      });
    }
  }, [id, serviceGroup]);

  const onDestroy = () => {
    setDestroy({
      error: undefined,
      status: RemoteResourceStatus.LOADING
    });

    serviceGroupDestroy(
      id as string,
      (res) => setDestroy({ res, status: RemoteResourceStatus.LOADED }),
      (error: string) => setDestroy({ status: RemoteResourceStatus.ERROR, error }));
  };

  if (destroy.status === RemoteResourceStatus.LOADING) {
    return <Box><Spinner />Destroying this {copy.entities.ServiceGroup}...</Box>;
  }

  if (destroy.status === RemoteResourceStatus.LOADED) {
    return <Navigate to='/service-groups' />;
  }

  if (!serviceGroup && !error) return <>Loading...</>;

  if (error || serviceGroup === null) {
    return (<div>
      There was an error retrieving the {copy.entities.ServiceGroup}
      <button onClick={() => {
        setServiceGroup(null);
        setError(null);
      }}
      >
        Retry
      </button>
    </div>);
  }


  return (
    <ServiceListProvider serviceGroupId={serviceGroup.id}>
      <Title serviceGroup={serviceGroup} onDestroy={onDestroy} />
      <Diagram
        serviceGroup={serviceGroup}
        setShowCreateService={setShowCreateService}
      />
      <ServiceCreateForm show={showCreateService} serviceGroupId={serviceGroup.id} onClose={() => { setShowCreateService(false); }} />
    </ServiceListProvider>
  );
};

export default Show;
