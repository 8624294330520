import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

const BuildLogs: React.FC<{
  logs: string | undefined,
  isOpen: boolean,
  onClose: () => void,
}> = ({ logs, isOpen, onClose }): ReactElement => {
  const getBuildLogs = () => {
    if (!logs) {
      return 'no logs yet...';
    }

    return logs;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="90%" >
        <ModalHeader>logs</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign='left' overflowX="scroll" style={{ whiteSpace: 'pre-wrap' }}>
          {getBuildLogs()}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BuildLogs;
