import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { userThunk } from '../User/userSlice';
import store from '../../reduxStore/reduxStore';

const Logout: React.FC = (): ReactElement => {
  localStorage.removeItem('jwt');

  const dispatch = useDispatch<typeof store.dispatch>();
  dispatch(userThunk(''));

  return (<div>
    <h1>You have been logged out!</h1>
    <Link to='/'>Go Home</Link>
  </div>);
};

export default Logout;
