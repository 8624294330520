/* eslint-disable */
import type { CallContext, CallOptions } from "nice-grpc-common";
import _m0 from "protobufjs/minimal";
import {
  ResponseFormat,
  responseFormatFromJSON,
  responseFormatToJSON,
  responseFormatToNumber,
} from "./appLatticeCommon";

export const protobufPackage = "Service";

export enum Status {
  /**
   * creating_github_repo - 0 should be unspecified. Right now status has to be undefined to
   * otherwise it defaults to creating_github_repo in where requests
   */
  creating_github_repo = "creating_github_repo",
  updating_k8s_config = "updating_k8s_config",
  creating_cicd_pipelines = "creating_cicd_pipelines",
  initial_docker_build_and_deploy = "initial_docker_build_and_deploy",
  destroying = "destroying",
  updating = "updating",
  ready = "ready",
  failed = "failed",
  creating_database = "creating_database",
  deploying_to_k8s = "deploying_to_k8s",
  create_failed_initial_docker_build_and_deploy = "create_failed_initial_docker_build_and_deploy",
  helmfile_sync = "helmfile_sync",
  create_failed_helmfile_sync = "create_failed_helmfile_sync",
  creating = "creating",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function statusFromJSON(object: any): Status {
  switch (object) {
    case 0:
    case "creating_github_repo":
      return Status.creating_github_repo;
    case 1:
    case "updating_k8s_config":
      return Status.updating_k8s_config;
    case 2:
    case "creating_cicd_pipelines":
      return Status.creating_cicd_pipelines;
    case 3:
    case "initial_docker_build_and_deploy":
      return Status.initial_docker_build_and_deploy;
    case 4:
    case "destroying":
      return Status.destroying;
    case 5:
    case "updating":
      return Status.updating;
    case 6:
    case "ready":
      return Status.ready;
    case 7:
    case "failed":
      return Status.failed;
    case 8:
    case "creating_database":
      return Status.creating_database;
    case 9:
    case "deploying_to_k8s":
      return Status.deploying_to_k8s;
    case 10:
    case "create_failed_initial_docker_build_and_deploy":
      return Status.create_failed_initial_docker_build_and_deploy;
    case 11:
    case "helmfile_sync":
      return Status.helmfile_sync;
    case 12:
    case "create_failed_helmfile_sync":
      return Status.create_failed_helmfile_sync;
    case 13:
    case "creating":
      return Status.creating;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Status.UNRECOGNIZED;
  }
}

export function statusToJSON(object: Status): string {
  switch (object) {
    case Status.creating_github_repo:
      return "creating_github_repo";
    case Status.updating_k8s_config:
      return "updating_k8s_config";
    case Status.creating_cicd_pipelines:
      return "creating_cicd_pipelines";
    case Status.initial_docker_build_and_deploy:
      return "initial_docker_build_and_deploy";
    case Status.destroying:
      return "destroying";
    case Status.updating:
      return "updating";
    case Status.ready:
      return "ready";
    case Status.failed:
      return "failed";
    case Status.creating_database:
      return "creating_database";
    case Status.deploying_to_k8s:
      return "deploying_to_k8s";
    case Status.create_failed_initial_docker_build_and_deploy:
      return "create_failed_initial_docker_build_and_deploy";
    case Status.helmfile_sync:
      return "helmfile_sync";
    case Status.create_failed_helmfile_sync:
      return "create_failed_helmfile_sync";
    case Status.creating:
      return "creating";
    case Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function statusToNumber(object: Status): number {
  switch (object) {
    case Status.creating_github_repo:
      return 0;
    case Status.updating_k8s_config:
      return 1;
    case Status.creating_cicd_pipelines:
      return 2;
    case Status.initial_docker_build_and_deploy:
      return 3;
    case Status.destroying:
      return 4;
    case Status.updating:
      return 5;
    case Status.ready:
      return 6;
    case Status.failed:
      return 7;
    case Status.creating_database:
      return 8;
    case Status.deploying_to_k8s:
      return 9;
    case Status.create_failed_initial_docker_build_and_deploy:
      return 10;
    case Status.helmfile_sync:
      return 11;
    case Status.create_failed_helmfile_sync:
      return 12;
    case Status.creating:
      return 13;
    case Status.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum PortProtocol {
  TCP = "TCP",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function portProtocolFromJSON(object: any): PortProtocol {
  switch (object) {
    case 0:
    case "TCP":
      return PortProtocol.TCP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PortProtocol.UNRECOGNIZED;
  }
}

export function portProtocolToJSON(object: PortProtocol): string {
  switch (object) {
    case PortProtocol.TCP:
      return "TCP";
    case PortProtocol.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function portProtocolToNumber(object: PortProtocol): number {
  switch (object) {
    case PortProtocol.TCP:
      return 0;
    case PortProtocol.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum CreateProcessState {
  INIT = "INIT",
  CREATE_GITHUB_REPO = "CREATE_GITHUB_REPO",
  CREATE_JENKINS_JOBS = "CREATE_JENKINS_JOBS",
  CREATE_DATABASE = "CREATE_DATABASE",
  END = "END",
  TRIGGER_JENKINS_JOB_BUILD = "TRIGGER_JENKINS_JOB_BUILD",
  HELMFILE_SYNC = "HELMFILE_SYNC",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function createProcessStateFromJSON(object: any): CreateProcessState {
  switch (object) {
    case 0:
    case "INIT":
      return CreateProcessState.INIT;
    case 1:
    case "CREATE_GITHUB_REPO":
      return CreateProcessState.CREATE_GITHUB_REPO;
    case 2:
    case "CREATE_JENKINS_JOBS":
      return CreateProcessState.CREATE_JENKINS_JOBS;
    case 3:
    case "CREATE_DATABASE":
      return CreateProcessState.CREATE_DATABASE;
    case 5:
    case "END":
      return CreateProcessState.END;
    case 6:
    case "TRIGGER_JENKINS_JOB_BUILD":
      return CreateProcessState.TRIGGER_JENKINS_JOB_BUILD;
    case 7:
    case "HELMFILE_SYNC":
      return CreateProcessState.HELMFILE_SYNC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CreateProcessState.UNRECOGNIZED;
  }
}

export function createProcessStateToJSON(object: CreateProcessState): string {
  switch (object) {
    case CreateProcessState.INIT:
      return "INIT";
    case CreateProcessState.CREATE_GITHUB_REPO:
      return "CREATE_GITHUB_REPO";
    case CreateProcessState.CREATE_JENKINS_JOBS:
      return "CREATE_JENKINS_JOBS";
    case CreateProcessState.CREATE_DATABASE:
      return "CREATE_DATABASE";
    case CreateProcessState.END:
      return "END";
    case CreateProcessState.TRIGGER_JENKINS_JOB_BUILD:
      return "TRIGGER_JENKINS_JOB_BUILD";
    case CreateProcessState.HELMFILE_SYNC:
      return "HELMFILE_SYNC";
    case CreateProcessState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function createProcessStateToNumber(object: CreateProcessState): number {
  switch (object) {
    case CreateProcessState.INIT:
      return 0;
    case CreateProcessState.CREATE_GITHUB_REPO:
      return 1;
    case CreateProcessState.CREATE_JENKINS_JOBS:
      return 2;
    case CreateProcessState.CREATE_DATABASE:
      return 3;
    case CreateProcessState.END:
      return 5;
    case CreateProcessState.TRIGGER_JENKINS_JOB_BUILD:
      return 6;
    case CreateProcessState.HELMFILE_SYNC:
      return 7;
    case CreateProcessState.UNRECOGNIZED:
    default:
      return -1;
  }
}

export enum CiBuildStatus {
  CI_BUILD_STATUS_UNSPECIFIED = "CI_BUILD_STATUS_UNSPECIFIED",
  CI_BUILD_STATUS_FAILED = "CI_BUILD_STATUS_FAILED",
  CI_BUILD_STATUS_SUCCESS = "CI_BUILD_STATUS_SUCCESS",
  CI_BUILD_STATUS_PENDING = "CI_BUILD_STATUS_PENDING",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function ciBuildStatusFromJSON(object: any): CiBuildStatus {
  switch (object) {
    case 0:
    case "CI_BUILD_STATUS_UNSPECIFIED":
      return CiBuildStatus.CI_BUILD_STATUS_UNSPECIFIED;
    case 1:
    case "CI_BUILD_STATUS_FAILED":
      return CiBuildStatus.CI_BUILD_STATUS_FAILED;
    case 2:
    case "CI_BUILD_STATUS_SUCCESS":
      return CiBuildStatus.CI_BUILD_STATUS_SUCCESS;
    case 3:
    case "CI_BUILD_STATUS_PENDING":
      return CiBuildStatus.CI_BUILD_STATUS_PENDING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CiBuildStatus.UNRECOGNIZED;
  }
}

export function ciBuildStatusToJSON(object: CiBuildStatus): string {
  switch (object) {
    case CiBuildStatus.CI_BUILD_STATUS_UNSPECIFIED:
      return "CI_BUILD_STATUS_UNSPECIFIED";
    case CiBuildStatus.CI_BUILD_STATUS_FAILED:
      return "CI_BUILD_STATUS_FAILED";
    case CiBuildStatus.CI_BUILD_STATUS_SUCCESS:
      return "CI_BUILD_STATUS_SUCCESS";
    case CiBuildStatus.CI_BUILD_STATUS_PENDING:
      return "CI_BUILD_STATUS_PENDING";
    case CiBuildStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function ciBuildStatusToNumber(object: CiBuildStatus): number {
  switch (object) {
    case CiBuildStatus.CI_BUILD_STATUS_UNSPECIFIED:
      return 0;
    case CiBuildStatus.CI_BUILD_STATUS_FAILED:
      return 1;
    case CiBuildStatus.CI_BUILD_STATUS_SUCCESS:
      return 2;
    case CiBuildStatus.CI_BUILD_STATUS_PENDING:
      return 3;
    case CiBuildStatus.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface CreateRequest {
  name: string;
  envVars: string;
  serviceGroupId: string;
  portMappings: CreatePortMappingRequest[];
  hasDatabase: boolean;
  replicas: number;
  templateRepo: string;
  withHttpProxy: boolean;
  grpc: boolean;
  secrets: CreateSecretRequest[];
}

export interface CreateCommandRequest {
  id: string;
  createRequest: CreateRequest | undefined;
  existingGithubRepo: boolean;
}

export interface SecretForBuildArgs {
  secretName: string;
  secretKey: string;
  key: string;
}

export interface ShowRequest {
  id: string;
}

export interface ShowResponse {
  id: string;
  name: string;
  envVars: string;
  status: Status;
  serviceGroupId: string;
  portMappings: PortMapping[];
  replicas: number;
  templateRepo: string;
  ciBuildInfo: CiBuildInfo | undefined;
  secrets: Secret[];
}

export interface CreateResponse {
}

export interface CreateArgumentError {
  name: string[];
  envVars: string[];
  serviceGroupName: string[];
  portMappings: string[];
}

export interface UpdateStatusResponse {
  service: ShowResponse | undefined;
  previousStatus: Status;
}

export interface Updated {
  originalStatus: Status;
  newStatus: Status;
  publicId: string;
}

export interface PortMapping {
  id: string;
  target: number;
  port: number;
  protocol: PortProtocol;
  name: string;
}

export interface CreatePortMappingRequest {
  target: number;
  port: number;
  protocol: PortProtocol;
  name: string;
}

export interface CreateSecretRequest {
  secretName: string;
  envName: string;
  propertyName: string;
  env: boolean;
  build: boolean;
}

export interface Secret {
  secretName: string;
  envName: string;
  propertyName: string;
  env: boolean;
  build: boolean;
  id: string;
}

export interface WhereInput {
  id?: string | undefined;
  name?: string | undefined;
  status?: Status | undefined;
  serviceGroupId?: string | undefined;
}

export interface WhereRequest {
  whereInput: WhereInput | undefined;
  format?: ResponseFormat | undefined;
}

export interface WhereResponse {
  services: ShowResponse[];
  servicesJson?: string | undefined;
}

export interface DestroyRequest {
  id: string;
}

export interface DestroyResponse {
  service: ShowResponse | undefined;
}

export interface CiBuildInfo {
  status: CiBuildStatus;
  logs: string;
}

export interface BuildRequest {
  id: string;
}

export interface BuildRespones {
}

export interface UpdateRequest {
  id: string;
  envVars: string;
  portMappings: CreatePortMappingRequest[];
  replicas: number;
  secretsForBuildArgs: SecretForBuildArgs[];
  withHttpProxy: boolean;
  grpc: boolean;
}

export interface UpdateResponse {
}

export interface CiBuildInfoRequest {
  id: string;
}

export interface CiBuildInfoResponse {
  status: CiBuildStatus;
  logs: string;
}

function createBaseCreateRequest(): CreateRequest {
  return {
    name: "",
    envVars: "",
    serviceGroupId: "",
    portMappings: [],
    hasDatabase: false,
    replicas: 0,
    templateRepo: "",
    withHttpProxy: false,
    grpc: false,
    secrets: [],
  };
}

export const CreateRequest = {
  encode(message: CreateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.envVars !== "") {
      writer.uint32(26).string(message.envVars);
    }
    if (message.serviceGroupId !== "") {
      writer.uint32(34).string(message.serviceGroupId);
    }
    for (const v of message.portMappings) {
      CreatePortMappingRequest.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.hasDatabase === true) {
      writer.uint32(48).bool(message.hasDatabase);
    }
    if (message.replicas !== 0) {
      writer.uint32(56).int32(message.replicas);
    }
    if (message.templateRepo !== "") {
      writer.uint32(74).string(message.templateRepo);
    }
    if (message.withHttpProxy === true) {
      writer.uint32(80).bool(message.withHttpProxy);
    }
    if (message.grpc === true) {
      writer.uint32(88).bool(message.grpc);
    }
    for (const v of message.secrets) {
      CreateSecretRequest.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.envVars = reader.string();
          break;
        case 4:
          message.serviceGroupId = reader.string();
          break;
        case 5:
          message.portMappings.push(CreatePortMappingRequest.decode(reader, reader.uint32()));
          break;
        case 6:
          message.hasDatabase = reader.bool();
          break;
        case 7:
          message.replicas = reader.int32();
          break;
        case 9:
          message.templateRepo = reader.string();
          break;
        case 10:
          message.withHttpProxy = reader.bool();
          break;
        case 11:
          message.grpc = reader.bool();
          break;
        case 12:
          message.secrets.push(CreateSecretRequest.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateRequest {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      envVars: isSet(object.envVars) ? String(object.envVars) : "",
      serviceGroupId: isSet(object.serviceGroupId) ? String(object.serviceGroupId) : "",
      portMappings: Array.isArray(object?.portMappings)
        ? object.portMappings.map((e: any) => CreatePortMappingRequest.fromJSON(e))
        : [],
      hasDatabase: isSet(object.hasDatabase) ? Boolean(object.hasDatabase) : false,
      replicas: isSet(object.replicas) ? Number(object.replicas) : 0,
      templateRepo: isSet(object.templateRepo) ? String(object.templateRepo) : "",
      withHttpProxy: isSet(object.withHttpProxy) ? Boolean(object.withHttpProxy) : false,
      grpc: isSet(object.grpc) ? Boolean(object.grpc) : false,
      secrets: Array.isArray(object?.secrets) ? object.secrets.map((e: any) => CreateSecretRequest.fromJSON(e)) : [],
    };
  },

  toJSON(message: CreateRequest): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.envVars !== undefined && (obj.envVars = message.envVars);
    message.serviceGroupId !== undefined && (obj.serviceGroupId = message.serviceGroupId);
    if (message.portMappings) {
      obj.portMappings = message.portMappings.map((e) => e ? CreatePortMappingRequest.toJSON(e) : undefined);
    } else {
      obj.portMappings = [];
    }
    message.hasDatabase !== undefined && (obj.hasDatabase = message.hasDatabase);
    message.replicas !== undefined && (obj.replicas = Math.round(message.replicas));
    message.templateRepo !== undefined && (obj.templateRepo = message.templateRepo);
    message.withHttpProxy !== undefined && (obj.withHttpProxy = message.withHttpProxy);
    message.grpc !== undefined && (obj.grpc = message.grpc);
    if (message.secrets) {
      obj.secrets = message.secrets.map((e) => e ? CreateSecretRequest.toJSON(e) : undefined);
    } else {
      obj.secrets = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<CreateRequest>): CreateRequest {
    const message = createBaseCreateRequest();
    message.name = object.name ?? "";
    message.envVars = object.envVars ?? "";
    message.serviceGroupId = object.serviceGroupId ?? "";
    message.portMappings = object.portMappings?.map((e) => CreatePortMappingRequest.fromPartial(e)) || [];
    message.hasDatabase = object.hasDatabase ?? false;
    message.replicas = object.replicas ?? 0;
    message.templateRepo = object.templateRepo ?? "";
    message.withHttpProxy = object.withHttpProxy ?? false;
    message.grpc = object.grpc ?? false;
    message.secrets = object.secrets?.map((e) => CreateSecretRequest.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateCommandRequest(): CreateCommandRequest {
  return { id: "", createRequest: undefined, existingGithubRepo: false };
}

export const CreateCommandRequest = {
  encode(message: CreateCommandRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.createRequest !== undefined) {
      CreateRequest.encode(message.createRequest, writer.uint32(18).fork()).ldelim();
    }
    if (message.existingGithubRepo === true) {
      writer.uint32(24).bool(message.existingGithubRepo);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateCommandRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCommandRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.createRequest = CreateRequest.decode(reader, reader.uint32());
          break;
        case 3:
          message.existingGithubRepo = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateCommandRequest {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      createRequest: isSet(object.createRequest) ? CreateRequest.fromJSON(object.createRequest) : undefined,
      existingGithubRepo: isSet(object.existingGithubRepo) ? Boolean(object.existingGithubRepo) : false,
    };
  },

  toJSON(message: CreateCommandRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.createRequest !== undefined &&
      (obj.createRequest = message.createRequest ? CreateRequest.toJSON(message.createRequest) : undefined);
    message.existingGithubRepo !== undefined && (obj.existingGithubRepo = message.existingGithubRepo);
    return obj;
  },

  fromPartial(object: DeepPartial<CreateCommandRequest>): CreateCommandRequest {
    const message = createBaseCreateCommandRequest();
    message.id = object.id ?? "";
    message.createRequest = (object.createRequest !== undefined && object.createRequest !== null)
      ? CreateRequest.fromPartial(object.createRequest)
      : undefined;
    message.existingGithubRepo = object.existingGithubRepo ?? false;
    return message;
  },
};

function createBaseSecretForBuildArgs(): SecretForBuildArgs {
  return { secretName: "", secretKey: "", key: "" };
}

export const SecretForBuildArgs = {
  encode(message: SecretForBuildArgs, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.secretName !== "") {
      writer.uint32(10).string(message.secretName);
    }
    if (message.secretKey !== "") {
      writer.uint32(18).string(message.secretKey);
    }
    if (message.key !== "") {
      writer.uint32(26).string(message.key);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretForBuildArgs {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretForBuildArgs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.secretName = reader.string();
          break;
        case 2:
          message.secretKey = reader.string();
          break;
        case 3:
          message.key = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SecretForBuildArgs {
    return {
      secretName: isSet(object.secretName) ? String(object.secretName) : "",
      secretKey: isSet(object.secretKey) ? String(object.secretKey) : "",
      key: isSet(object.key) ? String(object.key) : "",
    };
  },

  toJSON(message: SecretForBuildArgs): unknown {
    const obj: any = {};
    message.secretName !== undefined && (obj.secretName = message.secretName);
    message.secretKey !== undefined && (obj.secretKey = message.secretKey);
    message.key !== undefined && (obj.key = message.key);
    return obj;
  },

  fromPartial(object: DeepPartial<SecretForBuildArgs>): SecretForBuildArgs {
    const message = createBaseSecretForBuildArgs();
    message.secretName = object.secretName ?? "";
    message.secretKey = object.secretKey ?? "";
    message.key = object.key ?? "";
    return message;
  },
};

function createBaseShowRequest(): ShowRequest {
  return { id: "" };
}

export const ShowRequest = {
  encode(message: ShowRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ShowRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseShowRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ShowRequest {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: ShowRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial(object: DeepPartial<ShowRequest>): ShowRequest {
    const message = createBaseShowRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseShowResponse(): ShowResponse {
  return {
    id: "",
    name: "",
    envVars: "",
    status: Status.creating_github_repo,
    serviceGroupId: "",
    portMappings: [],
    replicas: 0,
    templateRepo: "",
    ciBuildInfo: undefined,
    secrets: [],
  };
}

export const ShowResponse = {
  encode(message: ShowResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.envVars !== "") {
      writer.uint32(26).string(message.envVars);
    }
    if (message.status !== Status.creating_github_repo) {
      writer.uint32(40).int32(statusToNumber(message.status));
    }
    if (message.serviceGroupId !== "") {
      writer.uint32(58).string(message.serviceGroupId);
    }
    for (const v of message.portMappings) {
      PortMapping.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.replicas !== 0) {
      writer.uint32(72).int32(message.replicas);
    }
    if (message.templateRepo !== "") {
      writer.uint32(106).string(message.templateRepo);
    }
    if (message.ciBuildInfo !== undefined) {
      CiBuildInfo.encode(message.ciBuildInfo, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.secrets) {
      Secret.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ShowResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseShowResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.envVars = reader.string();
          break;
        case 5:
          message.status = statusFromJSON(reader.int32());
          break;
        case 7:
          message.serviceGroupId = reader.string();
          break;
        case 8:
          message.portMappings.push(PortMapping.decode(reader, reader.uint32()));
          break;
        case 9:
          message.replicas = reader.int32();
          break;
        case 13:
          message.templateRepo = reader.string();
          break;
        case 14:
          message.ciBuildInfo = CiBuildInfo.decode(reader, reader.uint32());
          break;
        case 15:
          message.secrets.push(Secret.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ShowResponse {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      envVars: isSet(object.envVars) ? String(object.envVars) : "",
      status: isSet(object.status) ? statusFromJSON(object.status) : Status.creating_github_repo,
      serviceGroupId: isSet(object.serviceGroupId) ? String(object.serviceGroupId) : "",
      portMappings: Array.isArray(object?.portMappings)
        ? object.portMappings.map((e: any) => PortMapping.fromJSON(e))
        : [],
      replicas: isSet(object.replicas) ? Number(object.replicas) : 0,
      templateRepo: isSet(object.templateRepo) ? String(object.templateRepo) : "",
      ciBuildInfo: isSet(object.ciBuildInfo) ? CiBuildInfo.fromJSON(object.ciBuildInfo) : undefined,
      secrets: Array.isArray(object?.secrets) ? object.secrets.map((e: any) => Secret.fromJSON(e)) : [],
    };
  },

  toJSON(message: ShowResponse): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.envVars !== undefined && (obj.envVars = message.envVars);
    message.status !== undefined && (obj.status = statusToJSON(message.status));
    message.serviceGroupId !== undefined && (obj.serviceGroupId = message.serviceGroupId);
    if (message.portMappings) {
      obj.portMappings = message.portMappings.map((e) => e ? PortMapping.toJSON(e) : undefined);
    } else {
      obj.portMappings = [];
    }
    message.replicas !== undefined && (obj.replicas = Math.round(message.replicas));
    message.templateRepo !== undefined && (obj.templateRepo = message.templateRepo);
    message.ciBuildInfo !== undefined &&
      (obj.ciBuildInfo = message.ciBuildInfo ? CiBuildInfo.toJSON(message.ciBuildInfo) : undefined);
    if (message.secrets) {
      obj.secrets = message.secrets.map((e) => e ? Secret.toJSON(e) : undefined);
    } else {
      obj.secrets = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<ShowResponse>): ShowResponse {
    const message = createBaseShowResponse();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.envVars = object.envVars ?? "";
    message.status = object.status ?? Status.creating_github_repo;
    message.serviceGroupId = object.serviceGroupId ?? "";
    message.portMappings = object.portMappings?.map((e) => PortMapping.fromPartial(e)) || [];
    message.replicas = object.replicas ?? 0;
    message.templateRepo = object.templateRepo ?? "";
    message.ciBuildInfo = (object.ciBuildInfo !== undefined && object.ciBuildInfo !== null)
      ? CiBuildInfo.fromPartial(object.ciBuildInfo)
      : undefined;
    message.secrets = object.secrets?.map((e) => Secret.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateResponse(): CreateResponse {
  return {};
}

export const CreateResponse = {
  encode(_: CreateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): CreateResponse {
    return {};
  },

  toJSON(_: CreateResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<CreateResponse>): CreateResponse {
    const message = createBaseCreateResponse();
    return message;
  },
};

function createBaseCreateArgumentError(): CreateArgumentError {
  return { name: [], envVars: [], serviceGroupName: [], portMappings: [] };
}

export const CreateArgumentError = {
  encode(message: CreateArgumentError, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.name) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.envVars) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.serviceGroupName) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.portMappings) {
      writer.uint32(58).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateArgumentError {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateArgumentError();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name.push(reader.string());
          break;
        case 2:
          message.envVars.push(reader.string());
          break;
        case 6:
          message.serviceGroupName.push(reader.string());
          break;
        case 7:
          message.portMappings.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateArgumentError {
    return {
      name: Array.isArray(object?.name) ? object.name.map((e: any) => String(e)) : [],
      envVars: Array.isArray(object?.envVars) ? object.envVars.map((e: any) => String(e)) : [],
      serviceGroupName: Array.isArray(object?.serviceGroupName)
        ? object.serviceGroupName.map((e: any) => String(e))
        : [],
      portMappings: Array.isArray(object?.portMappings) ? object.portMappings.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: CreateArgumentError): unknown {
    const obj: any = {};
    if (message.name) {
      obj.name = message.name.map((e) => e);
    } else {
      obj.name = [];
    }
    if (message.envVars) {
      obj.envVars = message.envVars.map((e) => e);
    } else {
      obj.envVars = [];
    }
    if (message.serviceGroupName) {
      obj.serviceGroupName = message.serviceGroupName.map((e) => e);
    } else {
      obj.serviceGroupName = [];
    }
    if (message.portMappings) {
      obj.portMappings = message.portMappings.map((e) => e);
    } else {
      obj.portMappings = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<CreateArgumentError>): CreateArgumentError {
    const message = createBaseCreateArgumentError();
    message.name = object.name?.map((e) => e) || [];
    message.envVars = object.envVars?.map((e) => e) || [];
    message.serviceGroupName = object.serviceGroupName?.map((e) => e) || [];
    message.portMappings = object.portMappings?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdateStatusResponse(): UpdateStatusResponse {
  return { service: undefined, previousStatus: Status.creating_github_repo };
}

export const UpdateStatusResponse = {
  encode(message: UpdateStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.service !== undefined) {
      ShowResponse.encode(message.service, writer.uint32(10).fork()).ldelim();
    }
    if (message.previousStatus !== Status.creating_github_repo) {
      writer.uint32(16).int32(statusToNumber(message.previousStatus));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateStatusResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.service = ShowResponse.decode(reader, reader.uint32());
          break;
        case 2:
          message.previousStatus = statusFromJSON(reader.int32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UpdateStatusResponse {
    return {
      service: isSet(object.service) ? ShowResponse.fromJSON(object.service) : undefined,
      previousStatus: isSet(object.previousStatus)
        ? statusFromJSON(object.previousStatus)
        : Status.creating_github_repo,
    };
  },

  toJSON(message: UpdateStatusResponse): unknown {
    const obj: any = {};
    message.service !== undefined && (obj.service = message.service ? ShowResponse.toJSON(message.service) : undefined);
    message.previousStatus !== undefined && (obj.previousStatus = statusToJSON(message.previousStatus));
    return obj;
  },

  fromPartial(object: DeepPartial<UpdateStatusResponse>): UpdateStatusResponse {
    const message = createBaseUpdateStatusResponse();
    message.service = (object.service !== undefined && object.service !== null)
      ? ShowResponse.fromPartial(object.service)
      : undefined;
    message.previousStatus = object.previousStatus ?? Status.creating_github_repo;
    return message;
  },
};

function createBaseUpdated(): Updated {
  return { originalStatus: Status.creating_github_repo, newStatus: Status.creating_github_repo, publicId: "" };
}

export const Updated = {
  encode(message: Updated, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.originalStatus !== Status.creating_github_repo) {
      writer.uint32(8).int32(statusToNumber(message.originalStatus));
    }
    if (message.newStatus !== Status.creating_github_repo) {
      writer.uint32(16).int32(statusToNumber(message.newStatus));
    }
    if (message.publicId !== "") {
      writer.uint32(26).string(message.publicId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Updated {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdated();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.originalStatus = statusFromJSON(reader.int32());
          break;
        case 2:
          message.newStatus = statusFromJSON(reader.int32());
          break;
        case 3:
          message.publicId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Updated {
    return {
      originalStatus: isSet(object.originalStatus)
        ? statusFromJSON(object.originalStatus)
        : Status.creating_github_repo,
      newStatus: isSet(object.newStatus) ? statusFromJSON(object.newStatus) : Status.creating_github_repo,
      publicId: isSet(object.publicId) ? String(object.publicId) : "",
    };
  },

  toJSON(message: Updated): unknown {
    const obj: any = {};
    message.originalStatus !== undefined && (obj.originalStatus = statusToJSON(message.originalStatus));
    message.newStatus !== undefined && (obj.newStatus = statusToJSON(message.newStatus));
    message.publicId !== undefined && (obj.publicId = message.publicId);
    return obj;
  },

  fromPartial(object: DeepPartial<Updated>): Updated {
    const message = createBaseUpdated();
    message.originalStatus = object.originalStatus ?? Status.creating_github_repo;
    message.newStatus = object.newStatus ?? Status.creating_github_repo;
    message.publicId = object.publicId ?? "";
    return message;
  },
};

function createBasePortMapping(): PortMapping {
  return { id: "", target: 0, port: 0, protocol: PortProtocol.TCP, name: "" };
}

export const PortMapping = {
  encode(message: PortMapping, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.target !== 0) {
      writer.uint32(16).int32(message.target);
    }
    if (message.port !== 0) {
      writer.uint32(24).int32(message.port);
    }
    if (message.protocol !== PortProtocol.TCP) {
      writer.uint32(32).int32(portProtocolToNumber(message.protocol));
    }
    if (message.name !== "") {
      writer.uint32(42).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortMapping {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortMapping();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.target = reader.int32();
          break;
        case 3:
          message.port = reader.int32();
          break;
        case 4:
          message.protocol = portProtocolFromJSON(reader.int32());
          break;
        case 5:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PortMapping {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      target: isSet(object.target) ? Number(object.target) : 0,
      port: isSet(object.port) ? Number(object.port) : 0,
      protocol: isSet(object.protocol) ? portProtocolFromJSON(object.protocol) : PortProtocol.TCP,
      name: isSet(object.name) ? String(object.name) : "",
    };
  },

  toJSON(message: PortMapping): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.target !== undefined && (obj.target = Math.round(message.target));
    message.port !== undefined && (obj.port = Math.round(message.port));
    message.protocol !== undefined && (obj.protocol = portProtocolToJSON(message.protocol));
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  fromPartial(object: DeepPartial<PortMapping>): PortMapping {
    const message = createBasePortMapping();
    message.id = object.id ?? "";
    message.target = object.target ?? 0;
    message.port = object.port ?? 0;
    message.protocol = object.protocol ?? PortProtocol.TCP;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseCreatePortMappingRequest(): CreatePortMappingRequest {
  return { target: 0, port: 0, protocol: PortProtocol.TCP, name: "" };
}

export const CreatePortMappingRequest = {
  encode(message: CreatePortMappingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.target !== 0) {
      writer.uint32(8).int32(message.target);
    }
    if (message.port !== 0) {
      writer.uint32(16).int32(message.port);
    }
    if (message.protocol !== PortProtocol.TCP) {
      writer.uint32(24).int32(portProtocolToNumber(message.protocol));
    }
    if (message.name !== "") {
      writer.uint32(34).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreatePortMappingRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreatePortMappingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.target = reader.int32();
          break;
        case 2:
          message.port = reader.int32();
          break;
        case 3:
          message.protocol = portProtocolFromJSON(reader.int32());
          break;
        case 4:
          message.name = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreatePortMappingRequest {
    return {
      target: isSet(object.target) ? Number(object.target) : 0,
      port: isSet(object.port) ? Number(object.port) : 0,
      protocol: isSet(object.protocol) ? portProtocolFromJSON(object.protocol) : PortProtocol.TCP,
      name: isSet(object.name) ? String(object.name) : "",
    };
  },

  toJSON(message: CreatePortMappingRequest): unknown {
    const obj: any = {};
    message.target !== undefined && (obj.target = Math.round(message.target));
    message.port !== undefined && (obj.port = Math.round(message.port));
    message.protocol !== undefined && (obj.protocol = portProtocolToJSON(message.protocol));
    message.name !== undefined && (obj.name = message.name);
    return obj;
  },

  fromPartial(object: DeepPartial<CreatePortMappingRequest>): CreatePortMappingRequest {
    const message = createBaseCreatePortMappingRequest();
    message.target = object.target ?? 0;
    message.port = object.port ?? 0;
    message.protocol = object.protocol ?? PortProtocol.TCP;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseCreateSecretRequest(): CreateSecretRequest {
  return { secretName: "", envName: "", propertyName: "", env: false, build: false };
}

export const CreateSecretRequest = {
  encode(message: CreateSecretRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.secretName !== "") {
      writer.uint32(10).string(message.secretName);
    }
    if (message.envName !== "") {
      writer.uint32(18).string(message.envName);
    }
    if (message.propertyName !== "") {
      writer.uint32(26).string(message.propertyName);
    }
    if (message.env === true) {
      writer.uint32(32).bool(message.env);
    }
    if (message.build === true) {
      writer.uint32(40).bool(message.build);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateSecretRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateSecretRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.secretName = reader.string();
          break;
        case 2:
          message.envName = reader.string();
          break;
        case 3:
          message.propertyName = reader.string();
          break;
        case 4:
          message.env = reader.bool();
          break;
        case 5:
          message.build = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateSecretRequest {
    return {
      secretName: isSet(object.secretName) ? String(object.secretName) : "",
      envName: isSet(object.envName) ? String(object.envName) : "",
      propertyName: isSet(object.propertyName) ? String(object.propertyName) : "",
      env: isSet(object.env) ? Boolean(object.env) : false,
      build: isSet(object.build) ? Boolean(object.build) : false,
    };
  },

  toJSON(message: CreateSecretRequest): unknown {
    const obj: any = {};
    message.secretName !== undefined && (obj.secretName = message.secretName);
    message.envName !== undefined && (obj.envName = message.envName);
    message.propertyName !== undefined && (obj.propertyName = message.propertyName);
    message.env !== undefined && (obj.env = message.env);
    message.build !== undefined && (obj.build = message.build);
    return obj;
  },

  fromPartial(object: DeepPartial<CreateSecretRequest>): CreateSecretRequest {
    const message = createBaseCreateSecretRequest();
    message.secretName = object.secretName ?? "";
    message.envName = object.envName ?? "";
    message.propertyName = object.propertyName ?? "";
    message.env = object.env ?? false;
    message.build = object.build ?? false;
    return message;
  },
};

function createBaseSecret(): Secret {
  return { secretName: "", envName: "", propertyName: "", env: false, build: false, id: "" };
}

export const Secret = {
  encode(message: Secret, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.secretName !== "") {
      writer.uint32(10).string(message.secretName);
    }
    if (message.envName !== "") {
      writer.uint32(18).string(message.envName);
    }
    if (message.propertyName !== "") {
      writer.uint32(26).string(message.propertyName);
    }
    if (message.env === true) {
      writer.uint32(32).bool(message.env);
    }
    if (message.build === true) {
      writer.uint32(40).bool(message.build);
    }
    if (message.id !== "") {
      writer.uint32(50).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Secret {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecret();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.secretName = reader.string();
          break;
        case 2:
          message.envName = reader.string();
          break;
        case 3:
          message.propertyName = reader.string();
          break;
        case 4:
          message.env = reader.bool();
          break;
        case 5:
          message.build = reader.bool();
          break;
        case 6:
          message.id = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Secret {
    return {
      secretName: isSet(object.secretName) ? String(object.secretName) : "",
      envName: isSet(object.envName) ? String(object.envName) : "",
      propertyName: isSet(object.propertyName) ? String(object.propertyName) : "",
      env: isSet(object.env) ? Boolean(object.env) : false,
      build: isSet(object.build) ? Boolean(object.build) : false,
      id: isSet(object.id) ? String(object.id) : "",
    };
  },

  toJSON(message: Secret): unknown {
    const obj: any = {};
    message.secretName !== undefined && (obj.secretName = message.secretName);
    message.envName !== undefined && (obj.envName = message.envName);
    message.propertyName !== undefined && (obj.propertyName = message.propertyName);
    message.env !== undefined && (obj.env = message.env);
    message.build !== undefined && (obj.build = message.build);
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial(object: DeepPartial<Secret>): Secret {
    const message = createBaseSecret();
    message.secretName = object.secretName ?? "";
    message.envName = object.envName ?? "";
    message.propertyName = object.propertyName ?? "";
    message.env = object.env ?? false;
    message.build = object.build ?? false;
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseWhereInput(): WhereInput {
  return { id: undefined, name: undefined, status: undefined, serviceGroupId: undefined };
}

export const WhereInput = {
  encode(message: WhereInput, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.status !== undefined) {
      writer.uint32(24).int32(statusToNumber(message.status));
    }
    if (message.serviceGroupId !== undefined) {
      writer.uint32(34).string(message.serviceGroupId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WhereInput {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWhereInput();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.status = statusFromJSON(reader.int32());
          break;
        case 4:
          message.serviceGroupId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WhereInput {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      name: isSet(object.name) ? String(object.name) : undefined,
      status: isSet(object.status) ? statusFromJSON(object.status) : undefined,
      serviceGroupId: isSet(object.serviceGroupId) ? String(object.serviceGroupId) : undefined,
    };
  },

  toJSON(message: WhereInput): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.status !== undefined &&
      (obj.status = message.status !== undefined ? statusToJSON(message.status) : undefined);
    message.serviceGroupId !== undefined && (obj.serviceGroupId = message.serviceGroupId);
    return obj;
  },

  fromPartial(object: DeepPartial<WhereInput>): WhereInput {
    const message = createBaseWhereInput();
    message.id = object.id ?? undefined;
    message.name = object.name ?? undefined;
    message.status = object.status ?? undefined;
    message.serviceGroupId = object.serviceGroupId ?? undefined;
    return message;
  },
};

function createBaseWhereRequest(): WhereRequest {
  return { whereInput: undefined, format: undefined };
}

export const WhereRequest = {
  encode(message: WhereRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.whereInput !== undefined) {
      WhereInput.encode(message.whereInput, writer.uint32(10).fork()).ldelim();
    }
    if (message.format !== undefined) {
      writer.uint32(16).int32(responseFormatToNumber(message.format));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WhereRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWhereRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.whereInput = WhereInput.decode(reader, reader.uint32());
          break;
        case 2:
          message.format = responseFormatFromJSON(reader.int32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WhereRequest {
    return {
      whereInput: isSet(object.whereInput) ? WhereInput.fromJSON(object.whereInput) : undefined,
      format: isSet(object.format) ? responseFormatFromJSON(object.format) : undefined,
    };
  },

  toJSON(message: WhereRequest): unknown {
    const obj: any = {};
    message.whereInput !== undefined &&
      (obj.whereInput = message.whereInput ? WhereInput.toJSON(message.whereInput) : undefined);
    message.format !== undefined &&
      (obj.format = message.format !== undefined ? responseFormatToJSON(message.format) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<WhereRequest>): WhereRequest {
    const message = createBaseWhereRequest();
    message.whereInput = (object.whereInput !== undefined && object.whereInput !== null)
      ? WhereInput.fromPartial(object.whereInput)
      : undefined;
    message.format = object.format ?? undefined;
    return message;
  },
};

function createBaseWhereResponse(): WhereResponse {
  return { services: [], servicesJson: undefined };
}

export const WhereResponse = {
  encode(message: WhereResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.services) {
      ShowResponse.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.servicesJson !== undefined) {
      writer.uint32(18).string(message.servicesJson);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WhereResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWhereResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.services.push(ShowResponse.decode(reader, reader.uint32()));
          break;
        case 2:
          message.servicesJson = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WhereResponse {
    return {
      services: Array.isArray(object?.services) ? object.services.map((e: any) => ShowResponse.fromJSON(e)) : [],
      servicesJson: isSet(object.servicesJson) ? String(object.servicesJson) : undefined,
    };
  },

  toJSON(message: WhereResponse): unknown {
    const obj: any = {};
    if (message.services) {
      obj.services = message.services.map((e) => e ? ShowResponse.toJSON(e) : undefined);
    } else {
      obj.services = [];
    }
    message.servicesJson !== undefined && (obj.servicesJson = message.servicesJson);
    return obj;
  },

  fromPartial(object: DeepPartial<WhereResponse>): WhereResponse {
    const message = createBaseWhereResponse();
    message.services = object.services?.map((e) => ShowResponse.fromPartial(e)) || [];
    message.servicesJson = object.servicesJson ?? undefined;
    return message;
  },
};

function createBaseDestroyRequest(): DestroyRequest {
  return { id: "" };
}

export const DestroyRequest = {
  encode(message: DestroyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DestroyRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDestroyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DestroyRequest {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: DestroyRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial(object: DeepPartial<DestroyRequest>): DestroyRequest {
    const message = createBaseDestroyRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseDestroyResponse(): DestroyResponse {
  return { service: undefined };
}

export const DestroyResponse = {
  encode(message: DestroyResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.service !== undefined) {
      ShowResponse.encode(message.service, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DestroyResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDestroyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.service = ShowResponse.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DestroyResponse {
    return { service: isSet(object.service) ? ShowResponse.fromJSON(object.service) : undefined };
  },

  toJSON(message: DestroyResponse): unknown {
    const obj: any = {};
    message.service !== undefined && (obj.service = message.service ? ShowResponse.toJSON(message.service) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<DestroyResponse>): DestroyResponse {
    const message = createBaseDestroyResponse();
    message.service = (object.service !== undefined && object.service !== null)
      ? ShowResponse.fromPartial(object.service)
      : undefined;
    return message;
  },
};

function createBaseCiBuildInfo(): CiBuildInfo {
  return { status: CiBuildStatus.CI_BUILD_STATUS_UNSPECIFIED, logs: "" };
}

export const CiBuildInfo = {
  encode(message: CiBuildInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== CiBuildStatus.CI_BUILD_STATUS_UNSPECIFIED) {
      writer.uint32(8).int32(ciBuildStatusToNumber(message.status));
    }
    if (message.logs !== "") {
      writer.uint32(18).string(message.logs);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CiBuildInfo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCiBuildInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = ciBuildStatusFromJSON(reader.int32());
          break;
        case 2:
          message.logs = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CiBuildInfo {
    return {
      status: isSet(object.status) ? ciBuildStatusFromJSON(object.status) : CiBuildStatus.CI_BUILD_STATUS_UNSPECIFIED,
      logs: isSet(object.logs) ? String(object.logs) : "",
    };
  },

  toJSON(message: CiBuildInfo): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = ciBuildStatusToJSON(message.status));
    message.logs !== undefined && (obj.logs = message.logs);
    return obj;
  },

  fromPartial(object: DeepPartial<CiBuildInfo>): CiBuildInfo {
    const message = createBaseCiBuildInfo();
    message.status = object.status ?? CiBuildStatus.CI_BUILD_STATUS_UNSPECIFIED;
    message.logs = object.logs ?? "";
    return message;
  },
};

function createBaseBuildRequest(): BuildRequest {
  return { id: "" };
}

export const BuildRequest = {
  encode(message: BuildRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BuildRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBuildRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BuildRequest {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: BuildRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial(object: DeepPartial<BuildRequest>): BuildRequest {
    const message = createBaseBuildRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseBuildRespones(): BuildRespones {
  return {};
}

export const BuildRespones = {
  encode(_: BuildRespones, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BuildRespones {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBuildRespones();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): BuildRespones {
    return {};
  },

  toJSON(_: BuildRespones): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<BuildRespones>): BuildRespones {
    const message = createBaseBuildRespones();
    return message;
  },
};

function createBaseUpdateRequest(): UpdateRequest {
  return {
    id: "",
    envVars: "",
    portMappings: [],
    replicas: 0,
    secretsForBuildArgs: [],
    withHttpProxy: false,
    grpc: false,
  };
}

export const UpdateRequest = {
  encode(message: UpdateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.envVars !== "") {
      writer.uint32(18).string(message.envVars);
    }
    for (const v of message.portMappings) {
      CreatePortMappingRequest.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.replicas !== 0) {
      writer.uint32(32).int32(message.replicas);
    }
    for (const v of message.secretsForBuildArgs) {
      SecretForBuildArgs.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.withHttpProxy === true) {
      writer.uint32(48).bool(message.withHttpProxy);
    }
    if (message.grpc === true) {
      writer.uint32(56).bool(message.grpc);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.envVars = reader.string();
          break;
        case 3:
          message.portMappings.push(CreatePortMappingRequest.decode(reader, reader.uint32()));
          break;
        case 4:
          message.replicas = reader.int32();
          break;
        case 5:
          message.secretsForBuildArgs.push(SecretForBuildArgs.decode(reader, reader.uint32()));
          break;
        case 6:
          message.withHttpProxy = reader.bool();
          break;
        case 7:
          message.grpc = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UpdateRequest {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      envVars: isSet(object.envVars) ? String(object.envVars) : "",
      portMappings: Array.isArray(object?.portMappings)
        ? object.portMappings.map((e: any) => CreatePortMappingRequest.fromJSON(e))
        : [],
      replicas: isSet(object.replicas) ? Number(object.replicas) : 0,
      secretsForBuildArgs: Array.isArray(object?.secretsForBuildArgs)
        ? object.secretsForBuildArgs.map((e: any) => SecretForBuildArgs.fromJSON(e))
        : [],
      withHttpProxy: isSet(object.withHttpProxy) ? Boolean(object.withHttpProxy) : false,
      grpc: isSet(object.grpc) ? Boolean(object.grpc) : false,
    };
  },

  toJSON(message: UpdateRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.envVars !== undefined && (obj.envVars = message.envVars);
    if (message.portMappings) {
      obj.portMappings = message.portMappings.map((e) => e ? CreatePortMappingRequest.toJSON(e) : undefined);
    } else {
      obj.portMappings = [];
    }
    message.replicas !== undefined && (obj.replicas = Math.round(message.replicas));
    if (message.secretsForBuildArgs) {
      obj.secretsForBuildArgs = message.secretsForBuildArgs.map((e) => e ? SecretForBuildArgs.toJSON(e) : undefined);
    } else {
      obj.secretsForBuildArgs = [];
    }
    message.withHttpProxy !== undefined && (obj.withHttpProxy = message.withHttpProxy);
    message.grpc !== undefined && (obj.grpc = message.grpc);
    return obj;
  },

  fromPartial(object: DeepPartial<UpdateRequest>): UpdateRequest {
    const message = createBaseUpdateRequest();
    message.id = object.id ?? "";
    message.envVars = object.envVars ?? "";
    message.portMappings = object.portMappings?.map((e) => CreatePortMappingRequest.fromPartial(e)) || [];
    message.replicas = object.replicas ?? 0;
    message.secretsForBuildArgs = object.secretsForBuildArgs?.map((e) => SecretForBuildArgs.fromPartial(e)) || [];
    message.withHttpProxy = object.withHttpProxy ?? false;
    message.grpc = object.grpc ?? false;
    return message;
  },
};

function createBaseUpdateResponse(): UpdateResponse {
  return {};
}

export const UpdateResponse = {
  encode(_: UpdateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): UpdateResponse {
    return {};
  },

  toJSON(_: UpdateResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<UpdateResponse>): UpdateResponse {
    const message = createBaseUpdateResponse();
    return message;
  },
};

function createBaseCiBuildInfoRequest(): CiBuildInfoRequest {
  return { id: "" };
}

export const CiBuildInfoRequest = {
  encode(message: CiBuildInfoRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CiBuildInfoRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCiBuildInfoRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CiBuildInfoRequest {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: CiBuildInfoRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial(object: DeepPartial<CiBuildInfoRequest>): CiBuildInfoRequest {
    const message = createBaseCiBuildInfoRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseCiBuildInfoResponse(): CiBuildInfoResponse {
  return { status: CiBuildStatus.CI_BUILD_STATUS_UNSPECIFIED, logs: "" };
}

export const CiBuildInfoResponse = {
  encode(message: CiBuildInfoResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== CiBuildStatus.CI_BUILD_STATUS_UNSPECIFIED) {
      writer.uint32(8).int32(ciBuildStatusToNumber(message.status));
    }
    if (message.logs !== "") {
      writer.uint32(18).string(message.logs);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CiBuildInfoResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCiBuildInfoResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = ciBuildStatusFromJSON(reader.int32());
          break;
        case 2:
          message.logs = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CiBuildInfoResponse {
    return {
      status: isSet(object.status) ? ciBuildStatusFromJSON(object.status) : CiBuildStatus.CI_BUILD_STATUS_UNSPECIFIED,
      logs: isSet(object.logs) ? String(object.logs) : "",
    };
  },

  toJSON(message: CiBuildInfoResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = ciBuildStatusToJSON(message.status));
    message.logs !== undefined && (obj.logs = message.logs);
    return obj;
  },

  fromPartial(object: DeepPartial<CiBuildInfoResponse>): CiBuildInfoResponse {
    const message = createBaseCiBuildInfoResponse();
    message.status = object.status ?? CiBuildStatus.CI_BUILD_STATUS_UNSPECIFIED;
    message.logs = object.logs ?? "";
    return message;
  },
};

export type APIDefinition = typeof APIDefinition;
export const APIDefinition = {
  name: "API",
  fullName: "Service.API",
  methods: {
    showStream: {
      name: "showStream",
      requestType: ShowRequest,
      requestStream: false,
      responseType: ShowResponse,
      responseStream: true,
      options: {},
    },
    create: {
      name: "create",
      requestType: CreateRequest,
      requestStream: false,
      responseType: CreateResponse,
      responseStream: false,
      options: {},
    },
    where: {
      name: "where",
      requestType: WhereRequest,
      requestStream: false,
      responseType: WhereResponse,
      responseStream: false,
      options: {},
    },
    destroy: {
      name: "destroy",
      requestType: DestroyRequest,
      requestStream: false,
      responseType: DestroyResponse,
      responseStream: false,
      options: {},
    },
    build: {
      name: "build",
      requestType: BuildRequest,
      requestStream: false,
      responseType: BuildRespones,
      responseStream: false,
      options: {},
    },
    update: {
      name: "update",
      requestType: UpdateRequest,
      requestStream: false,
      responseType: UpdateResponse,
      responseStream: false,
      options: {},
    },
    ciBuildInfo: {
      name: "ciBuildInfo",
      requestType: CiBuildInfoRequest,
      requestStream: false,
      responseType: CiBuildInfoResponse,
      responseStream: true,
      options: {},
    },
  },
} as const;

export interface APIServiceImplementation<CallContextExt = {}> {
  showStream(
    request: ShowRequest,
    context: CallContext & CallContextExt,
  ): ServerStreamingMethodResult<DeepPartial<ShowResponse>>;
  create(request: CreateRequest, context: CallContext & CallContextExt): Promise<DeepPartial<CreateResponse>>;
  where(request: WhereRequest, context: CallContext & CallContextExt): Promise<DeepPartial<WhereResponse>>;
  destroy(request: DestroyRequest, context: CallContext & CallContextExt): Promise<DeepPartial<DestroyResponse>>;
  build(request: BuildRequest, context: CallContext & CallContextExt): Promise<DeepPartial<BuildRespones>>;
  update(request: UpdateRequest, context: CallContext & CallContextExt): Promise<DeepPartial<UpdateResponse>>;
  ciBuildInfo(
    request: CiBuildInfoRequest,
    context: CallContext & CallContextExt,
  ): ServerStreamingMethodResult<DeepPartial<CiBuildInfoResponse>>;
}

export interface APIClient<CallOptionsExt = {}> {
  showStream(request: DeepPartial<ShowRequest>, options?: CallOptions & CallOptionsExt): AsyncIterable<ShowResponse>;
  create(request: DeepPartial<CreateRequest>, options?: CallOptions & CallOptionsExt): Promise<CreateResponse>;
  where(request: DeepPartial<WhereRequest>, options?: CallOptions & CallOptionsExt): Promise<WhereResponse>;
  destroy(request: DeepPartial<DestroyRequest>, options?: CallOptions & CallOptionsExt): Promise<DestroyResponse>;
  build(request: DeepPartial<BuildRequest>, options?: CallOptions & CallOptionsExt): Promise<BuildRespones>;
  update(request: DeepPartial<UpdateRequest>, options?: CallOptions & CallOptionsExt): Promise<UpdateResponse>;
  ciBuildInfo(
    request: DeepPartial<CiBuildInfoRequest>,
    options?: CallOptions & CallOptionsExt,
  ): AsyncIterable<CiBuildInfoResponse>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export type ServerStreamingMethodResult<Response> = { [Symbol.asyncIterator](): AsyncIterator<Response, void> };
