/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "AppLatticeCommon";

export enum ResponseFormat {
  JSON = "JSON",
  JSON_STRING = "JSON_STRING",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function responseFormatFromJSON(object: any): ResponseFormat {
  switch (object) {
    case 0:
    case "JSON":
      return ResponseFormat.JSON;
    case 1:
    case "JSON_STRING":
      return ResponseFormat.JSON_STRING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ResponseFormat.UNRECOGNIZED;
  }
}

export function responseFormatToJSON(object: ResponseFormat): string {
  switch (object) {
    case ResponseFormat.JSON:
      return "JSON";
    case ResponseFormat.JSON_STRING:
      return "JSON_STRING";
    case ResponseFormat.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function responseFormatToNumber(object: ResponseFormat): number {
  switch (object) {
    case ResponseFormat.JSON:
      return 0;
    case ResponseFormat.JSON_STRING:
      return 1;
    case ResponseFormat.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface Timestamps {
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
  destroyedAt: Date | undefined;
}

function createBaseTimestamps(): Timestamps {
  return { createdAt: undefined, updatedAt: undefined, destroyedAt: undefined };
}

export const Timestamps = {
  encode(message: Timestamps, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(50).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(58).fork()).ldelim();
    }
    if (message.destroyedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.destroyedAt), writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Timestamps {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTimestamps();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 6:
          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 7:
          message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 8:
          message.destroyedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Timestamps {
    return {
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? fromJsonTimestamp(object.updatedAt) : undefined,
      destroyedAt: isSet(object.destroyedAt) ? fromJsonTimestamp(object.destroyedAt) : undefined,
    };
  },

  toJSON(message: Timestamps): unknown {
    const obj: any = {};
    message.createdAt !== undefined && (obj.createdAt = message.createdAt.toISOString());
    message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt.toISOString());
    message.destroyedAt !== undefined && (obj.destroyedAt = message.destroyedAt.toISOString());
    return obj;
  },

  fromPartial(object: DeepPartial<Timestamps>): Timestamps {
    const message = createBaseTimestamps();
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.destroyedAt = object.destroyedAt ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
