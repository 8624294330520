import React, { ReactElement } from 'react';
import MobileLogo from './MobileLogo';
import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  Icon,
} from '@chakra-ui/react';
import { EditIcon, HamburgerIcon } from '@chakra-ui/icons';
import copy from '../../utils/copy';
import { Link } from 'react-router-dom';
import { RiDoorOpenFill } from 'react-icons/ri';
import { LuLogOut } from 'react-icons/lu';

const MobileNav: React.FC = (): ReactElement => {
  return (
    <Flex
      position='fixed'
      top={0}
      left={0}
      pt={3}
      pb={3}
      className='mobile-nav'
      width='100%'
    >
      <Box flexGrow={.3} pl={6}>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label='Options'
            icon={<HamburgerIcon />}
            variant='accent'
          />
          <MenuList>
            <MenuItem>
              <Link to='/service-groups'>
                {copy.entities.ServiceGroup}s
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to='/billing'>
                Billing
              </Link>
            </MenuItem>
            <MenuDivider />
            <MenuItem >
              <Link to='/logout'>
                <Icon mb={-0.5} mr={1} as={LuLogOut} />Logout
              </Link>
            </MenuItem>

          </MenuList>
        </Menu>
      </Box>
      <Box flexGrow={5}>
        <MobileLogo />
      </Box>
    </Flex >
  );

};

export default MobileNav;
