import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Spinner
} from '@chakra-ui/react';
import React, { ReactElement, useState } from 'react';
import { Control, FieldArrayWithId, useFieldArray, UseFieldArrayRemove, UseFormRegister } from 'react-hook-form';
import { CreateRequest } from '../../../../../tsProtoCodegen/service';
import { SecretListResponse, ShowSecretResponse } from '../../../../../tsProtoCodegen/ServiceGroup';
import { RemoteResource, RemoteResourceStatus } from '../../Secrets/Secrets';
import ErrorElem from '../../../../../Components/ErrorElem/ErrorElem';
import copy from '../../../../../utils/copy';
import grpcClient from '../../../grpcClient';
import metadataWithJwt from '../../../../../utils/metadataWithJwt';
import { colors } from '../../../../../theme/theme';
import PropertyNames from './PropertyNames';

export type SecretItemProps = {
  field: FieldArrayWithId<CreateRequest, 'secrets', 'id'>,
  index: number,
  remove: UseFieldArrayRemove,
  register: UseFormRegister<CreateRequest>,
  secrets: ShowSecretResponse[],
  serviceGroupId: string
}



const SecretItem: React.FC<SecretItemProps> = ({ register, field, index, secrets, remove, serviceGroupId }): ReactElement => {
  const [propertyNames, setPropertyNames] = useState<{ propertyNames: string[], abortController: AbortController } & RemoteResource>({
    propertyNames: [],
    status: RemoteResourceStatus.INIT,
    abortController: new AbortController(),
  });

  const fetchSecretProps = async (secretName: string) => {
    if (propertyNames.status === RemoteResourceStatus.LOADING) {
      propertyNames?.abortController?.abort();
    }

    const abortController = new AbortController();

    setPropertyNames({
      status: RemoteResourceStatus.LOADING,
      abortController,
      error: undefined,
      propertyNames: []
    });

    const { data } = await grpcClient.secretShow(
      { id: serviceGroupId, secretName, namespace: 'default' },
      { metadata: metadataWithJwt(), signal: propertyNames.abortController.signal },
    );

    setPropertyNames({
      ...propertyNames,
      status: RemoteResourceStatus.LOADED,
      error: undefined,
      propertyNames: Object.keys(data),
    });
  };

  return (
    <Grid
      gap={4}
      textAlign='left'
      templateColumns='repeat(3, 1fr)'
      templateRows='repeat(1, 1fr)'
      border='1px solid'
      borderColor={colors.brand.purple[900]}
      borderRadius={6}
      p={3}
    >
      <GridItem>
        <FormControl>
          <FormLabel>Env Var Name</FormLabel>
          <Input
            type="text"
            id={`secrets.${index}.name`}
            {...register(`secrets.${index}.envName`, { required: true })}
            placeholder='MY_ENV_VAR'
          />
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl>
          <FormLabel>Secret Name</FormLabel>
          <Select
            id={`secrets.${index}.protocol`}
            {...register(`secrets.${index}.secretName`, {
              required: true, onChange: (e) => {
                fetchSecretProps(e.target.value);
              }
            })}
          >
            {secrets.map(({ name }: ShowSecretResponse) => (
              <option key={`secret-${name}`} value={`${name}`}>{name}</option>
            ))}
          </Select>
        </FormControl>
      </GridItem>
      <GridItem>
        <FormControl>
          <FormLabel>Property Name</FormLabel>
          <PropertyNames index={index} register={register} propertyNames={propertyNames} />
        </FormControl>
      </GridItem>

      <GridItem colSpan={3}>
        <FormControl>
          <Checkbox {...register(`secrets.${index}.env`)}>Environment Variable (For process running in container)</Checkbox>
          <Checkbox {...register(`secrets.${index}.build`)}>Build Variable (For image build e.g. ARG in Dockerfile)</Checkbox>
        </FormControl>
        <Box textAlign='right'>
          <Button variant='link' type="button" onClick={() => remove(index)}>Remove</Button>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default SecretItem;
