import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer';
import { PaymentMethodState } from '../Containers/Billing/PaymentMethod/redux/types';
import { UserState } from '../Containers/User/userSlice';

export type State = {
  user: UserState,
  paymentMethod: PaymentMethodState,
}

const setupStore = () => configureStore({ reducer });

export type Store = ReturnType<typeof setupStore>

const store = setupStore();

export default store;
