import { State } from '../../../reduxStore/reduxStore';
import { ShowResponse } from '../../../tsProtoCodegen/user';
import { UserState } from '../userSlice';

export const userStateNotNull = ({ user }: State): UserState => {
  if (!user.user) throw new Error('User is null when is expected to be set!');
  if (!user.customer) throw new Error('Customer is null when is expected to be set!');

  return user;
};

const userNotNull = ({ user: { user, customer } }: State): ShowResponse => {
  if (!user) throw new Error('User is null when is expected to be set!');
  if (!customer) throw new Error('Customer is null when is expected to be set!');

  return user;
};

export default userNotNull;