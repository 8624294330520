import { Flex, Box, Image } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

const MobileLogo: React.FC = (): ReactElement => {
  return (
    <Link to='/'>
      <Flex className='mobile-logo' justifyContent='center'>
        <Box flex={.1} mr={3} position='relative'>
          <Image
            position='absolute'
            top={0}
            bottom={0}
            margin='auto'
            src='/images/logo-small.svg'
          />
        </Box>
        <Box flex={2} maxW={170} fontSize='3xl' color='brand.purple.100'>
          App Lattice
        </Box>
      </Flex>
    </Link >
  );
};

export default MobileLogo;
