import React, { ReactElement } from 'react';
import { StatusPretty } from '../../../tsProtoCodegen/ServiceGroup';
import { Box, Flex, Image, Spinner } from '@chakra-ui/react';

const Status: React.FC<{ status: StatusPretty }> = ({ status }): ReactElement => {
  const statuses = {
    [StatusPretty.STATUS_PRETTY_ACTIVE]: 'active',
    [StatusPretty.STATUS_PRETTY_DELETING]: 'deleting',
    [StatusPretty.STATUS_PRETTY_DELETED]: 'deleted',
    [StatusPretty.STATUS_PRETTY_ERROR]: 'error',
    [StatusPretty.STATUS_PRETTY_INITIALIZING]: <>initializing<Spinner size='xs' /></>,
    [StatusPretty.STATUS_PRETTY_DNS_SETUP]: <>Configuring DNS<Spinner size='xs' /></>,
    [StatusPretty.STATUS_PRETTY_INSTALLING_VENDOR_SERVICES]: <>Installing ingress, data-layer, etc.<Spinner size='xs' /></>,
    [StatusPretty.STATUS_PRETTY_INSTALLING_PROPRIETARY_SERVICES]: <>Installing telemetry stack <Spinner size='xs' /></>,
    [StatusPretty.UNRECOGNIZED]: 'unknown',
    [StatusPretty.STATUS_PRETTY_UNSPECIFIED]: 'unknown',
  };

  const statusesIcon = {
    [StatusPretty.STATUS_PRETTY_ACTIVE]: '/images/icon-circle-green.svg',
    [StatusPretty.STATUS_PRETTY_DELETING]: '/images/icon-circle-yellow.svg',
    [StatusPretty.STATUS_PRETTY_DELETED]: '/images/icon-circle-red.svg',
    [StatusPretty.STATUS_PRETTY_ERROR]: '/images/icon-circle-red.svg',
    [StatusPretty.STATUS_PRETTY_INITIALIZING]: '/images/icon-circle-yellow.svg',
    [StatusPretty.UNRECOGNIZED]: '/images/icon-circle-red.svg',
    [StatusPretty.STATUS_PRETTY_UNSPECIFIED]: '/images/icon-circle-red.svg',
    [StatusPretty.STATUS_PRETTY_DNS_SETUP]: '/images/icon-circle-yellow.svg',
    [StatusPretty.STATUS_PRETTY_INSTALLING_VENDOR_SERVICES]: '/images/icon-circle-yellow.svg',
    [StatusPretty.STATUS_PRETTY_INSTALLING_PROPRIETARY_SERVICES]: '/images/icon-circle-yellow.svg',
  };

  return (
    <Flex justifyContent='flex-start' textAlign='left' alignContent='center'>
      <Box width='17px' height='17px' position='relative'>
        <Image
          position='absolute'
          top={0}
          bottom={0}
          margin='auto'
          width='10px'
          src={statusesIcon[status]}
          alt={`ServiceGroup status: ${statuses[status]}`}
          title={`ServiceGroup status: ${statuses[status]}`}
        />
      </Box>
      <Box fontSize='xs' fontWeight='bold'>
        {statuses[status]}
      </Box>
    </Flex>

  );
};

export default Status;
