import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

// define the base component styles
const baseStyle = definePartsStyle({
  // define the part you're going to style
  button: {
    // this will style the MenuButton component
    fontWeight: 'medium',
    bg: 'teal.500',
    color: 'brand.purple.800',
    _hover: {
      bg: 'teal.600',
      color: 'brand.purple.800',
    },
  },
  item: {
    color: 'brand.purple.800',
  },
});
export const menuTheme = defineMultiStyleConfig({ baseStyle });
