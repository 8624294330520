import { errorDetailsClientMiddleware } from 'nice-grpc-error-details';
import { createChannel, createClientFactory } from 'nice-grpc-web';
import { APIDefinition } from '../../tsProtoCodegen/billing.customer';

const channel = createChannel(process.env.REACT_APP_BILLING_GRPC_URL as string);

const billingCustomerApiClient = createClientFactory()
  // .use(errorDetailsClientMiddleware)
  .create(APIDefinition, channel);

export default billingCustomerApiClient;
