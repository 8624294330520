import React, { ReactElement, useEffect, useState } from 'react';
import { ShowResponse } from '../../../../../tsProtoCodegen/service';
import { ShowResponse as ServiceGroup } from '../../../../../tsProtoCodegen/ServiceGroup';
import Item from './Item';
import {
  Button,
  Card,
  IconButton,
  Spinner,
  Box,
} from '@chakra-ui/react';
import { RemoteResourceStatus, RemoteResource } from '../../Secrets/Secrets';
import ErrorElem from '../../../../../Components/ErrorElem/ErrorElem';
import copy from '../../../../../utils/copy';
import { AddIcon } from '@chakra-ui/icons';
import { SimpleGrid } from '@chakra-ui/react';
import fetchServices from './fetchServices';
import { useServiceList } from '../../../../Service/List/ServiceListContext';


const ServiceList: React.FC<{ serviceGroup: ServiceGroup, onShowCreate: () => void }> = ({ serviceGroup, onShowCreate }): ReactElement => {
  // const [services, setServices] = useState<{ services: ShowResponse[] } & RemoteResource>({ status: RemoteResourceStatus.INIT, services: [] });
  const { services, update } = useServiceList();
  const { services: servicesRes, status } = services;

  // useEffect(() => {
  //   if (services.status === RemoteResourceStatus.INIT) {
  //     fetchServices(serviceGroup.id, setServices, services);
  //   }
  // }, [status, serviceGroup.id, services]);

  if (status === RemoteResourceStatus.INIT || status === RemoteResourceStatus.LOADING) {
    return <>Loading services data...<Spinner /></>;
  }

  const getError = () => {
    if (status === RemoteResourceStatus.ERROR) {
      return (
        <Box mb={2}>
          <ErrorElem>
            Error fetching {copy.entities.Service}s.
            <Button
              onClick={() => update()}
            >Reload</Button>
          </ErrorElem>
        </Box>
      );
    }

    return <></>;
  };


  if (servicesRes.length === 0) {
    if (status === RemoteResourceStatus.ERROR) {
      return getError();
    }
    return (
      <Card variant='bigTextDashed'
        color='brand.purple.300'
        textAlign='center'
      >
        You have not created any Services yet.
        <Button mt={22}
          onClick={onShowCreate}
        >
          Create a Service
        </Button>
      </Card>
    );
  }

  const getVariant = () => {
    if (servicesRes.length > 0) {
      return 'outline';
    }

    return 'bigTextDashed';
  };

  return (
    <Card
      p={3}
      variant={getVariant()}
      color='brand.purple.300'
      position='relative'
    >
      {getError()}
      <SimpleGrid gap={3} columns={{ sm: 1, lg: 2 }}>
        {servicesRes.map((service) => {
          const { id } = service;

          return (
            <Item
              key={`service-${id}service={service}`}
              serviceGroup={serviceGroup}
              service={service}
              onDestroy={update}
            />
          );
        })}
      </SimpleGrid>

      <Box textAlign='right'>
        <IconButton
          w={10}
          mb={-36}
          onClick={onShowCreate}
          icon={<AddIcon />}
          borderRadius='full'
          aria-label={'Create new  Service'}
          size='lg'
          p={8}
        />
      </Box>
    </Card>
  );
};

export default ServiceList;
