import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PaymentMethod, PaymentMethodState } from './types';
import fetchPaymentMethodsThunk from './fetchPaymentMethodsThunk';

export const initialState: PaymentMethodState = {
  loading: false,
  error: null,
  paymentMethods: [],
};

const onPending = (state: PaymentMethodState) => {
  state.loading = true;
  state.error = null;
};

const onFullfilled = (
  state: PaymentMethodState,
  action: PayloadAction<PaymentMethod[] | null>
) => {
  state.loading = false;
  state.error = null;
  state.paymentMethods = action.payload || [];
};

const onError = (state: PaymentMethodState, error: any) => {
  const {
    error: { message },
  } = error;

  state.loading = false;

  state.error = message;
};

const paymentMethodSlice = createSlice({
  name: 'paymentMethod',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentMethodsThunk.pending, onPending)
      .addCase(fetchPaymentMethodsThunk.fulfilled, onFullfilled)
      .addCase(fetchPaymentMethodsThunk.rejected, onError);
  },
});

export default paymentMethodSlice;
