/* eslint-disable */
import type { CallContext, CallOptions } from "nice-grpc-common";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "Authenticate";

/** jwt in metadata */
export interface AuthenticateRequest {
}

export interface JwtPayload {
  userId: string;
  iat: number;
  tokenData: { [key: string]: string };
}

export interface JwtPayload_TokenDataEntry {
  key: string;
  value: string;
}

export interface AuthenticateResponse {
  jwtPayload: JwtPayload | undefined;
}

function createBaseAuthenticateRequest(): AuthenticateRequest {
  return {};
}

export const AuthenticateRequest = {
  encode(_: AuthenticateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthenticateRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthenticateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): AuthenticateRequest {
    return {};
  },

  toJSON(_: AuthenticateRequest): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<AuthenticateRequest>): AuthenticateRequest {
    const message = createBaseAuthenticateRequest();
    return message;
  },
};

function createBaseJwtPayload(): JwtPayload {
  return { userId: "", iat: 0, tokenData: {} };
}

export const JwtPayload = {
  encode(message: JwtPayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    if (message.iat !== 0) {
      writer.uint32(16).int32(message.iat);
    }
    Object.entries(message.tokenData).forEach(([key, value]) => {
      JwtPayload_TokenDataEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JwtPayload {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJwtPayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.userId = reader.string();
          break;
        case 2:
          message.iat = reader.int32();
          break;
        case 3:
          const entry3 = JwtPayload_TokenDataEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.tokenData[entry3.key] = entry3.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): JwtPayload {
    return {
      userId: isSet(object.userId) ? String(object.userId) : "",
      iat: isSet(object.iat) ? Number(object.iat) : 0,
      tokenData: isObject(object.tokenData)
        ? Object.entries(object.tokenData).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: JwtPayload): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.iat !== undefined && (obj.iat = Math.round(message.iat));
    obj.tokenData = {};
    if (message.tokenData) {
      Object.entries(message.tokenData).forEach(([k, v]) => {
        obj.tokenData[k] = v;
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<JwtPayload>): JwtPayload {
    const message = createBaseJwtPayload();
    message.userId = object.userId ?? "";
    message.iat = object.iat ?? 0;
    message.tokenData = Object.entries(object.tokenData ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseJwtPayload_TokenDataEntry(): JwtPayload_TokenDataEntry {
  return { key: "", value: "" };
}

export const JwtPayload_TokenDataEntry = {
  encode(message: JwtPayload_TokenDataEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JwtPayload_TokenDataEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJwtPayload_TokenDataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): JwtPayload_TokenDataEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: JwtPayload_TokenDataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(object: DeepPartial<JwtPayload_TokenDataEntry>): JwtPayload_TokenDataEntry {
    const message = createBaseJwtPayload_TokenDataEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseAuthenticateResponse(): AuthenticateResponse {
  return { jwtPayload: undefined };
}

export const AuthenticateResponse = {
  encode(message: AuthenticateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.jwtPayload !== undefined) {
      JwtPayload.encode(message.jwtPayload, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthenticateResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthenticateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.jwtPayload = JwtPayload.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AuthenticateResponse {
    return { jwtPayload: isSet(object.jwtPayload) ? JwtPayload.fromJSON(object.jwtPayload) : undefined };
  },

  toJSON(message: AuthenticateResponse): unknown {
    const obj: any = {};
    message.jwtPayload !== undefined &&
      (obj.jwtPayload = message.jwtPayload ? JwtPayload.toJSON(message.jwtPayload) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<AuthenticateResponse>): AuthenticateResponse {
    const message = createBaseAuthenticateResponse();
    message.jwtPayload = (object.jwtPayload !== undefined && object.jwtPayload !== null)
      ? JwtPayload.fromPartial(object.jwtPayload)
      : undefined;
    return message;
  },
};

export type APIDefinition = typeof APIDefinition;
export const APIDefinition = {
  name: "API",
  fullName: "Authenticate.API",
  methods: {
    authenticate: {
      name: "authenticate",
      requestType: AuthenticateRequest,
      requestStream: false,
      responseType: AuthenticateResponse,
      responseStream: false,
      options: {},
    },
  },
} as const;

export interface APIServiceImplementation<CallContextExt = {}> {
  authenticate(
    request: AuthenticateRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<AuthenticateResponse>>;
}

export interface APIClient<CallOptionsExt = {}> {
  authenticate(
    request: DeepPartial<AuthenticateRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<AuthenticateResponse>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
