import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { RemoteResource, RemoteResourceStatus } from '../../Containers/ServiceGroup/Show/Secrets/Secrets';
import { Button, Spinner } from '@chakra-ui/react';
import ErrorElem from '../ErrorElem/ErrorElem';

const PasswordPeek: React.FC<{
  onPeek: (setResource: Dispatch<SetStateAction<{ value?: string | undefined; } & RemoteResource>>) => void,
}> = ({ onPeek }): ReactElement => {

  const [resource, setResource] = useState<{ value?: string | undefined } & RemoteResource>({ status: RemoteResourceStatus.INIT });


  if (resource?.status === RemoteResourceStatus.INIT) {
    return (<>
      *******************
      <button onClick={() => onPeek(setResource)}>👁️</button>
    </>);
  }

  if (resource?.status === RemoteResourceStatus.LOADING) {
    return <Spinner />;
  }

  if (resource?.status === RemoteResourceStatus.ERROR) {
    return (
      <ErrorElem>
        <Button onClick={() => onPeek(setResource)} size='sm'>
          {resource?.error}
        </Button>
      </ErrorElem>
    );
  }

  return (
    <>
      {resource?.value}
      <button onClick={() => setResource({ value: undefined, status: RemoteResourceStatus.INIT, error: undefined })}>🔒️</button>
    </>
  );
};

export default PasswordPeek;
