import { combineReducers } from 'redux';
import paymentMethodSlice from '../Containers/Billing/PaymentMethod/redux/slice';
import userSlice from '../Containers/User/userSlice';

const reducer = combineReducers({
  user: userSlice.reducer,
  paymentMethod: paymentMethodSlice.reducer,
});

export default reducer;
