import React from 'react';
import Create from './Create/Create';
import List from './List/List';
import Show from './Show/Show';

const routes = [
  {
    path: '/service-groups/create',
    element: <Create />
  },
  {
    path: '/service-groups',
    element: <List />
  },
  {
    path: '/service-groups/:id',
    element: <Show />
  },
];

export default routes;