import React, { ReactElement } from 'react';
import Item from './Item';
import { BillingActivity } from '../useBillingActivity';
import { Charge } from '../../../tsProtoCodegen/billing.customer';
import { Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

const Activity: React.FC<BillingActivity> = ({ data, loading, error }): ReactElement => {
  if (loading) return <div>Loading...</div>;

  if (error) return <div>{error}</div>;

  if (data.length === 0) {
    return (
      <div>
        <h2>Activity</h2>
        <em>There is no activity.</em>
      </div>
    );
  }

  return (
    <TableContainer maxW={{ xs: 300, md: '100%' }}>
      <Table size='sm' variant='simple'>
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Transaction Type</Th>
            <Th isNumeric>Amount</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map(
            ({ created, amount }: Charge, i: number) =>
              <Item
                key={`billing-activity-${i}`}
                created={created}
                amount={amount}
              />
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default Activity;

