import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { ShowResponse } from '../../../tsProtoCodegen/ServiceGroup';
import { Box, Flex } from '@chakra-ui/react';
import Status from '../List/Status';
import Details from './Details';
import serviceGroupStreamer, { ServiceGroupStream } from '../List/serviceGroupStream';
import { RemoteResourceStatus } from './Secrets/Secrets';

const Title: React.FC<{ serviceGroup: ShowResponse, onDestroy: () => void }> = ({ serviceGroup, onDestroy }): ReactElement => {
  const [serviceGroupStream, setServiceGroupStream] = useState<ServiceGroupStream>({ requestStatus: RemoteResourceStatus.INIT });

  const abortController = useMemo(() => new AbortController(), []);

  useEffect(() => {
    if (serviceGroupStream.requestStatus === RemoteResourceStatus.INIT) {
      serviceGroupStreamer(serviceGroup.id, setServiceGroupStream, abortController.signal);
    }
  }, [serviceGroup.id, serviceGroupStream.requestStatus, abortController]);

  useEffect(() => {
    return () => abortController.abort();
  }, [abortController]);

  const { name, status } = serviceGroup;

  return (
    <>
      <Flex justifyContent='flex-start' alignItems='baseline'>
        <Box mr={6}>{name}</Box>
        <Flex alignItems='center'>
          <Box fontSize='md' mr={2} color='brand.purple.500'>
            Status:
          </Box>
          <Box>
            <Status status={serviceGroupStream.status ?? status} />
          </Box>
        </Flex>
      </Flex>
      <Details serviceGroup={serviceGroup} onDestroy={onDestroy} />
    </>
  );
};

export default Title;
