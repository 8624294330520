import { createClientFactory, createChannel } from 'nice-grpc-web';
import { errorDetailsClientMiddleware } from 'nice-grpc-error-details';
import { APIDefinition } from '../../tsProtoCodegen/billing';

const channel = createChannel(process.env.REACT_APP_BILLING_GRPC_URL as string);

const billingClient = createClientFactory()
  .use(errorDetailsClientMiddleware)
  .create(APIDefinition, channel);

export default billingClient;
