import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

const InstallGithubApp: React.FC = (): ReactElement => {
  return (
    <a href='https://github.com/apps/app-lattice/installations/new'>Install App Lattice Github App</a>
  );
};

export default InstallGithubApp;
