import { createChannel, createClientFactory } from 'nice-grpc-web';
import { errorDetailsClientMiddleware } from 'nice-grpc-error-details';
import { APIDefinition } from '../../tsProtoCodegen/authenticate';

const channel = createChannel(
  process.env.REACT_APP_AUTHENTICATE_GRPC_URL as string
);

const authenticateGrpcClient = createClientFactory()
  .use(errorDetailsClientMiddleware)
  .create(APIDefinition, channel);

export default authenticateGrpcClient;
