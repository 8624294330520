import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { AuthorizeError } from './Authorize';

const ErrorElem: React.FC<{ error: AuthorizeError }> = ({ error }): ReactElement => {
  return (
    <div>There was an issue logging in. <Link to='/'>Go Home</Link></div>
  );
};

export default ErrorElem;
