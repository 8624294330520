import React, { ReactElement, useEffect, useState } from 'react';
import BuildLogs from './BuildLogs';
import TriggerBuildButton from './TriggerBuildButton';
import { GridItem, Image, Button, Spinner } from '@chakra-ui/react';
import buildStatusMap from '../buildStatusMap';
import { CiBuildInfoResponse, ShowResponse, UpdateResponse, DeepPartial } from '../../../../../../tsProtoCodegen/service';
import { RemoteResource, RemoteResourceStatus } from '../../../Secrets/Secrets';
import serviceClient from '../../../../../../utils/serviceClient';
import metadataWithJwt from '../../../../../../utils/metadataWithJwt';
import { addSeconds } from 'date-fns';
import { RichServerError } from 'nice-grpc-error-details';

const streamCiBuildInfo = async (
  id: string,
  setCiBuildInfo: (ciBuildInfo: DeepPartial<CiBuildInfoResponse> & { error?: string, requestStatus: RemoteResourceStatus }) => void,
) => {
  try {
    setCiBuildInfo({ requestStatus: RemoteResourceStatus.LOADING, error: undefined });

    for await (const { logs, status } of serviceClient.ciBuildInfo({ id }, { metadata: metadataWithJwt() })) {
      setCiBuildInfo({ logs, status, requestStatus: RemoteResourceStatus.LOADED });
    }
  } catch (e) {
    if (e instanceof RichServerError) {
      setCiBuildInfo({
        requestStatus: RemoteResourceStatus.ERROR,
        error: e.details
      });
    }
  }
};

const CICD: React.FC<{ service: ShowResponse }> = ({ service }): ReactElement => {
  const [showLogs, setShowLogs] = useState<boolean>(false);
  const [updateState, setUpdateState] = useState<UpdateResponse & RemoteResource>({ status: RemoteResourceStatus.INIT });
  const [{ status, requestStatus, error, logs }, setCiBuildInfo] = useState<DeepPartial<CiBuildInfoResponse> & { error?: string, requestStatus: RemoteResourceStatus }>({ requestStatus: RemoteResourceStatus.INIT });

  useEffect(() => {
    streamCiBuildInfo(service.id, setCiBuildInfo);
  }, [service.id]);

  const triggerBuild = async () => {
    if (updateState.status === RemoteResourceStatus.INIT || updateState.status === RemoteResourceStatus.ERROR) {
      try {
        setUpdateState({ ...updateState, status: RemoteResourceStatus.LOADING });
        const res = await serviceClient.update({ ...service }, {
          metadata: metadataWithJwt(),

          deadline: addSeconds(new Date(), 180),
        });

        setUpdateState({ ...res, status: RemoteResourceStatus.LOADED, error: undefined });
      } catch (e: any) {
        setUpdateState({ status: RemoteResourceStatus.ERROR, error: e.details });
      }
    }
  };

  if (requestStatus === RemoteResourceStatus.LOADING) {
    return <>Loading CI build info <Spinner /></>;
  }

  if (requestStatus === RemoteResourceStatus.ERROR) {
    return <>Error loading CI build info: {error}</>;
  }

  return (
    <>
      <GridItem colSpan={2}>
        <h3>CI/CD Information</h3>
        <Image
          display='inline'
          width='10px'
          src={buildStatusMap[status || 'default'].image}
          mr={2}
        />
        Build Status: {buildStatusMap[status || 'default'].label}
      </GridItem>
      <TriggerBuildButton onClick={triggerBuild} status={updateState.status} />
      <Button onClick={() => setShowLogs(true)}>Build logs</Button>
      <BuildLogs isOpen={showLogs} logs={logs} onClose={() => setShowLogs(false)} />
    </>

  );
};

export default CICD;
