import { CreateSecretRequest } from '../../../../tsProtoCodegen/ServiceGroup';
import metadataWithJwt from '../../../../utils/metadataWithJwt';
import grpcClient from '../../grpcClient';
import { CreateOrUpdateSecretsRequest } from './Secrets';

const submit = async ({
  setError,
  setSubmitting,
  data,
}: {
  setError: (e: string | null) => void;
  setSubmitting: (submitting: boolean) => void;
  data: CreateOrUpdateSecretsRequest & { values: string };
}): Promise<void> => {
  try {
    setError(null);
    setSubmitting(true);

    const secrets = data.secrets.map((secret): CreateSecretRequest => {
      return { ...secret, values: JSON.parse(secret.values.toString()) };
    });

    await grpcClient.createOrUpdateSecrets(
      { ...data, secrets },
      { metadata: metadataWithJwt() }
    );
  } catch (e) {
    setError('There was an error');
  } finally {
    setSubmitting(false);
  }
};

export default submit;
