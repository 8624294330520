import { useState, useEffect } from 'react';
import billingCustomerApiClient from './billingCustomerApiClient';
import { Charge } from '../../tsProtoCodegen/billing.customer';
import metadataWithJwt from '../../utils/metadataWithJwt';

export type BillingActivity = {
  data: Charge[];
  loading: boolean;
  error: string | null;
};

const useBillingActivity = (id: string): BillingActivity => {
  const [billingActivity, setBillingActivity] = useState<BillingActivity>({
    data: [],
    loading: true,
    error: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { charges: data } = await billingCustomerApiClient.activity(
          {
            id,
          },
          { metadata: metadataWithJwt() }
        );

        setBillingActivity({
          error: null,
          loading: false,
          data: data,
        });
      } catch (error) {
        setBillingActivity({
          error: 'There was an error fetching the activity data.',
          loading: false,
          data: [],
        });
      }
    };

    fetchData();
  }, [id]);

  return billingActivity;
};

export default useBillingActivity;
