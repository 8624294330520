import React, { PropsWithChildren, ReactElement, useContext, useEffect, useState } from 'react';
import { ShowResponse } from '../../../tsProtoCodegen/service';
import { RemoteResource, RemoteResourceStatus } from '../../ServiceGroup/Show/Secrets/Secrets';
import fetchServices from '../../ServiceGroup/Show/Diagram/ServiceList/fetchServices';

export type ServiceListContextValue = {
  services: { services: ShowResponse[] } & RemoteResource,
  update: () => void,

}

export const ServiceListContext = React.createContext<ServiceListContextValue | undefined>(undefined);

const ServiceListProvider: React.FC<PropsWithChildren & { serviceGroupId: string }> = ({
  children,
  serviceGroupId,
}): ReactElement => {
  const [services, setServices] = useState<{ services: ShowResponse[] } & RemoteResource>({ status: RemoteResourceStatus.INIT, services: [] });
  const { status } = services;

  useEffect(() => {
    if (services.status === RemoteResourceStatus.INIT) {
      fetchServices(serviceGroupId, setServices, services);
    }
  }, [status, serviceGroupId, services]);

  const update = () => {
    fetchServices(serviceGroupId, setServices, services);
  };

  return (
    <ServiceListContext.Provider value={{ services, update }}>
      {children}
    </ServiceListContext.Provider>
  );
};

const useServiceList = () => {
  const context = useContext(ServiceListContext);

  if (!context) {
    throw new Error('userServiceList must be used within a UserServiceProvider');
  }

  return context;
};

export { ServiceListProvider, useServiceList };
