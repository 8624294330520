import React, { ReactElement } from 'react';
import { RemoteResourceStatus } from '../../../Secrets/Secrets';
import { Button, Spinner } from '@chakra-ui/react';

const TriggerBuildButton: React.FC<{
  status: RemoteResourceStatus,
  onClick: () => void,
}> = ({ status, onClick }): ReactElement => {
  if (status === RemoteResourceStatus.LOADED) {
    return <Button >Build initialized!</Button>;
  }
  if (status === RemoteResourceStatus.LOADING) {
    return <Button ><Spinner /> Triggering Build...</Button>;
  }
  if (status === RemoteResourceStatus.ERROR) {
    return <Button onClick={onClick}>Error updating, try again</Button>;
  }

  return <Button onClick={onClick}>Trigger Build</Button>;
};

export default TriggerBuildButton;
