import { ShowResponse } from '../../../../../tsProtoCodegen/service';
import metadataWithJwt from '../../../../../utils/metadataWithJwt';
import serviceClient from '../../../../../utils/serviceClient';
import { RemoteResource, RemoteResourceStatus } from '../../Secrets/Secrets';

export const fetchServicesInterval = (
  serviceGroupId: string,
  setServices: (
    services: { services: ShowResponse[] } & RemoteResource
  ) => void,
  services: { services: ShowResponse[] } & RemoteResource
) => {
  return setInterval(async () => {
    await fetchServices(serviceGroupId, setServices, services);
  }, 5000);
};

const fetchServices = async (
  serviceGroupId: string,
  setServices: (
    services: { services: ShowResponse[] } & RemoteResource
  ) => void,
  services: { services: ShowResponse[] } & RemoteResource
): Promise<void> => {
  try {
    if (services.status === RemoteResourceStatus.LOADING) {
      return;
    }

    const { services: servicesRes } = await serviceClient.where(
      { whereInput: { serviceGroupId } },
      { metadata: metadataWithJwt() }
    );

    setServices({
      services: servicesRes,
      status: RemoteResourceStatus.LOADED,
    });
  } catch (e: any) {
    setServices({
      services: services.services,
      error: e.details,
      status: RemoteResourceStatus.ERROR,
    });
  }
};
export default fetchServices;
