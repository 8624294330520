import React, { PropsWithChildren, ReactElement } from 'react';
import { Box } from '@chakra-ui/react';

const Title: React.FC<PropsWithChildren> = ({ children }): ReactElement => {
  return (
    <Box textAlign='left' fontSize='3xl' pb={8} width='100%'>{children}</Box>
  );
};

export default Title;
