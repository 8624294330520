import { createClientFactory, createChannel } from 'nice-grpc-web';
import { errorDetailsClientMiddleware } from 'nice-grpc-error-details';
import { APIDefinition } from '../tsProtoCodegen/service';
import { deadlineMiddleware } from 'nice-grpc-client-middleware-deadline';

const channel = createChannel(process.env.REACT_APP_SERVICE_GRPC_URL as string);

const serviceClient = createClientFactory()
  .use(errorDetailsClientMiddleware)
  .use(deadlineMiddleware)
  .create(APIDefinition, channel, {});

export default serviceClient;
