import React, { FocusEventHandler, MouseEventHandler, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Image, Text, Box, Flex, Icon, Button } from '@chakra-ui/react';
import { FaGithub } from 'react-icons/fa';
import { LuLogOut } from 'react-icons/lu';
import Jwt from './Jwt';

type UserMenuProps = {
  show: boolean,
  close: MouseEventHandler<HTMLDivElement> & FocusEventHandler<HTMLDivElement>,
  username: string,
  email: string

}
const UserMenu: React.FC<UserMenuProps> = (props): ReactElement => {
  const { show, close, username, email } = props;

  if (!show) return <></>;

  return (
    <Box
      bg='brand.purple.800'
      boxShadow='2px 2px 15px rgba(0,0,0,0.6)'
      onBlur={close}
      position='absolute'
      minW={80}
      padding={4}
      marginTop={1}
      right={6}
      borderRadius={6}
      zIndex={1}
    >
      <Flex justifyContent='flex-start'>
        <Box flex={1} p={1}>
          <Image src='/images/icon-user.svg' alt='user icon' />
        </Box>
        <Box flex={4} p={1} textAlign='left'>
          <Text fontSize='xl'><Icon mb={-1} mr={1} as={FaGithub} />{username}</Text>
          <Text fontSize='xs'>{email}</Text>
          <Jwt />
        </Box>
      </Flex>
      <Box textAlign='right'>
        <Link to='/logout'>
          <Icon mb={-0.5} mr={1} as={LuLogOut} />Logout
        </Link>
      </Box>
    </Box>
  );
};

export default UserMenu;