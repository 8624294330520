import React, { ReactElement } from 'react';
import ErrorElem from '../../../../../Components/ErrorElem/ErrorElem';

const DestroyError: React.FC<{ error?: string | undefined }> = ({ error }): ReactElement => {
  return (
    <ErrorElem>{error}</ErrorElem>

  );
};

export default DestroyError;
