import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { CreateRequest } from '../../../../../tsProtoCodegen/service';
import { RemoteResource, RemoteResourceStatus } from '../../Secrets/Secrets';
import { Select, Spinner } from '@chakra-ui/react';

const PropertyNames: React.FC<{
  index: number,
  register: UseFormRegister<CreateRequest>,
  propertyNames: { propertyNames: string[], abortController: AbortController } & RemoteResource,
}> = ({ index, register, propertyNames }) => {

  if (propertyNames.status === RemoteResourceStatus.LOADING) {
    return <Spinner />;
  }
  return (
    <Select
      id={`secrets.${index}.propertyName`}
      {...register(`secrets.${index}.propertyName`, {
        required: true
      })}
    >
      {propertyNames.propertyNames.map((name: string) => (
        <option key={`property-${name}`} value={name}>{name}</option>
      ))}
    </Select>
  );
};

export default PropertyNames;