import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import loginRoutes from './Containers/Login/routes';
import homeRoutes from './Containers/Home/routes';
import LoggedIn from './Components/Layouts/LoggedIn/LoggedIn';
import serviceGroupRoutes from './Containers/ServiceGroup/routes';
import billingRoutes from './Containers/Billing/routes';
import logoutRoutes from './Containers/Logout/routes';
import LoggedOut from './Components/Layouts/LoggedOut';
import userRoutes from './Containers/User/routes';

const router = createBrowserRouter([
  {
    element: (<LoggedOut />),
    path: '/login',
    children: [
      ...loginRoutes,
    ]
  },
  {
    element: (<LoggedIn />),
    path: '/',
    children: [
      ...homeRoutes,
      ...serviceGroupRoutes,
      ...billingRoutes,
      ...logoutRoutes,
    ]
  },
  {
    element: (<LoggedOut />),
    path: '/user',
    children: [
      ...userRoutes,
    ]
  }
]);

export default router;
