import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const baseStyle = defineStyle({});

const sizes = {
  md: defineStyle({
    fontSize: 'sm', // Change font size to sm (14px)
  }),
};

const danger = {
  color: 'white',
  bgGradient:
    'linear-gradient(56deg, rgba(143,0,96,1) 0%, rgba(255,0,0,1) 42%, rgba(255,79,0,1) 100%)',
  _hover: {
    transform: 'scale(1.01, 1.01)',
    bgGradient:
      'linear-gradient(56deg, rgba(103,1,70,1) 0%, rgba(196,0,0,1) 100%);',
  },
};

const flashy = {
  bg: 'none',
  color: 'white',
  bgGradient:
    'linear-gradient(56deg, rgba(112,3,176,1) 0%, rgba(180,37,90,1) 50%, rgba(255,132,0,1) 100%)',
  fontWeight: 'bold',
  minW: 28,
  _hover: {
    transform: 'scale(1.05, 1.05)',
    bg: 'brand.purple.300',
  },
};

const accent = {
  bg: 'none',
  color: 'white',
  bgGradient:
    'linear-gradient(45deg, rgba(255,89,0,1) 0%, rgba(255,149,0,1) 100%)',
  fontWeight: 'normal',
  _hover: {
    transform: 'scale(1.05, 1.05)',
  },
};

const link = {
  bg: 'none',
  color: 'brand.purple.100',
  fontWeight: 'normal',
  textDecoration: 'underline',
  _hover: {
    color: 'white',
  },
};

export const buttonTheme = defineStyleConfig({
  baseStyle,
  sizes,
  variants: { danger, flashy, accent, link },
  defaultProps: {
    colorScheme: 'brand.purple', // set the default color scheme to purple
  },
});
