import React, { ReactElement } from 'react';
import { Status as TheStatus } from '../../../../../tsProtoCodegen/service';
import { Spinner, Image, Box } from '@chakra-ui/react';
import copy from '../../../../../utils/copy';

const Status: React.FC<{ status: TheStatus }> = ({ status }): ReactElement => {

  const convert = () => {
    if ([TheStatus.UNRECOGNIZED, TheStatus.destroying].includes(status)) {
      return {
        image: '/images/icon-circle-yellow.svg',
        label: <>Updating... <Spinner size='sm' ml={1} /></>,
      };
    }

    if ([TheStatus.initial_docker_build_and_deploy].includes(status)) {
      return {
        image: '/images/icon-circle-yellow.svg',
        label: <>Starting initial build... <Spinner size='sm' ml={1} /></>,
      };
    }

    if ([TheStatus.failed, TheStatus.create_failed_helmfile_sync, TheStatus.create_failed_helmfile_sync].includes(status)) {
      return {
        image: '/images/icon-circle-red.svg',
        label: `There is an issue with the ${copy.entities.Service}. Try triggering a build to fix things.`,
      };
    }
    if ([TheStatus.updating, TheStatus.deploying_to_k8s, TheStatus.helmfile_sync].includes(status)) {
      return {
        image: '/images/icon-circle-yellow.svg',
        label: <>Deploying...<Spinner size='sm' ml={1} /></>
      };
    }
    if (status === TheStatus.creating_github_repo) {
      return {
        image: '/images/icon-circle-yellow.svg',
        label: <>Creating Github Repo...<Spinner size='sm' ml={1} /></>
      };
    }

    if (status === TheStatus.creating_cicd_pipelines) {
      return {
        image: '/images/icon-circle-yellow.svg',
        label: <>Configuring CI/CD...<Spinner size='sm' ml={1} /></>
      };
    }
    if (status === TheStatus.creating_database) {
      return {
        image: '/images/icon-circle-yellow.svg',
        label: <>Configuring Database...<Spinner size='sm' ml={1} /></>
      };
    }

    return {
      image: '/images/icon-circle-green.svg',
      label: 'Deployed'
    };
  };



  return (
    <Box>
      <Image
        display='inline'
        width='10px'
        src={convert().image}
        mr={2}
      />
      Status: {convert().label}
    </Box>

  );
};

export default Status;
