import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import {
  Card,
  Grid,
  GridItem,
  Image,
  Text,
  Box
} from '@chakra-ui/react';
import { ShowResponse as ServiceGroupShowResponse } from '../../../../tsProtoCodegen/ServiceGroup';
import telemetryDashboardUrl from '../telemetryDashboardUrl';
import ServiceList from './ServiceList/ServiceList';
import PasswordPeek from '../../../../Components/PasswordPeek/PasswordPeek';
import grpcClient from '../../grpcClient';
import Secrets, { RemoteResource, RemoteResourceStatus } from '../Secrets/Secrets';
import metadataWithJwt from '../../../../utils/metadataWithJwt';
import { Buffer } from 'buffer';

type DiagramProps = {
  serviceGroup: ServiceGroupShowResponse
  setShowCreateService: (show: boolean) => void
}

const getTelemetryPassword = async (serviceGroupId: string, setResource: Dispatch<SetStateAction<{ value?: string | undefined; } & RemoteResource>>) => {
  try {
    setResource({
      status: RemoteResourceStatus.LOADING,
      error: undefined
    });

    const { data } = await grpcClient.secretShow(
      { id: serviceGroupId, secretName: 'telemetry-grafana', namespace: 'default' },
      { metadata: metadataWithJwt() }
    );

    const value = Buffer.from(data['admin-password'], 'base64').toString();

    setResource({
      status: RemoteResourceStatus.LOADED,
      error: undefined,
      value,
    });
  } catch (e) {
    setResource({
      status: RemoteResourceStatus.ERROR,
      error: 'Error. Please try again'
    });
  }
};

const Diagram: React.FC<DiagramProps> = ({ serviceGroup, setShowCreateService }): ReactElement => {
  return (
    <Grid gap={4} pr={4} h='80vh' templateRows='repeat(8, 1fr)' pt='2rem' templateColumns='repeat(8, 1fr)'>
      <GridItem rowSpan={8} colSpan={1} alignItems='center'>
        <Card variant='bigText' justifyContent='center'>
          <Image src='/images/label-ingress.svg' width={9} />
        </Card>
      </GridItem>
      <GridItem rowSpan={6} colSpan={5} position='relative'>
        <Text position='absolute' width='100%' top='-2rem' fontWeight='bold'>Your Services</Text>
        <ServiceList serviceGroup={serviceGroup} onShowCreate={() => setShowCreateService(true)} />
      </GridItem>
      <GridItem rowSpan={1} colSpan={2} position='relative'>
        <Text position='absolute' width='100%' fontWeight='bold' top='-2rem'>Data Layer</Text>
        <Card variant='bigText'>
          <strong>Kafka Cluster</strong>
          <Box>
            Brokers: https://kafka:9092
          </Box>
        </Card>
      </GridItem>
      <GridItem rowSpan={1} colSpan={2} >
        <Card variant='bigText'>
          <strong>
            Cassandra Cluster
          </strong>

          <Box>
            url: https://cassandra:9042
          </Box>
        </Card>
      </GridItem>
      <GridItem rowSpan={2} colSpan={2} >
        <Card variant='bigText'>
          <strong>
            Postgres Cluster
          </strong>

          <Box>
            url: https://postgresql:5432
          </Box>
        </Card>
      </GridItem>
      <GridItem rowSpan={2} colSpan={2} >
        <Card variant='bigText'>
          <strong>
            Redis Cluster
          </strong>

          <Box>
            url: https://redis:6379
          </Box>
        </Card>
      </GridItem>
      <GridItem rowSpan={2} colSpan={2} >
        <Card variant='bigText'>
          <strong>Secret Manager</strong>
          <Secrets serviceGroup={serviceGroup} />
        </Card>
      </GridItem>
      <GridItem rowSpan={2} colSpan={2} >
        <Card variant='bigText'>
          <strong>CI/CD</strong>
        </Card>
      </GridItem>
      <GridItem rowSpan={2} colSpan={3} >
        <Card variant='bigTextClickable'>
          <a href={telemetryDashboardUrl(serviceGroup)} target='_blank' rel="noreferrer">
            <strong>
              Telemetry/Observability
            </strong>
          </a>

          <Box>
            <Grid templateColumns='repeat(2, 1fr)'>
              <GridItem>trace address:</GridItem>
              <GridItem>https://otel-collector:6379</GridItem>

              <GridItem>Username:</GridItem>
              <GridItem>admin</GridItem>

              <GridItem>Password:</GridItem>
              <GridItem>
                <PasswordPeek
                  onPeek={(setResource) => getTelemetryPassword(serviceGroup.id, setResource)}
                />
              </GridItem>
            </Grid>
          </Box>
        </Card>
      </GridItem>
    </Grid >
  );
};

export default Diagram;
