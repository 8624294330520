/* eslint-disable */
import Long from "long";
import type { CallContext, CallOptions } from "nice-grpc-common";
import _m0 from "protobufjs/minimal";
import { ClusterPriceHourly, ShowResponse as ShowResponse1, Volume } from "./ServiceGroup";
import { PriceHourlyRequest } from "./ServiceGroup.Private";
import { Timestamps } from "./appLatticeCommon";

export const protobufPackage = "Billing.Customer";

export enum Status {
  STATUS_UNSPECIFIED = "STATUS_UNSPECIFIED",
  active = "active",
  deactivated = "deactivated",
  external_customer_creation_error = "external_customer_creation_error",
  initializing = "initializing",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function statusFromJSON(object: any): Status {
  switch (object) {
    case 0:
    case "STATUS_UNSPECIFIED":
      return Status.STATUS_UNSPECIFIED;
    case 1:
    case "active":
      return Status.active;
    case 2:
    case "deactivated":
      return Status.deactivated;
    case 4:
    case "external_customer_creation_error":
      return Status.external_customer_creation_error;
    case 5:
    case "initializing":
      return Status.initializing;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Status.UNRECOGNIZED;
  }
}

export function statusToJSON(object: Status): string {
  switch (object) {
    case Status.STATUS_UNSPECIFIED:
      return "STATUS_UNSPECIFIED";
    case Status.active:
      return "active";
    case Status.deactivated:
      return "deactivated";
    case Status.external_customer_creation_error:
      return "external_customer_creation_error";
    case Status.initializing:
      return "initializing";
    case Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function statusToNumber(object: Status): number {
  switch (object) {
    case Status.STATUS_UNSPECIFIED:
      return 0;
    case Status.active:
      return 1;
    case Status.deactivated:
      return 2;
    case Status.external_customer_creation_error:
      return 4;
    case Status.initializing:
      return 5;
    case Status.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface WhereRequest {
  id?: string | undefined;
  userId?: string | undefined;
  autoDeposit?: boolean | undefined;
  status?: string | undefined;
  timestamps?: Timestamps | undefined;
}

export interface ShowResponse {
  id: string;
  autoDeposit: boolean;
  status: Status;
  /** stripe id */
  externalId: string;
}

export interface WhereResponse {
  customers: ShowResponse[];
}

export interface BalanceRequest {
  id: string;
}

export interface ServiceGroupUsage {
  clusterPriceHourly: ClusterPriceHourly | undefined;
  serviceGroup: ShowResponse1 | undefined;
}

export interface BalanceResponse {
  id: string;
  balance: number;
  serviceGroupUsage: ServiceGroupUsage[];
}

export interface DepositRequest {
  id: string;
  amount: number;
}

export interface DepositResponse {
  id: string;
  amount: number;
}

export interface ServiceGroupPriceHourlyRequest {
  id: string;
  serviceGroupPriceHourlyRequest: PriceHourlyRequest | undefined;
}

export interface ServiceGroupPriceHourlyResponse {
  priceHourly: ClusterPriceHourly | undefined;
  numberOfNodes: number;
  nodeSize: string;
  volumes: Volume[];
}

export interface ActivityRequest {
  id: string;
}

export interface Charge {
  amount: number;
  created: number;
}

export interface ActivityResponse {
  charges: Charge[];
}

function createBaseWhereRequest(): WhereRequest {
  return { id: undefined, userId: undefined, autoDeposit: undefined, status: undefined, timestamps: undefined };
}

export const WhereRequest = {
  encode(message: WhereRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(10).string(message.id);
    }
    if (message.userId !== undefined) {
      writer.uint32(18).string(message.userId);
    }
    if (message.autoDeposit !== undefined) {
      writer.uint32(24).bool(message.autoDeposit);
    }
    if (message.status !== undefined) {
      writer.uint32(34).string(message.status);
    }
    if (message.timestamps !== undefined) {
      Timestamps.encode(message.timestamps, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WhereRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWhereRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.userId = reader.string();
          break;
        case 3:
          message.autoDeposit = reader.bool();
          break;
        case 4:
          message.status = reader.string();
          break;
        case 5:
          message.timestamps = Timestamps.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WhereRequest {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      userId: isSet(object.userId) ? String(object.userId) : undefined,
      autoDeposit: isSet(object.autoDeposit) ? Boolean(object.autoDeposit) : undefined,
      status: isSet(object.status) ? String(object.status) : undefined,
      timestamps: isSet(object.timestamps) ? Timestamps.fromJSON(object.timestamps) : undefined,
    };
  },

  toJSON(message: WhereRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.userId !== undefined && (obj.userId = message.userId);
    message.autoDeposit !== undefined && (obj.autoDeposit = message.autoDeposit);
    message.status !== undefined && (obj.status = message.status);
    message.timestamps !== undefined &&
      (obj.timestamps = message.timestamps ? Timestamps.toJSON(message.timestamps) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<WhereRequest>): WhereRequest {
    const message = createBaseWhereRequest();
    message.id = object.id ?? undefined;
    message.userId = object.userId ?? undefined;
    message.autoDeposit = object.autoDeposit ?? undefined;
    message.status = object.status ?? undefined;
    message.timestamps = (object.timestamps !== undefined && object.timestamps !== null)
      ? Timestamps.fromPartial(object.timestamps)
      : undefined;
    return message;
  },
};

function createBaseShowResponse(): ShowResponse {
  return { id: "", autoDeposit: false, status: Status.STATUS_UNSPECIFIED, externalId: "" };
}

export const ShowResponse = {
  encode(message: ShowResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.autoDeposit === true) {
      writer.uint32(16).bool(message.autoDeposit);
    }
    if (message.status !== Status.STATUS_UNSPECIFIED) {
      writer.uint32(24).int32(statusToNumber(message.status));
    }
    if (message.externalId !== "") {
      writer.uint32(34).string(message.externalId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ShowResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseShowResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.autoDeposit = reader.bool();
          break;
        case 3:
          message.status = statusFromJSON(reader.int32());
          break;
        case 4:
          message.externalId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ShowResponse {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      autoDeposit: isSet(object.autoDeposit) ? Boolean(object.autoDeposit) : false,
      status: isSet(object.status) ? statusFromJSON(object.status) : Status.STATUS_UNSPECIFIED,
      externalId: isSet(object.externalId) ? String(object.externalId) : "",
    };
  },

  toJSON(message: ShowResponse): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.autoDeposit !== undefined && (obj.autoDeposit = message.autoDeposit);
    message.status !== undefined && (obj.status = statusToJSON(message.status));
    message.externalId !== undefined && (obj.externalId = message.externalId);
    return obj;
  },

  fromPartial(object: DeepPartial<ShowResponse>): ShowResponse {
    const message = createBaseShowResponse();
    message.id = object.id ?? "";
    message.autoDeposit = object.autoDeposit ?? false;
    message.status = object.status ?? Status.STATUS_UNSPECIFIED;
    message.externalId = object.externalId ?? "";
    return message;
  },
};

function createBaseWhereResponse(): WhereResponse {
  return { customers: [] };
}

export const WhereResponse = {
  encode(message: WhereResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.customers) {
      ShowResponse.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WhereResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWhereResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.customers.push(ShowResponse.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WhereResponse {
    return {
      customers: Array.isArray(object?.customers) ? object.customers.map((e: any) => ShowResponse.fromJSON(e)) : [],
    };
  },

  toJSON(message: WhereResponse): unknown {
    const obj: any = {};
    if (message.customers) {
      obj.customers = message.customers.map((e) => e ? ShowResponse.toJSON(e) : undefined);
    } else {
      obj.customers = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<WhereResponse>): WhereResponse {
    const message = createBaseWhereResponse();
    message.customers = object.customers?.map((e) => ShowResponse.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBalanceRequest(): BalanceRequest {
  return { id: "" };
}

export const BalanceRequest = {
  encode(message: BalanceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BalanceRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBalanceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BalanceRequest {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: BalanceRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial(object: DeepPartial<BalanceRequest>): BalanceRequest {
    const message = createBaseBalanceRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseServiceGroupUsage(): ServiceGroupUsage {
  return { clusterPriceHourly: undefined, serviceGroup: undefined };
}

export const ServiceGroupUsage = {
  encode(message: ServiceGroupUsage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clusterPriceHourly !== undefined) {
      ClusterPriceHourly.encode(message.clusterPriceHourly, writer.uint32(10).fork()).ldelim();
    }
    if (message.serviceGroup !== undefined) {
      ShowResponse1.encode(message.serviceGroup, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServiceGroupUsage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServiceGroupUsage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.clusterPriceHourly = ClusterPriceHourly.decode(reader, reader.uint32());
          break;
        case 2:
          message.serviceGroup = ShowResponse1.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ServiceGroupUsage {
    return {
      clusterPriceHourly: isSet(object.clusterPriceHourly)
        ? ClusterPriceHourly.fromJSON(object.clusterPriceHourly)
        : undefined,
      serviceGroup: isSet(object.serviceGroup) ? ShowResponse1.fromJSON(object.serviceGroup) : undefined,
    };
  },

  toJSON(message: ServiceGroupUsage): unknown {
    const obj: any = {};
    message.clusterPriceHourly !== undefined && (obj.clusterPriceHourly = message.clusterPriceHourly
      ? ClusterPriceHourly.toJSON(message.clusterPriceHourly)
      : undefined);
    message.serviceGroup !== undefined &&
      (obj.serviceGroup = message.serviceGroup ? ShowResponse1.toJSON(message.serviceGroup) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<ServiceGroupUsage>): ServiceGroupUsage {
    const message = createBaseServiceGroupUsage();
    message.clusterPriceHourly = (object.clusterPriceHourly !== undefined && object.clusterPriceHourly !== null)
      ? ClusterPriceHourly.fromPartial(object.clusterPriceHourly)
      : undefined;
    message.serviceGroup = (object.serviceGroup !== undefined && object.serviceGroup !== null)
      ? ShowResponse1.fromPartial(object.serviceGroup)
      : undefined;
    return message;
  },
};

function createBaseBalanceResponse(): BalanceResponse {
  return { id: "", balance: 0, serviceGroupUsage: [] };
}

export const BalanceResponse = {
  encode(message: BalanceResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.balance !== 0) {
      writer.uint32(16).int64(message.balance);
    }
    for (const v of message.serviceGroupUsage) {
      ServiceGroupUsage.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BalanceResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBalanceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.balance = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.serviceGroupUsage.push(ServiceGroupUsage.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BalanceResponse {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      balance: isSet(object.balance) ? Number(object.balance) : 0,
      serviceGroupUsage: Array.isArray(object?.serviceGroupUsage)
        ? object.serviceGroupUsage.map((e: any) => ServiceGroupUsage.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BalanceResponse): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.balance !== undefined && (obj.balance = Math.round(message.balance));
    if (message.serviceGroupUsage) {
      obj.serviceGroupUsage = message.serviceGroupUsage.map((e) => e ? ServiceGroupUsage.toJSON(e) : undefined);
    } else {
      obj.serviceGroupUsage = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<BalanceResponse>): BalanceResponse {
    const message = createBaseBalanceResponse();
    message.id = object.id ?? "";
    message.balance = object.balance ?? 0;
    message.serviceGroupUsage = object.serviceGroupUsage?.map((e) => ServiceGroupUsage.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDepositRequest(): DepositRequest {
  return { id: "", amount: 0 };
}

export const DepositRequest = {
  encode(message: DepositRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.amount !== 0) {
      writer.uint32(16).int64(message.amount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DepositRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDepositRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.amount = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DepositRequest {
    return { id: isSet(object.id) ? String(object.id) : "", amount: isSet(object.amount) ? Number(object.amount) : 0 };
  },

  toJSON(message: DepositRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.amount !== undefined && (obj.amount = Math.round(message.amount));
    return obj;
  },

  fromPartial(object: DeepPartial<DepositRequest>): DepositRequest {
    const message = createBaseDepositRequest();
    message.id = object.id ?? "";
    message.amount = object.amount ?? 0;
    return message;
  },
};

function createBaseDepositResponse(): DepositResponse {
  return { id: "", amount: 0 };
}

export const DepositResponse = {
  encode(message: DepositResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.amount !== 0) {
      writer.uint32(16).int64(message.amount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DepositResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDepositResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.amount = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DepositResponse {
    return { id: isSet(object.id) ? String(object.id) : "", amount: isSet(object.amount) ? Number(object.amount) : 0 };
  },

  toJSON(message: DepositResponse): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.amount !== undefined && (obj.amount = Math.round(message.amount));
    return obj;
  },

  fromPartial(object: DeepPartial<DepositResponse>): DepositResponse {
    const message = createBaseDepositResponse();
    message.id = object.id ?? "";
    message.amount = object.amount ?? 0;
    return message;
  },
};

function createBaseServiceGroupPriceHourlyRequest(): ServiceGroupPriceHourlyRequest {
  return { id: "", serviceGroupPriceHourlyRequest: undefined };
}

export const ServiceGroupPriceHourlyRequest = {
  encode(message: ServiceGroupPriceHourlyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.serviceGroupPriceHourlyRequest !== undefined) {
      PriceHourlyRequest.encode(message.serviceGroupPriceHourlyRequest, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServiceGroupPriceHourlyRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServiceGroupPriceHourlyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.serviceGroupPriceHourlyRequest = PriceHourlyRequest.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ServiceGroupPriceHourlyRequest {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      serviceGroupPriceHourlyRequest: isSet(object.serviceGroupPriceHourlyRequest)
        ? PriceHourlyRequest.fromJSON(object.serviceGroupPriceHourlyRequest)
        : undefined,
    };
  },

  toJSON(message: ServiceGroupPriceHourlyRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.serviceGroupPriceHourlyRequest !== undefined &&
      (obj.serviceGroupPriceHourlyRequest = message.serviceGroupPriceHourlyRequest
        ? PriceHourlyRequest.toJSON(message.serviceGroupPriceHourlyRequest)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<ServiceGroupPriceHourlyRequest>): ServiceGroupPriceHourlyRequest {
    const message = createBaseServiceGroupPriceHourlyRequest();
    message.id = object.id ?? "";
    message.serviceGroupPriceHourlyRequest =
      (object.serviceGroupPriceHourlyRequest !== undefined && object.serviceGroupPriceHourlyRequest !== null)
        ? PriceHourlyRequest.fromPartial(object.serviceGroupPriceHourlyRequest)
        : undefined;
    return message;
  },
};

function createBaseServiceGroupPriceHourlyResponse(): ServiceGroupPriceHourlyResponse {
  return { priceHourly: undefined, numberOfNodes: 0, nodeSize: "", volumes: [] };
}

export const ServiceGroupPriceHourlyResponse = {
  encode(message: ServiceGroupPriceHourlyResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.priceHourly !== undefined) {
      ClusterPriceHourly.encode(message.priceHourly, writer.uint32(10).fork()).ldelim();
    }
    if (message.numberOfNodes !== 0) {
      writer.uint32(16).int64(message.numberOfNodes);
    }
    if (message.nodeSize !== "") {
      writer.uint32(26).string(message.nodeSize);
    }
    for (const v of message.volumes) {
      Volume.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServiceGroupPriceHourlyResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServiceGroupPriceHourlyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.priceHourly = ClusterPriceHourly.decode(reader, reader.uint32());
          break;
        case 2:
          message.numberOfNodes = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.nodeSize = reader.string();
          break;
        case 4:
          message.volumes.push(Volume.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ServiceGroupPriceHourlyResponse {
    return {
      priceHourly: isSet(object.priceHourly) ? ClusterPriceHourly.fromJSON(object.priceHourly) : undefined,
      numberOfNodes: isSet(object.numberOfNodes) ? Number(object.numberOfNodes) : 0,
      nodeSize: isSet(object.nodeSize) ? String(object.nodeSize) : "",
      volumes: Array.isArray(object?.volumes) ? object.volumes.map((e: any) => Volume.fromJSON(e)) : [],
    };
  },

  toJSON(message: ServiceGroupPriceHourlyResponse): unknown {
    const obj: any = {};
    message.priceHourly !== undefined &&
      (obj.priceHourly = message.priceHourly ? ClusterPriceHourly.toJSON(message.priceHourly) : undefined);
    message.numberOfNodes !== undefined && (obj.numberOfNodes = Math.round(message.numberOfNodes));
    message.nodeSize !== undefined && (obj.nodeSize = message.nodeSize);
    if (message.volumes) {
      obj.volumes = message.volumes.map((e) => e ? Volume.toJSON(e) : undefined);
    } else {
      obj.volumes = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<ServiceGroupPriceHourlyResponse>): ServiceGroupPriceHourlyResponse {
    const message = createBaseServiceGroupPriceHourlyResponse();
    message.priceHourly = (object.priceHourly !== undefined && object.priceHourly !== null)
      ? ClusterPriceHourly.fromPartial(object.priceHourly)
      : undefined;
    message.numberOfNodes = object.numberOfNodes ?? 0;
    message.nodeSize = object.nodeSize ?? "";
    message.volumes = object.volumes?.map((e) => Volume.fromPartial(e)) || [];
    return message;
  },
};

function createBaseActivityRequest(): ActivityRequest {
  return { id: "" };
}

export const ActivityRequest = {
  encode(message: ActivityRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActivityRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActivityRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ActivityRequest {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: ActivityRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial(object: DeepPartial<ActivityRequest>): ActivityRequest {
    const message = createBaseActivityRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseCharge(): Charge {
  return { amount: 0, created: 0 };
}

export const Charge = {
  encode(message: Charge, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.amount !== 0) {
      writer.uint32(8).int32(message.amount);
    }
    if (message.created !== 0) {
      writer.uint32(16).int32(message.created);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Charge {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCharge();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.amount = reader.int32();
          break;
        case 2:
          message.created = reader.int32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Charge {
    return {
      amount: isSet(object.amount) ? Number(object.amount) : 0,
      created: isSet(object.created) ? Number(object.created) : 0,
    };
  },

  toJSON(message: Charge): unknown {
    const obj: any = {};
    message.amount !== undefined && (obj.amount = Math.round(message.amount));
    message.created !== undefined && (obj.created = Math.round(message.created));
    return obj;
  },

  fromPartial(object: DeepPartial<Charge>): Charge {
    const message = createBaseCharge();
    message.amount = object.amount ?? 0;
    message.created = object.created ?? 0;
    return message;
  },
};

function createBaseActivityResponse(): ActivityResponse {
  return { charges: [] };
}

export const ActivityResponse = {
  encode(message: ActivityResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.charges) {
      Charge.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActivityResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActivityResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.charges.push(Charge.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ActivityResponse {
    return { charges: Array.isArray(object?.charges) ? object.charges.map((e: any) => Charge.fromJSON(e)) : [] };
  },

  toJSON(message: ActivityResponse): unknown {
    const obj: any = {};
    if (message.charges) {
      obj.charges = message.charges.map((e) => e ? Charge.toJSON(e) : undefined);
    } else {
      obj.charges = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<ActivityResponse>): ActivityResponse {
    const message = createBaseActivityResponse();
    message.charges = object.charges?.map((e) => Charge.fromPartial(e)) || [];
    return message;
  },
};

export type APIDefinition = typeof APIDefinition;
export const APIDefinition = {
  name: "API",
  fullName: "Billing.Customer.API",
  methods: {
    where: {
      name: "where",
      requestType: WhereRequest,
      requestStream: false,
      responseType: WhereResponse,
      responseStream: false,
      options: {},
    },
    balance: {
      name: "balance",
      requestType: BalanceRequest,
      requestStream: false,
      responseType: BalanceResponse,
      responseStream: false,
      options: {},
    },
    deposit: {
      name: "deposit",
      requestType: DepositRequest,
      requestStream: false,
      responseType: DepositResponse,
      responseStream: false,
      options: {},
    },
    serviceGroupPriceHourly: {
      name: "serviceGroupPriceHourly",
      requestType: ServiceGroupPriceHourlyRequest,
      requestStream: false,
      responseType: ServiceGroupPriceHourlyResponse,
      responseStream: false,
      options: {},
    },
    activity: {
      name: "activity",
      requestType: ActivityRequest,
      requestStream: false,
      responseType: ActivityResponse,
      responseStream: false,
      options: {},
    },
  },
} as const;

export interface APIServiceImplementation<CallContextExt = {}> {
  where(request: WhereRequest, context: CallContext & CallContextExt): Promise<DeepPartial<WhereResponse>>;
  balance(request: BalanceRequest, context: CallContext & CallContextExt): Promise<DeepPartial<BalanceResponse>>;
  deposit(request: DepositRequest, context: CallContext & CallContextExt): Promise<DeepPartial<DepositResponse>>;
  serviceGroupPriceHourly(
    request: ServiceGroupPriceHourlyRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<ServiceGroupPriceHourlyResponse>>;
  activity(request: ActivityRequest, context: CallContext & CallContextExt): Promise<DeepPartial<ActivityResponse>>;
}

export interface APIClient<CallOptionsExt = {}> {
  where(request: DeepPartial<WhereRequest>, options?: CallOptions & CallOptionsExt): Promise<WhereResponse>;
  balance(request: DeepPartial<BalanceRequest>, options?: CallOptions & CallOptionsExt): Promise<BalanceResponse>;
  deposit(request: DeepPartial<DepositRequest>, options?: CallOptions & CallOptionsExt): Promise<DepositResponse>;
  serviceGroupPriceHourly(
    request: DeepPartial<ServiceGroupPriceHourlyRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<ServiceGroupPriceHourlyResponse>;
  activity(request: DeepPartial<ActivityRequest>, options?: CallOptions & CallOptionsExt): Promise<ActivityResponse>;
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
