import React, { ReactElement, useEffect, useState } from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import { Metadata } from 'nice-grpc-web';
import grpcClient from './grpcClient';
import ErrorElem from './ErrorElem';
import { Spinner } from '@chakra-ui/react';

export type AuthorizeError = {
  message: string,
  validationErrors: { [attribute: string]: string[] },
}

const setJwt = (headers: Metadata) => {
  const authHeader = headers.get('authorization');

  if (!authHeader) {
    throw new Error('Authorization header is not in headers');
  }

  const authHeaderSplit = authHeader.split(' ');
  if (authHeaderSplit.length < 2) {
    throw new Error('Authorization header is malformed');
  }

  localStorage.setItem('jwt', authHeaderSplit[1]);
};

const Authorize: React.FC = (): ReactElement => {
  const [searchParams] = useSearchParams();

  const [error, setError] = useState<AuthorizeError | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    const code = searchParams.get('code');
    const githubInstallationId = searchParams.get('installation_id');

    (async () => {
      try {
        // initialize user
        await grpcClient.getUsingGithubCode(
          {
            code: code as string,
            githubAppInstallationId: githubInstallationId as string
          },
          {
            onTrailer: setJwt
          }
        );

        setSuccess(true);
      } catch (e: any) {
        setError({
          message: e.details,
          validationErrors: e.validationErrors
        });
      }
    })();
  }, [searchParams]);

  if (error) {
    return (
      <ErrorElem error={error} />
    );
  }

  if (success) return <Navigate to={'/'} />;

  return (
    <><Spinner /> Logging in with Github...</>

  );
};

export default Authorize;
