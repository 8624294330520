import { RichServerError } from 'nice-grpc-error-details';
import { DeepPartial, ShowResponse } from '../../../tsProtoCodegen/ServiceGroup';
import metadataWithJwt from '../../../utils/metadataWithJwt';
import grpcClient from '../grpcClient';
import { RemoteResourceStatus } from '../Show/Secrets/Secrets';

export type ServiceGroupStream = DeepPartial<ShowResponse> &
{
  error?: string | undefined,
  requestStatus: RemoteResourceStatus,
}

const serviceGroupStream = async (
  id: string,
  setServiceGroupStream: (serviceStream: ServiceGroupStream) => void,
  signal: AbortSignal
) => {
  try {
    setServiceGroupStream({ requestStatus: RemoteResourceStatus.LOADING, error: undefined });

    for await (const serviceGroup of grpcClient.showStream({ id }, { metadata: metadataWithJwt(), signal: signal })) {
      setServiceGroupStream({ requestStatus: RemoteResourceStatus.LOADED, ...serviceGroup });
    }
  } catch (e) {
    if (e instanceof RichServerError) {
      setServiceGroupStream({
        requestStatus: RemoteResourceStatus.ERROR,
        error: e.details
      });
    }
  }
};

export default serviceGroupStream;