import { numberInputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(numberInputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    color: 'brand.purple.800',
    background: 'brand.purple.200',
    backgroundColor: 'brand.purple.200',
    borderColor: 'brand.purple.800',
    boxShadow: '2px 2px 2px 0px rgba(0,0,0,0.3) inset',
    ':focus': {
      background: 'brand.purple.50',
    },
  },
  stepper: {
    borderColor: 'brand.purple.300',
  },
});

export const numberInputTheme = defineMultiStyleConfig({ baseStyle });
