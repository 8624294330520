import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { DestroyResponse, ShowResponse } from '../../../../../tsProtoCodegen/service';
import { ShowResponse as UserShowResponse } from '../../../../../tsProtoCodegen/user';
import { ShowResponse as ServiceGroup } from '../../../../../tsProtoCodegen/ServiceGroup';
import {
  Grid,
  GridItem,
  Spinner,
  Icon,
  Divider,
  IconButton,
  Box,
} from '@chakra-ui/react';
import { RemoteResource, RemoteResourceStatus } from '../../Secrets/Secrets';
import { colors } from '../../../../../theme/theme';
import { useSelector } from 'react-redux';
import { State } from '../../../../../reduxStore/reduxStore';
import userNotNull from '../../../../User/selectors/userNotNull';
import { FaGithub } from 'react-icons/fa';
import getDomain from '../../../../../utils/getDomain';
import Status from './Status';
import { BiTrash, BiWorld } from 'react-icons/bi';
import { DeepPartial } from '../../../../../tsProtoCodegen/appLatticeCommon';
import destroy from './destroy';
import DestroyError from './DestroyError';
import CICD from './CICD/CICD';
import streamService from './streamService';

export type ServiceStream = DeepPartial<ShowResponse> &
{
  error?: string | undefined,
  requestStatus: RemoteResourceStatus,
}

const Item: React.FC<{ service: ShowResponse, serviceGroup: ServiceGroup, onDestroy: () => void }> = ({ service, serviceGroup, onDestroy }): ReactElement => {
  const [serviceStream, setServiceStream] = useState<ServiceStream>({ requestStatus: RemoteResourceStatus.INIT });
  const { name, status } = service;
  const [destroying, setDestroying] = useState<DeepPartial<DestroyResponse> & RemoteResource>({ status: RemoteResourceStatus.INIT });

  const abortController = useMemo(() => new AbortController(), []);

  useEffect(() => {
    if (serviceStream.requestStatus === RemoteResourceStatus.INIT) {
      streamService(service.id, setServiceStream, abortController.signal);
    }
  }, [service.id, serviceStream.requestStatus, abortController]);

  useEffect(() => {
    return () => abortController.abort();
  }, [abortController]);

  const { githubUsername: username } = useSelector<State, UserShowResponse>(userNotNull);

  if (destroying.status === RemoteResourceStatus.LOADING) {
    return (
      <Box
        bg={colors.brand.purple[800]}
        p={3}
        gap={2}
        textAlign='left'
        overflow='auto'
        position='relative'
      >
        Destroying {name}. <Spinner />
      </Box>
    );
  }

  return (
    <Grid
      bg={colors.brand.purple[800]}
      p={3}
      gap={2}
      templateColumns='repeat(2, 1fr)'
      textAlign='left'
      overflow='auto'
      position='relative'
    >
      <DestroyError error={destroying.error} />
      <IconButton
        icon={<BiTrash />}
        aria-label={''}
        position='absolute'
        top='1rem'
        right='1rem'
        onClick={() => {
          destroy(
            service.id,
            () => setDestroying({ ...destroying, status: RemoteResourceStatus.LOADING, error: undefined }),
            onDestroy,
            (error: string) => setDestroying({ ...destroying, status: RemoteResourceStatus.ERROR, error }),
          );
        }}
      />
      <GridItem colSpan={2}>
        <h2>{name}</h2>
      </GridItem>
      <Status status={serviceStream.status ?? status} />
      <GridItem colSpan={2} pb={4} >
        <a target='_blank' href={`https://github.com/${username}/${name}`} rel="noreferrer">
          <Icon as={FaGithub} mr={2} /> https://github.com/{username}/{name}
        </a>
      </GridItem>
      <GridItem colSpan={2} pb={4} >
        <Icon as={BiWorld} mb={-1} /> {name}.{getDomain(serviceGroup)}
      </GridItem>
      <GridItem colSpan={2}>
        <Divider />
      </GridItem>
      <CICD service={service} />
    </Grid>
  );
};

export default Item;
