import grpcClient from '../grpcClient';
import {
  StatusPretty,
  WhereResponse,
} from '../../../tsProtoCodegen/ServiceGroup';
import metadataWithJwt from '../../../utils/metadataWithJwt';
import { RemoteResource, RemoteResourceStatus } from '../Show/Secrets/Secrets';

const serviceGroupsFetch = async (
  setServiceGroups: (serviceGroups: WhereResponse & RemoteResource) => void
): Promise<void> => {
  try {
    const metadata = metadataWithJwt();

    const { serviceGroups } = await grpcClient.where({}, { metadata });

    setServiceGroups({
      serviceGroups: serviceGroups.filter(
        (sg) => sg.status !== StatusPretty.STATUS_PRETTY_DELETED
      ),
      status: RemoteResourceStatus.LOADED,
    });
  } catch (e: any) {
    console.error('serviceGroupsFetch error', {
      message: e.message,
      stack: e.stack,
    });

    setServiceGroups({
      serviceGroups: [],
      error: e.details,
      status: RemoteResourceStatus.ERROR,
    });
  }
};
export default serviceGroupsFetch;
