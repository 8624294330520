import React, { ReactElement } from 'react';
import { ShowResponse } from '../../../tsProtoCodegen/ServiceGroup';
import { Box, Button, SimpleGrid } from '@chakra-ui/react';
import getDomain from '../../../utils/getDomain';

const Details: React.FC<{ serviceGroup: ShowResponse, onDestroy: () => void }> = ({ serviceGroup, onDestroy }): ReactElement => {
  const { numberOfNodes, nodeSize } = serviceGroup;
  return (
    <SimpleGrid columns={4} maxW='7xl' fontSize={{ lg: 'sm', md: 'xs', xl: 'lg' }} color='brand.purple.300' textAlign='left'>
      <Box>Domain: {getDomain(serviceGroup)}</Box>
      <Box>Number of Nodes: {numberOfNodes}</Box>
      <Box>Node Size: {nodeSize}</Box>
      <Box textAlign='center'>
        <Button variant='danger' onClick={onDestroy}>Destroy</Button>
      </Box>
    </SimpleGrid>
  );
};

export default Details;
