import React, { ReactElement, useEffect, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';
import { SetupIntent, ShowSetupIntentResponse } from '../../../tsProtoCodegen/billing';
import billingClient from '../billingClient';
import { Button, Spinner } from '@chakra-ui/react';
import ErrorElem from '../../../Components/ErrorElem/ErrorElem';

const CreateCreditCard: React.FC<{
  stripeCustomerId: string
}> = ({ stripeCustomerId }): ReactElement => {
  const stripe = useStripe();
  const elements = useElements();

  const [setupIntentResponse, setSetupIntentResponse] = useState<ShowSetupIntentResponse | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const createSetupIntent = async () => {
      if (!setupIntentResponse) {
        setSetupIntentResponse(await billingClient.createSetupIntent({ stripeCustomerId }));
      }
    };

    createSetupIntent();
  }, [setupIntentResponse, stripeCustomerId]);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setSubmitting(true);


    if (!stripe || !elements) return;
    const { setupIntent } = setupIntentResponse as ShowSetupIntentResponse;

    const { clientSecret } = setupIntent as SetupIntent;

    const { error } = await stripe.confirmCardSetup(
      clientSecret,
      { payment_method: { card: elements.getElement(CardElement) as StripeCardElement } }
    );

    if (error) {
      setError(true);
      setSubmitting(false);
    }

    if (!error) {
      window.location.reload();
    }
  };

  const getSubmitButton = () => {
    if (submitting) {
      return <Spinner>Submitting...</Spinner>;
    }

    return <Button onClick={onSubmit}>Submit</Button>;
  };

  return (
    <form id="payment-form" onSubmit={onSubmit}>
      <ErrorElem show={error}>There was an error creating your card</ErrorElem>
      <p>No payment method on file</p>
      <CardElement options={{ style: { base: { color: '#FFF' } } }} />
      {getSubmitButton()}
    </form>
  );
};

export default CreateCreditCard;

