import {
  theme as chakraTheme,
  defineStyleConfig,
  extendTheme,
} from '@chakra-ui/react';
import { buttonTheme } from './Button';
import { cardTheme } from './Card';
import { menuTheme } from './Menu';
import { textareaTheme } from './Textarea';
import { numberInputTheme } from './NumberInput';
export const colors = {
  brand: {
    purple: {
      50: '#dbd0e5',
      100: '#c3b1d3',
      200: '#ac95c1',
      300: '#8568a0',
      400: '#5c4372',
      500: '#3b264f',
      600: '#261535',
      700: '#180b23',
      800: '#21132d',
      900: '#060020',
    },
    orange: {
      50: '#ffe9db',
      100: '#ffc8ae',
      200: '#ffa97e',
      300: '#ff8e4c',
      400: '#ff751a',
      500: '#e64d00',
      600: '#b42f00',
      700: '#811800',
      800: '#4f0800',
      900: '#200004',
    },
  },
};
const theme = extendTheme({
  ...chakraTheme,
  styles: {
    global: {
      body: {
        bg: 'brand.purple.900',
        color: 'brand.purple.100',
      },
    },
  },
  components: {
    Container: defineStyleConfig({
      baseStyle: {
        padding: 0,
        maxWidth: 'none',
      },
    }),
    Button: buttonTheme,
    Card: cardTheme,
    Modal: defineStyleConfig({
      baseStyle: {
        dialog: {
          bg: 'brand.purple.800',
        },
      },
    }),
    Input: {
      variants: {
        normal: {
          field: {
            background: 'brand.purple.200',
            color: 'brand.purple.800',
            // border: `1px solid ${colors.brand.purple[400]}`,
            boxShadow: '2px 2px 2px 0px rgba(0,0,0,0.3) inset',
            ':focus': {
              background: 'brand.purple.50',
            },
          },
        },
      },
      defaultProps: {
        variant: 'normal',
        errorBorderColor: 'brand.orange.400',
      },
    },
    Textarea: textareaTheme,
    Select: {
      variants: {
        normal: {
          field: {
            background: 'brand.purple.200',
            color: 'brand.purple.800',
            // border: `1px solid ${colors.brand.purple[400]}`,
            boxShadow: '2px 2px 2px 0px rgba(0,0,0,0.3) inset',
            ':focus': {
              background: 'brand.purple.50',
            },
          },
        },
      },
      defaultProps: {
        variant: 'normal',
      },
    },
    NumberInput: numberInputTheme,
    Menu: menuTheme,
    Box: {
      variants: {
        center: {
          textAlign: 'center',
        },
      },
    },
  },
  colors,
});

export default theme;
