import React, { ReactElement, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Outlet } from 'react-router-dom';
import SessionCheck from '../../../Containers/SessionCheck/SessionCheck';
import Nav from '../../Nav/Nav';
import { Box, Flex, Show, } from '@chakra-ui/react';
import UserNav from '../../UserNav/UserNav';
import { TitleContext } from '../../../Contexts/TitleContext';
import BalanceProvider from '../../../Contexts/BalanceContext';
import MobileNav from '../../MobileNav/MobileNav';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);

const LoggedIn: React.FC = (): ReactElement => {
  const [title, setTitle] = useState<string | ReactElement>('');

  return (
    <Box h='calc(100vh)'>
      <SessionCheck>
        <Elements stripe={stripePromise}>
          <BalanceProvider>
            <Show below='sm'>
              <MobileNav />
            </Show>
            <Flex height='100%'>
              <Show above='sm'>
                <Box bg='red' minW={260} maxW={350} flex={4}>
                  <Nav />
                </Box>
              </Show>
              <Box pt={[20, 6]} pl={6} pr={6} flex={8} maxH='100%' overflow='auto'>
                <Show above='sm'>
                  <Box h={10}>
                    <Box position='fixed' zIndex={1} right={0} top={0}>
                      <UserNav />
                    </Box>
                  </Box>
                </Show>
                <TitleContext.Provider value={{ title, setTitle }}>
                </TitleContext.Provider>
                <Outlet />
              </Box>
            </Flex>
          </BalanceProvider>
        </Elements>
      </SessionCheck>
    </Box>
  );
};

export default LoggedIn;