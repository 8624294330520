import {
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Checkbox,
  Textarea,
  Spinner,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import React, { ReactElement, useEffect, useState } from 'react';
import { CreateRequest, CreateResponse } from '../../../../tsProtoCodegen/service';
import { useForm } from 'react-hook-form';
import PortMappings from './PortMappings';
import onSubmit from './onSubmit';
import ErrorElem from '../../../../Components/ErrorElem/ErrorElem';
import Secrets from './Secrets/Secrets';
import { SecretListResponse } from '../../../../tsProtoCodegen/ServiceGroup';
import { RemoteResource, RemoteResourceStatus } from '../Secrets/Secrets';
import grpcClient from '../../grpcClient';
import metadataWithJwt from '../../../../utils/metadataWithJwt';
import copy from '../../../../utils/copy';
import { Navigate } from 'react-router-dom';
import { useServiceList } from '../../../Service/List/ServiceListContext';

export type ServiceCreateFormParams = {
  serviceGroupId: string,
  show: boolean,
  onClose: () => void
}

export type SubmitStatus = {
  submitting: boolean,
  error?: Error | undefined | unknown,
  success: boolean,
}

const ServiceCreateForm: React.FC<ServiceCreateFormParams> = (params): ReactElement => {
  const [secrets, setSecrets] = useState<SecretListResponse & RemoteResource>({ secrets: [], status: RemoteResourceStatus.INIT });
  const [createService, setCreateService] = useState<CreateResponse & RemoteResource>({ status: RemoteResourceStatus.INIT });

  const { show, onClose, serviceGroupId } = params;

  const { status, error } = secrets;

  const { update } = useServiceList();
  useEffect(() => {
    if (show && status === RemoteResourceStatus.INIT) {
      const loadSecrets = async () => {
        setSecrets({ secrets: [], status: RemoteResourceStatus.LOADING, error: undefined });

        const res = await grpcClient.secretList(
          { id: serviceGroupId, labelSelectors: ['name=postgres-password'] },
          { metadata: metadataWithJwt() },
        );

        setSecrets({ ...res, status: RemoteResourceStatus.LOADED, error });
      };

      loadSecrets();
    }
  }, [secrets, show, serviceGroupId, status, error]);


  const defaultValues: CreateRequest = {
    name: '',
    envVars: '',
    serviceGroupId,
    portMappings: [],
    hasDatabase: false,
    replicas: 1,
    templateRepo: '',
    withHttpProxy: false,
    grpc: false,
    secrets: []
  };


  const { register, control, handleSubmit, reset } = useForm<CreateRequest>({ defaultValues });

  const getError = () => {
    if (createService.status === RemoteResourceStatus.ERROR) {
      return <ErrorElem>{createService.error}</ErrorElem>;
    }
  };
  const spinner = () => {
    if (createService.status === RemoteResourceStatus.LOADING) {
      return <Spinner />;
    }
  };

  const close = () => {
    reset();
    setCreateService({ status: RemoteResourceStatus.INIT, error: undefined });
    setSecrets({ secrets: [], status: RemoteResourceStatus.INIT, error: undefined });
    onClose();
  };

  const getBody = () => {
    if (createService.status === RemoteResourceStatus.LOADED) {
      return <>{copy.entities.Service} Created!</>;
    }

    return (
      <form onSubmit={handleSubmit(async (data) => {
        await onSubmit(
          data,
          createService,
          setCreateService,
          update,
        );
      })}
      >
        {getError()}
        {spinner()}
        <Grid
          templateColumns='repeat(3, 1fr)'
          display={createService.status === RemoteResourceStatus.LOADING ? 'none' : 'grid'}
          gap={4}
        >
          <GridItem colSpan={2}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                isInvalid={true}
                type="text"
                placeholder="Name"
                {...register('name', { required: true, maxLength: 80 })}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl>
              <FormLabel>Replicas</FormLabel>
              <NumberInput min={1} >
                <NumberInputField
                  {...register('replicas', { required: true, maxLength: 80, valueAsNumber: true })}
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>

            </FormControl>
          </GridItem>

          <GridItem colSpan={3}>
            <FormControl>
              <FormLabel>Template Repo</FormLabel>
              <Select placeholder='Select template repo'{...register('templateRepo', { required: true })}>
                <option value="https://api.github.com/repos/amanos1022/ts-express-grpc-prisma-template/generate">https://github.com/amanos1022/ts-express-grpc-prisma-template</option>
              </Select>
            </FormControl>
          </GridItem>



          <GridItem colSpan={3}>
            <FormLabel>Environment Variables (key=value)</FormLabel>
            <Textarea {...register('envVars')} />
          </GridItem>

          <GridItem colSpan={3}>
            <h3>Secrets for EnvVars</h3>
            <Secrets serviceGroupId={serviceGroupId} control={control} register={register} secrets={secrets} />
          </GridItem>

          <GridItem colSpan={3} pb={6} pt={6}>
            <h3>Port Mappings</h3>
            <PortMappings control={control} register={register} />
          </GridItem>

          <GridItem>
            <FormControl>
              <Checkbox {...register('withHttpProxy')}>Public</Checkbox>
            </FormControl>
          </GridItem>

          <FormControl>
            <Checkbox
              {...register('grpc')}
            >is gRPC?</Checkbox>
          </FormControl>

          <GridItem>
            <FormControl>
              <Checkbox {...register('hasDatabase')}>Has Database</Checkbox>
            </FormControl>
          </GridItem>

          <GridItem colSpan={3} pt={4} pb={4} textAlign='center'>
            <Button
              variant='flashy'
              disabled={createService.status === RemoteResourceStatus.LOADING}
              type="submit"
            >Create {copy.entities.Service}</Button>
          </GridItem>

          <input type="hidden" placeholder="..." {...register('serviceGroupId', { required: true })} />
        </Grid>
      </form>
    );
  };

  return (
    <Modal size='lg' isOpen={show} onClose={close}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create {copy.entities.Service}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{getBody()}</ModalBody>
      </ModalContent>
    </Modal >
  );
};

export default ServiceCreateForm;
