export type DollarValue = { raw: number; pretty: string; cents: number };

const centsToDollars = (cents: number): DollarValue => {
  const dollars = +(cents / 100).toFixed(2);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return {
    raw: dollars,
    pretty: formatter.format(dollars),
    cents,
  };
};

export default centsToDollars;
