import React, { PropsWithChildren, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Image, Box, Flex } from '@chakra-ui/react';

export type NavButtonProps = {
  to: string,
  icon: string,
}

const NavButton: React.FC<NavButtonProps & PropsWithChildren> = ({ to, icon, children }): ReactElement => (
  <li style={{ listStyleType: 'none' }}>
    <Flex justifyContent='flex-start' pb={4} alignItems='center'>
      <Box w={8}>
        <Image src={icon} alt={`icon for ${to} link`} w={4} />
      </Box>
      <Box pl={4} fontSize='xl'>
        <Link to={to}>{children}</Link>
      </Box>
    </Flex>
  </li>
);

export default NavButton;
