import React, { ReactElement } from 'react';
import centsToDollars from '../../../utils/centsToDollars';
import { format } from 'date-fns';
import { Charge } from '../../../tsProtoCodegen/billing.customer';
import { Td, Tr } from '@chakra-ui/react';

const Item: React.FC<Charge> = ({ created, amount }): ReactElement => (
  <Tr >
    <Td>{format(created * 1000, 'MM/dd/yyyy')}</Td>
    <Td>Deposit</Td>
    <Td isNumeric>{centsToDollars(amount).pretty}</Td>
  </Tr>
);

export default Item;
