/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "app_lattice.certmanager.dns";

export interface DigitalOcean {
  access_token: string;
}

export interface CloudFlare {
  api_token: string;
}

export interface Route53 {
  region: string;
  hosted_zone_id: string;
  role: string;
}

function createBaseDigitalOcean(): DigitalOcean {
  return { access_token: "" };
}

export const DigitalOcean = {
  encode(message: DigitalOcean, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.access_token !== "") {
      writer.uint32(10).string(message.access_token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DigitalOcean {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDigitalOcean();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.access_token = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DigitalOcean {
    return { access_token: isSet(object.access_token) ? String(object.access_token) : "" };
  },

  toJSON(message: DigitalOcean): unknown {
    const obj: any = {};
    message.access_token !== undefined && (obj.access_token = message.access_token);
    return obj;
  },

  fromPartial(object: DeepPartial<DigitalOcean>): DigitalOcean {
    const message = createBaseDigitalOcean();
    message.access_token = object.access_token ?? "";
    return message;
  },
};

function createBaseCloudFlare(): CloudFlare {
  return { api_token: "" };
}

export const CloudFlare = {
  encode(message: CloudFlare, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.api_token !== "") {
      writer.uint32(10).string(message.api_token);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CloudFlare {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCloudFlare();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.api_token = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CloudFlare {
    return { api_token: isSet(object.api_token) ? String(object.api_token) : "" };
  },

  toJSON(message: CloudFlare): unknown {
    const obj: any = {};
    message.api_token !== undefined && (obj.api_token = message.api_token);
    return obj;
  },

  fromPartial(object: DeepPartial<CloudFlare>): CloudFlare {
    const message = createBaseCloudFlare();
    message.api_token = object.api_token ?? "";
    return message;
  },
};

function createBaseRoute53(): Route53 {
  return { region: "", hosted_zone_id: "", role: "" };
}

export const Route53 = {
  encode(message: Route53, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.region !== "") {
      writer.uint32(10).string(message.region);
    }
    if (message.hosted_zone_id !== "") {
      writer.uint32(18).string(message.hosted_zone_id);
    }
    if (message.role !== "") {
      writer.uint32(26).string(message.role);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Route53 {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRoute53();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.region = reader.string();
          break;
        case 2:
          message.hosted_zone_id = reader.string();
          break;
        case 3:
          message.role = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Route53 {
    return {
      region: isSet(object.region) ? String(object.region) : "",
      hosted_zone_id: isSet(object.hosted_zone_id) ? String(object.hosted_zone_id) : "",
      role: isSet(object.role) ? String(object.role) : "",
    };
  },

  toJSON(message: Route53): unknown {
    const obj: any = {};
    message.region !== undefined && (obj.region = message.region);
    message.hosted_zone_id !== undefined && (obj.hosted_zone_id = message.hosted_zone_id);
    message.role !== undefined && (obj.role = message.role);
    return obj;
  },

  fromPartial(object: DeepPartial<Route53>): Route53 {
    const message = createBaseRoute53();
    message.region = object.region ?? "";
    message.hosted_zone_id = object.hosted_zone_id ?? "";
    message.role = object.role ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
