import React, { ReactElement, useEffect, useState } from 'react';
import { ShowResponse, WhereResponse } from '../../../tsProtoCodegen/ServiceGroup';
import Item from './Item';
import serviceGroupsFetch from './serviceGroupsFetch';
import { Link } from 'react-router-dom';
import { Box, Button, Flex, Spinner } from '@chakra-ui/react';
import Title from './Title';
import CreateModal from '../CreateModal/CreateModal';
import { RemoteResource, RemoteResourceStatus } from '../Show/Secrets/Secrets';
import copy from '../../../utils/copy';

const serviceGroupsFetchPoll = (
  { status }: WhereResponse & RemoteResource,
  setServiceGroups: (serviceGroups: WhereResponse & RemoteResource) => void
) => {
  if (status !== RemoteResourceStatus.LOADING) {
    return setInterval(async () => {
      await serviceGroupsFetch(setServiceGroups);
    }, 15000);
  }
};

const List: React.FC = (): ReactElement => {
  const [serviceGroups, setServiceGroups] = useState<WhereResponse & RemoteResource>({ serviceGroups: [], status: RemoteResourceStatus.INIT });
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { status, serviceGroups: serviceGroupsRes } = serviceGroups;
  useEffect(() => {
    if (status === RemoteResourceStatus.INIT) {
      serviceGroupsFetch(setServiceGroups);
    }
    // const intervalId = serviceGroupsFetchPoll(serviceGroups, setServiceGroups);

    // return () => { clearInterval(intervalId); };
  }, [serviceGroups, status]);


  if (status === RemoteResourceStatus.INIT || status === RemoteResourceStatus.LOADING) {
    return (
      <Box textAlign='center' pt='5rem'>
        <Spinner />
      </Box>
    );
  }

  if (status === RemoteResourceStatus.ERROR) {
    return <div>Error loading {copy.entities.ServiceGroup}s. <button onClick={() => { setServiceGroups({ serviceGroups: [], status: RemoteResourceStatus.INIT }); }}>Reload</button></div>;
  }

  if (serviceGroupsRes.length === 0) {
    return (
      <>
        <Title openModal={() => setModalOpen(true)} />
        <Flex textAlign='center' alignItems='center' justifyContent='center' h='60%'>
          <Link to='/service-groups/create'><Button size='lg' variant='flashy'>Create your first {copy.entities.ServiceGroup}</Button></Link>
        </Flex>
      </>
    );

  }

  return (
    <>
      <Title showCreateButton={true} openModal={() => setModalOpen(true)} />
      <Box>
        <Flex justifyContent='flex-start' flexWrap='wrap'>
          {serviceGroupsRes.map((serviceGroup: ShowResponse) => (
            <Box key={serviceGroup.id} pr={{ sm: 0, md: 6 }}>
              <Item {...serviceGroup} />
            </Box>
          ))}
        </Flex>
        <CreateModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
      </Box>
    </>
  );
};

export default List;
