import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  Spinner
} from '@chakra-ui/react';
import React, { ReactElement, useState } from 'react';
import { Control, useFieldArray, UseFormRegister } from 'react-hook-form';
import { CreateRequest } from '../../../../../tsProtoCodegen/service';
import { SecretListResponse, ShowSecretResponse } from '../../../../../tsProtoCodegen/ServiceGroup';
import { RemoteResource, RemoteResourceStatus } from '../../Secrets/Secrets';
import ErrorElem from '../../../../../Components/ErrorElem/ErrorElem';
import copy from '../../../../../utils/copy';
import grpcClient from '../../../grpcClient';
import metadataWithJwt from '../../../../../utils/metadataWithJwt';
import { colors } from '../../../../../theme/theme';
import PropertyNames from './PropertyNames';
import SecretItem from './SecretItem';

export type SecretsProps = {
  control: Control<CreateRequest>,
  register: UseFormRegister<CreateRequest>,
  secrets: SecretListResponse & RemoteResource,
  serviceGroupId: string,
}

const Secrets: React.FC<SecretsProps> = ({ register, control, secrets, serviceGroupId }): ReactElement => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'secrets',
  });

  if (secrets.status === RemoteResourceStatus.ERROR) {
    return <ErrorElem>There was an error getting the {copy.entities.ServiceGroup} secrets.</ErrorElem>;
  }

  if (secrets.status === RemoteResourceStatus.LOADING) {
    return <><Spinner /> Loading secrets...</>;
  }

  return (<>
    {fields.map((field, index) => (
      <SecretItem
        remove={remove}
        field={field}
        index={index}
        key={field.id}
        serviceGroupId={serviceGroupId}
        register={register}
        secrets={secrets.secrets}
      />
    ))}
    <Button borderRadius='full' onClick={() => append({ envName: '', secretName: '', propertyName: '', env: false, build: false })}>
      +
    </Button>
    <button type='submit' />
  </>
  );
};

export default Secrets;
