import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Flex, Image, Box } from '@chakra-ui/react';

const Logo: React.FC = (): ReactElement => {
  return (
    <Link to='/'>

      <Flex textAlign='left' alignContent='center'>
        <Box flex={1} mr={3} position='relative'>
          <Image
            position='absolute'
            top={0}
            bottom={0}
            margin='auto'
            src='/images/logo-small.svg'
          />
        </Box >
        <Box fontSize='3xl' color='brand.purple.100' flex={4}>
          App Lattice
        </Box>
      </Flex >
    </Link >

  );
};

export default Logo;
