import React, { ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import store, { State } from '../../reduxStore/reduxStore';
import { useDispatch, useSelector } from 'react-redux';
import InstallGithubApp from '../Login/InstallGithubApp';
import { UserState, userThunk } from '../User/userSlice';
import ErrorElem from '../../Components/ErrorElem/ErrorElem';
import { Button, Spinner } from '@chakra-ui/react';

const SessionCheck: React.FC<React.PropsWithChildren> = ({ children }): ReactElement => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const pathname = useLocation().pathname;
  const userState = useSelector<State, UserState>((state: State) => state.user);

  const { user, customer, checked, loading, error } = userState;

  // user is loaded
  if (user && customer) {
    if (user.githubAppInstallationId === '' || user.githubAppInstallationId === null) {
      return <InstallGithubApp />;
    }
    if (pathname === '/login') {
      return <Navigate to='/' />;
    }

    return <>{children}</>;
  }

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorElem>{error}<Button onClick={() => { localStorage.setItem('jwt', ''); window.location.href = '/'; }}>Reload</Button></ErrorElem>;
  }

  if (!checked) {
    dispatch(userThunk(localStorage.getItem('jwt') || ''));
  }

  if (pathname !== '/login' && checked) {
    return <Navigate to='/login' />;
  }

  return <>{children}</>;
};

export default SessionCheck;
