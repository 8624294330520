import React, { ReactElement } from 'react';
import { Container, Image } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

const LoggedOut: React.FC = (): ReactElement => {
  return (
    <Container
      className='login'
      color="white"
      bgGradient='linear-gradient(0deg, rgba(6,0,32,1) 67%, rgba(57,19,87,1) 100%)'
      textAlign='center'
      minH="calc(100vh)"
      w='100%'
      pos='relative'
      overflow='hidden'
    >
      <Image
        src='/images/diamonds.svg'
        pos='absolute'
        top='30px'
        left='-120px'
        zIndex={0}
      />
      <Image
        src='/images/diamonds2.svg'
        pos='absolute'
        top='70px'
        right='-150px'
        zIndex={0}
      />
      <Outlet />
    </Container >
  );
};

export default LoggedOut;
