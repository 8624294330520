import { RemoteResourceStatus } from '../../Secrets/Secrets';
import serviceClient from '../../../../../utils/serviceClient';
import metadataWithJwt from '../../../../../utils/metadataWithJwt';
import { ServiceStream } from './Item';
import { RichServerError } from 'nice-grpc-error-details';

const streamService = async (
  id: string,
  setServiceStream: (serviceStream: ServiceStream) => void,
  signal: AbortSignal
) => {
  try {
    setServiceStream({ requestStatus: RemoteResourceStatus.LOADING, error: undefined });

    for await (const service of serviceClient.showStream({ id }, { metadata: metadataWithJwt(), signal })) {
      setServiceStream({ requestStatus: RemoteResourceStatus.LOADED, ...service });
    }
  } catch (e) {
    if (e instanceof RichServerError) {
      setServiceStream({
        requestStatus: RemoteResourceStatus.ERROR,
        error: e.details
      });
    }
  }
};

export default streamService;