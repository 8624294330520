import React, { ReactElement, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Spinner,
  Box,
  IconButton
} from '@chakra-ui/react';
import { SecretListResponse, ShowResponse, StatusPretty } from '../../../../tsProtoCodegen/ServiceGroup';
import { useFieldArray, useForm } from 'react-hook-form';
import grpcClient from '../../grpcClient';
import metadataWithJwt from '../../../../utils/metadataWithJwt';
import ErrorElem from '../../../../Components/ErrorElem/ErrorElem';
import copy from '../../../../utils/copy';
import submit from './submit';
import SecretFields from './SecretFields';
import { AddIcon } from '@chakra-ui/icons';

type SecretsProps = {
  serviceGroup: ShowResponse,
}

export enum RemoteResourceStatus {
  INIT,
  LOADING,
  ERROR,
  LOADED
}
export type RemoteResource = {
  status: RemoteResourceStatus,
  error?: string,
}

const getSecrets = async (id: string, setSecrets: (secrets: SecretListResponse & RemoteResource) => void) => {
  try {
    const { secrets } = await grpcClient.secretList({ id }, { metadata: metadataWithJwt() });
    setSecrets({ status: RemoteResourceStatus.LOADED, secrets });
  } catch (e: any) {
    setSecrets({ status: RemoteResourceStatus.ERROR, error: e.details, secrets: [] });

  }
};
export type CreateOrUpdateSecretsRequest = {
  secrets: { name: string, type: string, values: string, namespace: string }[]
  id: string
}
const Secrets: React.FC<SecretsProps> = ({ serviceGroup }): ReactElement => {
  const [secrets, setSecrets] = useState<SecretListResponse & RemoteResource>({ status: RemoteResourceStatus.INIT, secrets: [] });
  const [error, setError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const { id } = serviceGroup;
  useEffect(() => {
    if (secrets.status === RemoteResourceStatus.INIT && show) {
      getSecrets(id, setSecrets);
    }
  }, [secrets, id, show]);

  const defaultValues: CreateOrUpdateSecretsRequest = {
    id,
    secrets: []
  };

  const { register, control, handleSubmit } = useForm<CreateOrUpdateSecretsRequest & { values: string }>({ defaultValues });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'secrets',
  });

  useEffect(() => {
    secrets.secrets.forEach((secret) => {
      append({ ...secret, values: JSON.stringify(secret.values) });
    });
  }, [append, secrets]);

  if (serviceGroup.status !== StatusPretty.STATUS_PRETTY_ACTIVE) {
    return <>Waiting for {copy.entities.ServiceGroup} to be ready</>;
  }

  if (!show) {
    return <><Button onClick={() => { setShow(true); }}>Edit Secrets</Button></>;
  }

  if (secrets.status === RemoteResourceStatus.ERROR) {
    return (<div>Error loading secrets <button onClick={() => {
      setSecrets({ secrets: [], status: RemoteResourceStatus.INIT });
    }}
                                       >Reload</button></div>);
  }

  if (secrets.status === RemoteResourceStatus.LOADING || secrets.status === RemoteResourceStatus.INIT) {
    return <div>Loading Secrets data...</div>;
  }

  if (submitting) {
    return <div><Spinner /> Updating secrets...</div>;
  }

  return (

    <Modal isOpen={show}
onClose={() => {
      setShow(false);
    }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Manage Secrets</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit(async (data) => submit({
            setError,
            setSubmitting,
            data,
          }))}
          >
            <ErrorElem>{error}</ErrorElem>
            {
              fields.map((field, index) => {
                return (
                  <Box key={field.id}>
                    <SecretFields
                      index={index}
                      register={register}
                      remove={remove}
                    />
                  </Box>

                );
              })
            }
            <IconButton icon={<AddIcon />} onClick={() => append({ name: '', values: '', namespace: 'default', type: 'Opaque' })} aria-label={'Add Secret'}/>
            <Box textAlign='center'>
              <Button type='submit'>Save</Button>
            </Box>
          </form >
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Secrets;
