import React, { ReactElement, useState, useEffect, useMemo } from 'react';
import { ShowResponse } from '../../../tsProtoCodegen/ServiceGroup';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Text,
  Box,
  Flex
} from '@chakra-ui/react';
import Status from './Status';
import copy from '../../../utils/copy';
import serviceGroupStreamer, { ServiceGroupStream } from './serviceGroupStream';
import { RemoteResourceStatus } from '../Show/Secrets/Secrets';

const Item: React.FC<ShowResponse> = (serviceGroup): ReactElement => {
  const [serviceGroupStream, setServiceGroupStream] = useState<ServiceGroupStream>({ requestStatus: RemoteResourceStatus.INIT });

  const abortController = useMemo(() => new AbortController(), []);

  useEffect(() => {
    if (serviceGroupStream.requestStatus === RemoteResourceStatus.INIT) {
      serviceGroupStreamer(serviceGroup.id, setServiceGroupStream, abortController.signal);
    }
  }, [serviceGroup.id, serviceGroupStream.requestStatus, abortController]);

  useEffect(() => {
    return () => {
      console.log('abort');
      abortController.abort();
    };
  }, [abortController]);

  const { id, name, nodeSize, numberOfNodes, domain, status } = serviceGroup;

  return (
    <Card maxW='sm' mb={6}>
      <CardBody>
        <Text textAlign='left' color='brand.purple.100' fontSize='3xl' fontWeight='semibold'>{name}</Text>
        <Status status={serviceGroupStream.status ?? status} />

        <Box pt={14} pb={14}>
          <Flex>
            <Box width='md' flex={1}>Domain:</Box><Box flex={2}>{domain}</Box>
          </Flex>
          <Flex>
            <Box width='md' flex={1}>Node Size:</Box><Box flex={2}>{nodeSize}</Box>
          </Flex>
          <Flex>
            <Box width='md' flex={1}># of Nodes:</Box><Box flex={2}>{numberOfNodes}</Box>
          </Flex>
        </Box>

        <Box textAlign='center'>
          <Link to={`/service-groups/${id}`}>
            <Button>Manage {copy.entities.ServiceGroup}</Button>
          </Link >
        </Box>
      </CardBody>
    </Card >
  );
};

export default Item;
