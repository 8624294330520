import { State } from '../../../../reduxStore/reduxStore';
import { ShowResponse } from '../../../../tsProtoCodegen/billing.customer';

const notNull = ({ user: { customer } }: State): ShowResponse => {
  if (!customer)
    throw new Error('Customer is null when is expected to be set!');

  return customer;
};

export default notNull;
