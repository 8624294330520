import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import store, { State } from '../../../reduxStore/reduxStore';
import Form from './Form';
import { SubmitHandler } from 'react-hook-form';
import getUser from '../getUser';
import { ShowResponse } from '../../../tsProtoCodegen/user';
import { ShowResponse as CustomerShowResponse } from '../../../tsProtoCodegen/billing.customer';
import * as ServiceGroup from '../../../tsProtoCodegen/ServiceGroup';
import { Link, Navigate } from 'react-router-dom';
import grpcClient from '../grpcClient';
import { ClientError, Status, Metadata } from 'nice-grpc-web';
import userNotNull from '../../User/selectors/userNotNull';
import { PaymentMethodState } from '../../Billing/PaymentMethod/redux/types';
import fetchPaymentMethodsThunk from '../../Billing/PaymentMethod/redux/fetchPaymentMethodsThunk';
import notNull from '../../Billing/Customer/selectors/notNull';
import { RichClientError } from 'nice-grpc-error-details';
import Title from '../../../Components/Layouts/LoggedIn/Title';
import copy from '../../../utils/copy';
import { Button, Card, Spinner } from '@chakra-ui/react';
import ErrorElem from './ErrorElem';
import metadataWithJwt from '../../../utils/metadataWithJwt';

const Create: React.FC = (): ReactElement => {
  const user = useSelector<State, ShowResponse>(userNotNull);
  const { externalId: stripeCustomerId } = useSelector<State, CustomerShowResponse>(notNull);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [serviceGroup, setServiceGroup] = useState<ServiceGroup.CreateResponse | null>(null);
  const [paymentMethodsFetched, setPaymentMethodsFetched] = useState<boolean>(false);

  const dispatch = useDispatch<typeof store.dispatch>();
  const paymentMethod = useSelector<State, PaymentMethodState>(state => state.paymentMethod);

  const { id: userId } = getUser(user);

  useEffect(() => {
    if (user && !paymentMethodsFetched) {
      setPaymentMethodsFetched(true);
      dispatch(fetchPaymentMethodsThunk(stripeCustomerId as string));
    }
  }, [dispatch, paymentMethodsFetched, stripeCustomerId, user]);

  const onSubmit: SubmitHandler<ServiceGroup.CreateRequest> = async (req: ServiceGroup.CreateRequest) => {
    setSubmitting(true);
    setError(null);

    try {
      setServiceGroup(
        await grpcClient.create(
          req,
          {
            metadata: metadataWithJwt(),
          }
        )
      );
    } catch (e) {
      setSubmitting(false);

      if (!(e instanceof ClientError) && !(e instanceof RichClientError) || e.code !== Status.INVALID_ARGUMENT) {
        setError(`there was an error creating the ${copy.entities.ServiceGroup}`);
      }

      const clientError = e as ClientError;

      setError(clientError.message);
      setErrors([clientError.message]);
    };
  };

  if (paymentMethod.error) return <>pm error</>;

  if (serviceGroup) {
    return <Navigate to='/service-groups' />;
  }

  if (paymentMethod.loading || paymentMethod.paymentMethods === null) {
    return <Spinner />;
  }

  if (paymentMethod.paymentMethods.length === 0) {
    return <Button><Link to='/billing'>Please add a payment method</Link></Button>;
  }


  return (
    <>
      <Title>Create {copy.entities.ServiceGroup}</Title>
      <Card p={6}>
        <ErrorElem error={error} />
        <Form userId={userId} onSubmit={onSubmit} setErrors={setErrors} submitting={submitting} />
      </Card>
    </>
  );
};

export default Create;
