
import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../reduxStore/reduxStore';
import userNotNull from '../../Containers/User/selectors/userNotNull';
import { Image, Box } from '@chakra-ui/react';
import { ShowResponse } from '../../tsProtoCodegen/user';
import UserMenu from './UserMenu';

const UserNav: React.FC = (): ReactElement => {
  const { githubUsername: username, email } = useSelector<State, ShowResponse>(userNotNull);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const toggleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <Box pt={6} pr={6}>
      <Image
        src='/images/icon-user.svg'
        alt='user icon'
        cursor='pointer'
        onClick={toggleShowMenu}
      />
      <UserMenu
        username={username}
        email={email}
        close={() => setShowMenu(false)}
        show={showMenu}
      />
    </Box >
  );
};

export default UserNav;
