import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { BalanceResponse, ShowResponse } from '../tsProtoCodegen/billing.customer';
import billingCustomerApiClient from '../Containers/Billing/billingCustomerApiClient';
import metadataWithJwt from '../utils/metadataWithJwt';
import { useSelector } from 'react-redux';
import { State } from '../reduxStore/reduxStore';

export type Balance = {
  data: BalanceResponse | null;
  loading: boolean;
  error: string | null;
};

export const initialState = {
  data: null,
  loading: true,
  error: null,
};

export type BalanceContextType = {
  balance: Balance;
};
export const BalanceContext = React.createContext<BalanceContextType>({
  balance: initialState,
});

const BalanceProvider: React.FC<PropsWithChildren> = ({
  children,
}): ReactElement => {
  const [balance, setBalance] = useState<Balance>(initialState);
  const customer = useSelector<State, ShowResponse | undefined>(({ user: { customer } }: State) => customer);

  useEffect(() => {
    if (customer) {
      const { id } = customer;

      const fetchData = async () => {
        try {
          const data = await billingCustomerApiClient.balance(
            {
              id,
            },
            { metadata: metadataWithJwt() }
          );

          setBalance({
            data,
            loading: false,
            error: null,
          });
        } catch (error) {
          setBalance({
            ...initialState,
            loading: false,
            error: 'There was an error fetching the balance data.',
          });
        }
      };

      fetchData();

    }
  }, [customer, setBalance]);

  if (!customer) {
    return <></>;
  }


  return (
    <BalanceContext.Provider value={{ balance }}>
      {children}
    </BalanceContext.Provider>
  );
};

export default BalanceProvider;
