import React, {
Dispatch,
ReactElement,
SetStateAction,
useEffect,
useState
} from 'react';
import copy from '../../../utils/copy';
import billingCustomerApiClient from '../../Billing/billingCustomerApiClient';
import { useSelector } from 'react-redux';
import { State } from '../../../reduxStore/reduxStore';
import { ShowResponse } from '../../../tsProtoCodegen/billing.customer';
import centsToDollars, { DollarValue } from '../../../utils/centsToDollars';
import metadataWithJwt from '../../../utils/metadataWithJwt';
import { Spinner } from '@chakra-ui/react';
export type PriceParams = {
  priceHourly: DollarValue | null,
  setPriceHourly: Dispatch<SetStateAction<DollarValue | null>>,
  priceInput: {
    nodeSize: string,
    numberOfNodes: number,
  }
}
const loadPrice = async (
  id: string,
  numberOfNodes: number,
  nodeSize: string,
  setPriceHourly: Dispatch<SetStateAction<DollarValue | null>>,
  setError: (e: string | null) => void,
  setLoading: (e: boolean) => void
) => {
  try {
    if (nodeSize === '' || numberOfNodes <= 0) return null;

    setLoading(true);
    setError(null);
    setPriceHourly(null);
    const { priceHourly } = await billingCustomerApiClient.serviceGroupPriceHourly(
      {
        id,
        serviceGroupPriceHourlyRequest: { numberOfNodes, nodeSize }
      },
      { metadata: metadataWithJwt() },
    );

    if (!priceHourly) {
      throw new Error('priceHourly not defined');
    }

    setPriceHourly(centsToDollars(priceHourly.total));
  } catch (e) {
    setError('Error getting price data');
  } finally {
    setLoading(false);
  }
};

const Price: React.FC<PriceParams> = (props): ReactElement => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { priceHourly, setPriceHourly, priceInput: { nodeSize, numberOfNodes } } = props;
  const customer = useSelector<State, ShowResponse | undefined>(({ user }) => user.customer);

  if (!customer) throw Error('Customer not defined');

  const { id } = customer;

  useEffect(() => {
    loadPrice(id, numberOfNodes, nodeSize, setPriceHourly, setError, setLoading);
  }, [id, nodeSize, numberOfNodes, setPriceHourly]);

  if (error) {
    return <>{error}</>;
  }

  if (loading) return <>Getting price <Spinner /></>;

  if (!priceHourly) return <></>;

  return (
    <>{copy.entities.ServiceGroup} will be {priceHourly.pretty}/per hour</>
  );
};

export default Price;
