import { DestroyResponse } from '../../../tsProtoCodegen/ServiceGroup';
import metadataWithJwt from '../../../utils/metadataWithJwt';
import grpcClient from '../grpcClient';

const serviceGroupDestroy = async (
  id: string,
  success: (res: DestroyResponse) => void,
  failed: (msg: string) => void
): Promise<void> => {
  try {
    const res = await grpcClient.destroy(
      { id },
      { metadata: metadataWithJwt() }
    );

    success(res);
  } catch (e) {
    failed((e as Error).message);
  }
};

export default serviceGroupDestroy;
