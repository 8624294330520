import React, { ReactElement } from 'react';
import { ShowDefaultPaymentMethodResponse } from '../../../tsProtoCodegen/billing';
import { Box, Icon } from '@chakra-ui/react';

const List: React.FC<{
  paymentMethods: ShowDefaultPaymentMethodResponse[]
}> = ({ paymentMethods }): ReactElement => {
  return (
    <>
      {paymentMethods.map(({ brand, last4 }) => {

        return (
          <Box key={last4}>
            <Box><strong>Brand:</strong> {brand}</Box>
            <Box><strong>Last 4 Digits:</strong> {last4}</Box>
          </Box>
        );
      })}
    </>
  );
};

export default List;
