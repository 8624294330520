import { ShowResponse as User } from '../../tsProtoCodegen/user';
import { ShowResponse as Customer } from '../../tsProtoCodegen/billing.customer';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import billingCustomerApiClient from '../Billing/billingCustomerApiClient';
import metadataWithJwt from '../../utils/metadataWithJwt';
import userGrpcClient from '../../utils/userGrpcClient';
import authenticateGrpcClient from '../SessionCheck/authenticateGrpcClient';
import { JwtPayload } from '../../tsProtoCodegen/authenticate';

export const authenticateAndDecodeJwt = async (
  jwt: string
): Promise<JwtPayload> => {
  if (!jwt) throw new Error('No jwt in local storage.');

  const { jwtPayload } = await authenticateGrpcClient.authenticate(
    {},
    { metadata: metadataWithJwt() }
  );

  return jwtPayload as JwtPayload;
};

export type UserState = {
  checked: boolean;
  loading: boolean;
  error: string | null;
  user?: User | undefined;
  customer?: Customer | undefined;
};
export const userThunk = createAsyncThunk<
  { customer?: Customer | undefined; user?: User | undefined },
  string,
  { rejectValue: string }
>('user/fetchUserAndCustomer', async (jwt) => {
  try {
    // get jwt
    if (!jwt || jwt === '') {
      return {};
    }

    // get user
    const { userId } = await authenticateAndDecodeJwt(jwt as string);
    const user = await userGrpcClient.show(
      { id: userId, refreshGithubToken: true },
      { metadata: metadataWithJwt() }
    );
    // get customer
    const customer = await billingCustomerApiClient.where(
      { userId: user.id },
      { metadata: metadataWithJwt() }
    );

    if (!customer.customers[0]) {
      throw new Error('Could not load customer');
    }

    return {
      customer: customer.customers[0],
      user,
    };
  } catch (e) {
    console.error('error fetching customer', { e, stack: (e as Error).stack });
    throw e;
  }
});

const onPending = (state: UserState) => {
  state.loading = true;
  state.checked = true;
};

const onFullfilled = (
  state: UserState,
  action: PayloadAction<{
    customer?: Customer | undefined;
    user?: User | undefined;
  }>
) => {
  state.loading = false;
  state.customer = action.payload.customer;
  state.user = action.payload.user;
  state.error = null;
};

const onError = (state: UserState, error: any) => {
  state.loading = false;

  const {
    error: { message },
  } = error;

  state.error = message as string;
};

const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    checked: false,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userThunk.pending, onPending)
      .addCase(userThunk.fulfilled, onFullfilled)
      .addCase(userThunk.rejected, onError);
  },
});

export default customerSlice;
