import React, { ReactElement, useState } from 'react';
import { Text, Button, Box, useToast } from '@chakra-ui/react';
import { CopyToClipboard } from 'react-copy-to-clipboard';


const Jwt: React.FC = (): ReactElement => {
  const [show, setShow] = useState(false);
  const toast = useToast();


  if (show) {
    return (
      <CopyToClipboard text={localStorage.getItem('jwt') as string}>
        <Text
          size='sm'
          whiteSpace='nowrap'
          onClick={() => toast({ title: 'Token copied to clipboard!', isClosable: true })}
          overflow='hidden'
          width='150px'
          textOverflow='ellipsis'
          cursor='pointer'
          position='relative'
        >
          <span>{localStorage.getItem('jwt')}</span>
        </Text>
      </CopyToClipboard >
    );
  }


  return (
    <Button onClick={() => setShow(true)} size='xs'>Show API Token</Button>
  );
};

export default Jwt;
