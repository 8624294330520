/* eslint-disable */
import Long from "long";
import type { CallContext, CallOptions } from "nice-grpc-common";
import _m0 from "protobufjs/minimal";
import { CloudFlare, DigitalOcean, Route53 } from "./certmanager/dns/providers";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "ServiceGroup";

/** A `ServiceGroup` is the same thing as an App Lattice "App" */

export enum StatusPretty {
  STATUS_PRETTY_UNSPECIFIED = "STATUS_PRETTY_UNSPECIFIED",
  STATUS_PRETTY_ACTIVE = "STATUS_PRETTY_ACTIVE",
  STATUS_PRETTY_INITIALIZING = "STATUS_PRETTY_INITIALIZING",
  STATUS_PRETTY_DELETING = "STATUS_PRETTY_DELETING",
  STATUS_PRETTY_DELETED = "STATUS_PRETTY_DELETED",
  STATUS_PRETTY_ERROR = "STATUS_PRETTY_ERROR",
  STATUS_PRETTY_INSTALLING_VENDOR_SERVICES = "STATUS_PRETTY_INSTALLING_VENDOR_SERVICES",
  STATUS_PRETTY_DNS_SETUP = "STATUS_PRETTY_DNS_SETUP",
  STATUS_PRETTY_INSTALLING_PROPRIETARY_SERVICES = "STATUS_PRETTY_INSTALLING_PROPRIETARY_SERVICES",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function statusPrettyFromJSON(object: any): StatusPretty {
  switch (object) {
    case 0:
    case "STATUS_PRETTY_UNSPECIFIED":
      return StatusPretty.STATUS_PRETTY_UNSPECIFIED;
    case 1:
    case "STATUS_PRETTY_ACTIVE":
      return StatusPretty.STATUS_PRETTY_ACTIVE;
    case 2:
    case "STATUS_PRETTY_INITIALIZING":
      return StatusPretty.STATUS_PRETTY_INITIALIZING;
    case 3:
    case "STATUS_PRETTY_DELETING":
      return StatusPretty.STATUS_PRETTY_DELETING;
    case 4:
    case "STATUS_PRETTY_DELETED":
      return StatusPretty.STATUS_PRETTY_DELETED;
    case 5:
    case "STATUS_PRETTY_ERROR":
      return StatusPretty.STATUS_PRETTY_ERROR;
    case 6:
    case "STATUS_PRETTY_INSTALLING_VENDOR_SERVICES":
      return StatusPretty.STATUS_PRETTY_INSTALLING_VENDOR_SERVICES;
    case 7:
    case "STATUS_PRETTY_DNS_SETUP":
      return StatusPretty.STATUS_PRETTY_DNS_SETUP;
    case 8:
    case "STATUS_PRETTY_INSTALLING_PROPRIETARY_SERVICES":
      return StatusPretty.STATUS_PRETTY_INSTALLING_PROPRIETARY_SERVICES;
    case -1:
    case "UNRECOGNIZED":
    default:
      return StatusPretty.UNRECOGNIZED;
  }
}

export function statusPrettyToJSON(object: StatusPretty): string {
  switch (object) {
    case StatusPretty.STATUS_PRETTY_UNSPECIFIED:
      return "STATUS_PRETTY_UNSPECIFIED";
    case StatusPretty.STATUS_PRETTY_ACTIVE:
      return "STATUS_PRETTY_ACTIVE";
    case StatusPretty.STATUS_PRETTY_INITIALIZING:
      return "STATUS_PRETTY_INITIALIZING";
    case StatusPretty.STATUS_PRETTY_DELETING:
      return "STATUS_PRETTY_DELETING";
    case StatusPretty.STATUS_PRETTY_DELETED:
      return "STATUS_PRETTY_DELETED";
    case StatusPretty.STATUS_PRETTY_ERROR:
      return "STATUS_PRETTY_ERROR";
    case StatusPretty.STATUS_PRETTY_INSTALLING_VENDOR_SERVICES:
      return "STATUS_PRETTY_INSTALLING_VENDOR_SERVICES";
    case StatusPretty.STATUS_PRETTY_DNS_SETUP:
      return "STATUS_PRETTY_DNS_SETUP";
    case StatusPretty.STATUS_PRETTY_INSTALLING_PROPRIETARY_SERVICES:
      return "STATUS_PRETTY_INSTALLING_PROPRIETARY_SERVICES";
    case StatusPretty.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function statusPrettyToNumber(object: StatusPretty): number {
  switch (object) {
    case StatusPretty.STATUS_PRETTY_UNSPECIFIED:
      return 0;
    case StatusPretty.STATUS_PRETTY_ACTIVE:
      return 1;
    case StatusPretty.STATUS_PRETTY_INITIALIZING:
      return 2;
    case StatusPretty.STATUS_PRETTY_DELETING:
      return 3;
    case StatusPretty.STATUS_PRETTY_DELETED:
      return 4;
    case StatusPretty.STATUS_PRETTY_ERROR:
      return 5;
    case StatusPretty.STATUS_PRETTY_INSTALLING_VENDOR_SERVICES:
      return 6;
    case StatusPretty.STATUS_PRETTY_DNS_SETUP:
      return 7;
    case StatusPretty.STATUS_PRETTY_INSTALLING_PROPRIETARY_SERVICES:
      return 8;
    case StatusPretty.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface ShowRequest {
  id: string;
}

export interface ShowResponse {
  id: string;
  numberOfNodes: number;
  nodeSize: string;
  createdAt: number;
  updatedAt: number;
  name: string;
  status: StatusPretty;
  clusterId: string;
  userId: string;
  certbotAdminEmail: string;
  domain: string;
  subdomain: string;
}

export interface CreateRequest {
  name: string;
  numberOfNodes: number;
  nodeSize: string;
  userId: string;
  certbotAdminEmail: string;
  domain: string;
  digitalOceanDns?: DigitalOcean | undefined;
  cloudFlareDns?: CloudFlare | undefined;
  route53Dns?: Route53 | undefined;
  subdomain: string;
}

export interface CreateResponse {
  id: string;
  numberOfNodes: number;
  nodeSize: string;
  createdAt: number;
  name: string;
  status: StatusPretty;
}

export interface UpdateRequest {
  id: string;
  numberOfNodes: number;
  nodeSize: string;
  updatedAt: number;
  secrets: string;
}

export interface UpdateResponse {
  id: string;
  numberOfNodes: number;
  nodeSize: string;
  createdAt: number;
  updatedAt: number;
}

export interface DestroyRequest {
  id: string;
}

export interface DestroyResponse {
  id: string;
  numberOfNodes: number;
  nodeSize: string;
  createdAt: number;
  updatedAt: number;
}

export interface WhereRequest {
  id: string;
  name: string;
  nodeSize: string;
  numberOfNodes: number;
  clusterName: string;
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
  destroyedAt: Date | undefined;
  status: StatusPretty;
  clusterId: string;
  userId: string;
}

export interface WhereResponse {
  serviceGroups: ShowResponse[];
}

export interface ClusterPriceHourly {
  total: number;
  nodes: number;
  volumes: number;
  loadBalancer: number;
}

export interface Volume {
  size: number;
}

export interface CreateSecretRequest {
  name: string;
  type: string;
  values: { [key: string]: string };
  namespace: string;
}

export interface CreateSecretRequest_ValuesEntry {
  key: string;
  value: string;
}

export interface CreateOrUpdateSecretsRequest {
  id: string;
  secrets: CreateSecretRequest[];
}

export interface CreateOrUpdateSecretsResponse {
}

export interface SecretMetaData {
  labels: { [key: string]: string };
}

export interface SecretMetaData_LabelsEntry {
  key: string;
  value: string;
}

export interface ShowSecretResponse {
  name: string;
  type: string;
  values: { [key: string]: string };
  namespace: string;
  metadata: SecretMetaData | undefined;
}

export interface ShowSecretResponse_ValuesEntry {
  key: string;
  value: string;
}

export interface SecretListRequest {
  id: string;
  labelSelectors: string[];
}

export interface SecretListResponse {
  secrets: ShowSecretResponse[];
}

export interface SecretShowRequest {
  id: string;
  secretName: string;
  namespace: string;
}

export interface SecretShowResponse {
  name: string;
  data: { [key: string]: string };
}

export interface SecretShowResponse_DataEntry {
  key: string;
  value: string;
}

function createBaseShowRequest(): ShowRequest {
  return { id: "" };
}

export const ShowRequest = {
  encode(message: ShowRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ShowRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseShowRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ShowRequest {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: ShowRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial(object: DeepPartial<ShowRequest>): ShowRequest {
    const message = createBaseShowRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseShowResponse(): ShowResponse {
  return {
    id: "",
    numberOfNodes: 0,
    nodeSize: "",
    createdAt: 0,
    updatedAt: 0,
    name: "",
    status: StatusPretty.STATUS_PRETTY_UNSPECIFIED,
    clusterId: "",
    userId: "",
    certbotAdminEmail: "",
    domain: "",
    subdomain: "",
  };
}

export const ShowResponse = {
  encode(message: ShowResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.numberOfNodes !== 0) {
      writer.uint32(16).int64(message.numberOfNodes);
    }
    if (message.nodeSize !== "") {
      writer.uint32(26).string(message.nodeSize);
    }
    if (message.createdAt !== 0) {
      writer.uint32(32).int64(message.createdAt);
    }
    if (message.updatedAt !== 0) {
      writer.uint32(40).int64(message.updatedAt);
    }
    if (message.name !== "") {
      writer.uint32(50).string(message.name);
    }
    if (message.status !== StatusPretty.STATUS_PRETTY_UNSPECIFIED) {
      writer.uint32(56).int32(statusPrettyToNumber(message.status));
    }
    if (message.clusterId !== "") {
      writer.uint32(66).string(message.clusterId);
    }
    if (message.userId !== "") {
      writer.uint32(74).string(message.userId);
    }
    if (message.certbotAdminEmail !== "") {
      writer.uint32(82).string(message.certbotAdminEmail);
    }
    if (message.domain !== "") {
      writer.uint32(90).string(message.domain);
    }
    if (message.subdomain !== "") {
      writer.uint32(98).string(message.subdomain);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ShowResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseShowResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.numberOfNodes = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.nodeSize = reader.string();
          break;
        case 4:
          message.createdAt = longToNumber(reader.int64() as Long);
          break;
        case 5:
          message.updatedAt = longToNumber(reader.int64() as Long);
          break;
        case 6:
          message.name = reader.string();
          break;
        case 7:
          message.status = statusPrettyFromJSON(reader.int32());
          break;
        case 8:
          message.clusterId = reader.string();
          break;
        case 9:
          message.userId = reader.string();
          break;
        case 10:
          message.certbotAdminEmail = reader.string();
          break;
        case 11:
          message.domain = reader.string();
          break;
        case 12:
          message.subdomain = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ShowResponse {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      numberOfNodes: isSet(object.numberOfNodes) ? Number(object.numberOfNodes) : 0,
      nodeSize: isSet(object.nodeSize) ? String(object.nodeSize) : "",
      createdAt: isSet(object.createdAt) ? Number(object.createdAt) : 0,
      updatedAt: isSet(object.updatedAt) ? Number(object.updatedAt) : 0,
      name: isSet(object.name) ? String(object.name) : "",
      status: isSet(object.status) ? statusPrettyFromJSON(object.status) : StatusPretty.STATUS_PRETTY_UNSPECIFIED,
      clusterId: isSet(object.clusterId) ? String(object.clusterId) : "",
      userId: isSet(object.userId) ? String(object.userId) : "",
      certbotAdminEmail: isSet(object.certbotAdminEmail) ? String(object.certbotAdminEmail) : "",
      domain: isSet(object.domain) ? String(object.domain) : "",
      subdomain: isSet(object.subdomain) ? String(object.subdomain) : "",
    };
  },

  toJSON(message: ShowResponse): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.numberOfNodes !== undefined && (obj.numberOfNodes = Math.round(message.numberOfNodes));
    message.nodeSize !== undefined && (obj.nodeSize = message.nodeSize);
    message.createdAt !== undefined && (obj.createdAt = Math.round(message.createdAt));
    message.updatedAt !== undefined && (obj.updatedAt = Math.round(message.updatedAt));
    message.name !== undefined && (obj.name = message.name);
    message.status !== undefined && (obj.status = statusPrettyToJSON(message.status));
    message.clusterId !== undefined && (obj.clusterId = message.clusterId);
    message.userId !== undefined && (obj.userId = message.userId);
    message.certbotAdminEmail !== undefined && (obj.certbotAdminEmail = message.certbotAdminEmail);
    message.domain !== undefined && (obj.domain = message.domain);
    message.subdomain !== undefined && (obj.subdomain = message.subdomain);
    return obj;
  },

  fromPartial(object: DeepPartial<ShowResponse>): ShowResponse {
    const message = createBaseShowResponse();
    message.id = object.id ?? "";
    message.numberOfNodes = object.numberOfNodes ?? 0;
    message.nodeSize = object.nodeSize ?? "";
    message.createdAt = object.createdAt ?? 0;
    message.updatedAt = object.updatedAt ?? 0;
    message.name = object.name ?? "";
    message.status = object.status ?? StatusPretty.STATUS_PRETTY_UNSPECIFIED;
    message.clusterId = object.clusterId ?? "";
    message.userId = object.userId ?? "";
    message.certbotAdminEmail = object.certbotAdminEmail ?? "";
    message.domain = object.domain ?? "";
    message.subdomain = object.subdomain ?? "";
    return message;
  },
};

function createBaseCreateRequest(): CreateRequest {
  return {
    name: "",
    numberOfNodes: 0,
    nodeSize: "",
    userId: "",
    certbotAdminEmail: "",
    domain: "",
    digitalOceanDns: undefined,
    cloudFlareDns: undefined,
    route53Dns: undefined,
    subdomain: "",
  };
}

export const CreateRequest = {
  encode(message: CreateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.numberOfNodes !== 0) {
      writer.uint32(16).int64(message.numberOfNodes);
    }
    if (message.nodeSize !== "") {
      writer.uint32(26).string(message.nodeSize);
    }
    if (message.userId !== "") {
      writer.uint32(34).string(message.userId);
    }
    if (message.certbotAdminEmail !== "") {
      writer.uint32(42).string(message.certbotAdminEmail);
    }
    if (message.domain !== "") {
      writer.uint32(50).string(message.domain);
    }
    if (message.digitalOceanDns !== undefined) {
      DigitalOcean.encode(message.digitalOceanDns, writer.uint32(58).fork()).ldelim();
    }
    if (message.cloudFlareDns !== undefined) {
      CloudFlare.encode(message.cloudFlareDns, writer.uint32(66).fork()).ldelim();
    }
    if (message.route53Dns !== undefined) {
      Route53.encode(message.route53Dns, writer.uint32(74).fork()).ldelim();
    }
    if (message.subdomain !== "") {
      writer.uint32(82).string(message.subdomain);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.numberOfNodes = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.nodeSize = reader.string();
          break;
        case 4:
          message.userId = reader.string();
          break;
        case 5:
          message.certbotAdminEmail = reader.string();
          break;
        case 6:
          message.domain = reader.string();
          break;
        case 7:
          message.digitalOceanDns = DigitalOcean.decode(reader, reader.uint32());
          break;
        case 8:
          message.cloudFlareDns = CloudFlare.decode(reader, reader.uint32());
          break;
        case 9:
          message.route53Dns = Route53.decode(reader, reader.uint32());
          break;
        case 10:
          message.subdomain = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateRequest {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      numberOfNodes: isSet(object.numberOfNodes) ? Number(object.numberOfNodes) : 0,
      nodeSize: isSet(object.nodeSize) ? String(object.nodeSize) : "",
      userId: isSet(object.userId) ? String(object.userId) : "",
      certbotAdminEmail: isSet(object.certbotAdminEmail) ? String(object.certbotAdminEmail) : "",
      domain: isSet(object.domain) ? String(object.domain) : "",
      digitalOceanDns: isSet(object.digitalOceanDns) ? DigitalOcean.fromJSON(object.digitalOceanDns) : undefined,
      cloudFlareDns: isSet(object.cloudFlareDns) ? CloudFlare.fromJSON(object.cloudFlareDns) : undefined,
      route53Dns: isSet(object.route53Dns) ? Route53.fromJSON(object.route53Dns) : undefined,
      subdomain: isSet(object.subdomain) ? String(object.subdomain) : "",
    };
  },

  toJSON(message: CreateRequest): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.numberOfNodes !== undefined && (obj.numberOfNodes = Math.round(message.numberOfNodes));
    message.nodeSize !== undefined && (obj.nodeSize = message.nodeSize);
    message.userId !== undefined && (obj.userId = message.userId);
    message.certbotAdminEmail !== undefined && (obj.certbotAdminEmail = message.certbotAdminEmail);
    message.domain !== undefined && (obj.domain = message.domain);
    message.digitalOceanDns !== undefined &&
      (obj.digitalOceanDns = message.digitalOceanDns ? DigitalOcean.toJSON(message.digitalOceanDns) : undefined);
    message.cloudFlareDns !== undefined &&
      (obj.cloudFlareDns = message.cloudFlareDns ? CloudFlare.toJSON(message.cloudFlareDns) : undefined);
    message.route53Dns !== undefined &&
      (obj.route53Dns = message.route53Dns ? Route53.toJSON(message.route53Dns) : undefined);
    message.subdomain !== undefined && (obj.subdomain = message.subdomain);
    return obj;
  },

  fromPartial(object: DeepPartial<CreateRequest>): CreateRequest {
    const message = createBaseCreateRequest();
    message.name = object.name ?? "";
    message.numberOfNodes = object.numberOfNodes ?? 0;
    message.nodeSize = object.nodeSize ?? "";
    message.userId = object.userId ?? "";
    message.certbotAdminEmail = object.certbotAdminEmail ?? "";
    message.domain = object.domain ?? "";
    message.digitalOceanDns = (object.digitalOceanDns !== undefined && object.digitalOceanDns !== null)
      ? DigitalOcean.fromPartial(object.digitalOceanDns)
      : undefined;
    message.cloudFlareDns = (object.cloudFlareDns !== undefined && object.cloudFlareDns !== null)
      ? CloudFlare.fromPartial(object.cloudFlareDns)
      : undefined;
    message.route53Dns = (object.route53Dns !== undefined && object.route53Dns !== null)
      ? Route53.fromPartial(object.route53Dns)
      : undefined;
    message.subdomain = object.subdomain ?? "";
    return message;
  },
};

function createBaseCreateResponse(): CreateResponse {
  return {
    id: "",
    numberOfNodes: 0,
    nodeSize: "",
    createdAt: 0,
    name: "",
    status: StatusPretty.STATUS_PRETTY_UNSPECIFIED,
  };
}

export const CreateResponse = {
  encode(message: CreateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.numberOfNodes !== 0) {
      writer.uint32(16).int64(message.numberOfNodes);
    }
    if (message.nodeSize !== "") {
      writer.uint32(26).string(message.nodeSize);
    }
    if (message.createdAt !== 0) {
      writer.uint32(32).int64(message.createdAt);
    }
    if (message.name !== "") {
      writer.uint32(42).string(message.name);
    }
    if (message.status !== StatusPretty.STATUS_PRETTY_UNSPECIFIED) {
      writer.uint32(48).int32(statusPrettyToNumber(message.status));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.numberOfNodes = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.nodeSize = reader.string();
          break;
        case 4:
          message.createdAt = longToNumber(reader.int64() as Long);
          break;
        case 5:
          message.name = reader.string();
          break;
        case 6:
          message.status = statusPrettyFromJSON(reader.int32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateResponse {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      numberOfNodes: isSet(object.numberOfNodes) ? Number(object.numberOfNodes) : 0,
      nodeSize: isSet(object.nodeSize) ? String(object.nodeSize) : "",
      createdAt: isSet(object.createdAt) ? Number(object.createdAt) : 0,
      name: isSet(object.name) ? String(object.name) : "",
      status: isSet(object.status) ? statusPrettyFromJSON(object.status) : StatusPretty.STATUS_PRETTY_UNSPECIFIED,
    };
  },

  toJSON(message: CreateResponse): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.numberOfNodes !== undefined && (obj.numberOfNodes = Math.round(message.numberOfNodes));
    message.nodeSize !== undefined && (obj.nodeSize = message.nodeSize);
    message.createdAt !== undefined && (obj.createdAt = Math.round(message.createdAt));
    message.name !== undefined && (obj.name = message.name);
    message.status !== undefined && (obj.status = statusPrettyToJSON(message.status));
    return obj;
  },

  fromPartial(object: DeepPartial<CreateResponse>): CreateResponse {
    const message = createBaseCreateResponse();
    message.id = object.id ?? "";
    message.numberOfNodes = object.numberOfNodes ?? 0;
    message.nodeSize = object.nodeSize ?? "";
    message.createdAt = object.createdAt ?? 0;
    message.name = object.name ?? "";
    message.status = object.status ?? StatusPretty.STATUS_PRETTY_UNSPECIFIED;
    return message;
  },
};

function createBaseUpdateRequest(): UpdateRequest {
  return { id: "", numberOfNodes: 0, nodeSize: "", updatedAt: 0, secrets: "" };
}

export const UpdateRequest = {
  encode(message: UpdateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.numberOfNodes !== 0) {
      writer.uint32(16).int64(message.numberOfNodes);
    }
    if (message.nodeSize !== "") {
      writer.uint32(26).string(message.nodeSize);
    }
    if (message.updatedAt !== 0) {
      writer.uint32(32).int64(message.updatedAt);
    }
    if (message.secrets !== "") {
      writer.uint32(58).string(message.secrets);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.numberOfNodes = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.nodeSize = reader.string();
          break;
        case 4:
          message.updatedAt = longToNumber(reader.int64() as Long);
          break;
        case 7:
          message.secrets = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UpdateRequest {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      numberOfNodes: isSet(object.numberOfNodes) ? Number(object.numberOfNodes) : 0,
      nodeSize: isSet(object.nodeSize) ? String(object.nodeSize) : "",
      updatedAt: isSet(object.updatedAt) ? Number(object.updatedAt) : 0,
      secrets: isSet(object.secrets) ? String(object.secrets) : "",
    };
  },

  toJSON(message: UpdateRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.numberOfNodes !== undefined && (obj.numberOfNodes = Math.round(message.numberOfNodes));
    message.nodeSize !== undefined && (obj.nodeSize = message.nodeSize);
    message.updatedAt !== undefined && (obj.updatedAt = Math.round(message.updatedAt));
    message.secrets !== undefined && (obj.secrets = message.secrets);
    return obj;
  },

  fromPartial(object: DeepPartial<UpdateRequest>): UpdateRequest {
    const message = createBaseUpdateRequest();
    message.id = object.id ?? "";
    message.numberOfNodes = object.numberOfNodes ?? 0;
    message.nodeSize = object.nodeSize ?? "";
    message.updatedAt = object.updatedAt ?? 0;
    message.secrets = object.secrets ?? "";
    return message;
  },
};

function createBaseUpdateResponse(): UpdateResponse {
  return { id: "", numberOfNodes: 0, nodeSize: "", createdAt: 0, updatedAt: 0 };
}

export const UpdateResponse = {
  encode(message: UpdateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(18).string(message.id);
    }
    if (message.numberOfNodes !== 0) {
      writer.uint32(24).int64(message.numberOfNodes);
    }
    if (message.nodeSize !== "") {
      writer.uint32(34).string(message.nodeSize);
    }
    if (message.createdAt !== 0) {
      writer.uint32(40).int64(message.createdAt);
    }
    if (message.updatedAt !== 0) {
      writer.uint32(48).int64(message.updatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          message.id = reader.string();
          break;
        case 3:
          message.numberOfNodes = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.nodeSize = reader.string();
          break;
        case 5:
          message.createdAt = longToNumber(reader.int64() as Long);
          break;
        case 6:
          message.updatedAt = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UpdateResponse {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      numberOfNodes: isSet(object.numberOfNodes) ? Number(object.numberOfNodes) : 0,
      nodeSize: isSet(object.nodeSize) ? String(object.nodeSize) : "",
      createdAt: isSet(object.createdAt) ? Number(object.createdAt) : 0,
      updatedAt: isSet(object.updatedAt) ? Number(object.updatedAt) : 0,
    };
  },

  toJSON(message: UpdateResponse): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.numberOfNodes !== undefined && (obj.numberOfNodes = Math.round(message.numberOfNodes));
    message.nodeSize !== undefined && (obj.nodeSize = message.nodeSize);
    message.createdAt !== undefined && (obj.createdAt = Math.round(message.createdAt));
    message.updatedAt !== undefined && (obj.updatedAt = Math.round(message.updatedAt));
    return obj;
  },

  fromPartial(object: DeepPartial<UpdateResponse>): UpdateResponse {
    const message = createBaseUpdateResponse();
    message.id = object.id ?? "";
    message.numberOfNodes = object.numberOfNodes ?? 0;
    message.nodeSize = object.nodeSize ?? "";
    message.createdAt = object.createdAt ?? 0;
    message.updatedAt = object.updatedAt ?? 0;
    return message;
  },
};

function createBaseDestroyRequest(): DestroyRequest {
  return { id: "" };
}

export const DestroyRequest = {
  encode(message: DestroyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DestroyRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDestroyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DestroyRequest {
    return { id: isSet(object.id) ? String(object.id) : "" };
  },

  toJSON(message: DestroyRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial(object: DeepPartial<DestroyRequest>): DestroyRequest {
    const message = createBaseDestroyRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseDestroyResponse(): DestroyResponse {
  return { id: "", numberOfNodes: 0, nodeSize: "", createdAt: 0, updatedAt: 0 };
}

export const DestroyResponse = {
  encode(message: DestroyResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.numberOfNodes !== 0) {
      writer.uint32(16).int64(message.numberOfNodes);
    }
    if (message.nodeSize !== "") {
      writer.uint32(26).string(message.nodeSize);
    }
    if (message.createdAt !== 0) {
      writer.uint32(32).int64(message.createdAt);
    }
    if (message.updatedAt !== 0) {
      writer.uint32(40).int64(message.updatedAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DestroyResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDestroyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.numberOfNodes = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.nodeSize = reader.string();
          break;
        case 4:
          message.createdAt = longToNumber(reader.int64() as Long);
          break;
        case 5:
          message.updatedAt = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DestroyResponse {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      numberOfNodes: isSet(object.numberOfNodes) ? Number(object.numberOfNodes) : 0,
      nodeSize: isSet(object.nodeSize) ? String(object.nodeSize) : "",
      createdAt: isSet(object.createdAt) ? Number(object.createdAt) : 0,
      updatedAt: isSet(object.updatedAt) ? Number(object.updatedAt) : 0,
    };
  },

  toJSON(message: DestroyResponse): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.numberOfNodes !== undefined && (obj.numberOfNodes = Math.round(message.numberOfNodes));
    message.nodeSize !== undefined && (obj.nodeSize = message.nodeSize);
    message.createdAt !== undefined && (obj.createdAt = Math.round(message.createdAt));
    message.updatedAt !== undefined && (obj.updatedAt = Math.round(message.updatedAt));
    return obj;
  },

  fromPartial(object: DeepPartial<DestroyResponse>): DestroyResponse {
    const message = createBaseDestroyResponse();
    message.id = object.id ?? "";
    message.numberOfNodes = object.numberOfNodes ?? 0;
    message.nodeSize = object.nodeSize ?? "";
    message.createdAt = object.createdAt ?? 0;
    message.updatedAt = object.updatedAt ?? 0;
    return message;
  },
};

function createBaseWhereRequest(): WhereRequest {
  return {
    id: "",
    name: "",
    nodeSize: "",
    numberOfNodes: 0,
    clusterName: "",
    createdAt: undefined,
    updatedAt: undefined,
    destroyedAt: undefined,
    status: StatusPretty.STATUS_PRETTY_UNSPECIFIED,
    clusterId: "",
    userId: "",
  };
}

export const WhereRequest = {
  encode(message: WhereRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.nodeSize !== "") {
      writer.uint32(26).string(message.nodeSize);
    }
    if (message.numberOfNodes !== 0) {
      writer.uint32(32).int32(message.numberOfNodes);
    }
    if (message.clusterName !== "") {
      writer.uint32(42).string(message.clusterName);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(50).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(58).fork()).ldelim();
    }
    if (message.destroyedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.destroyedAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.status !== StatusPretty.STATUS_PRETTY_UNSPECIFIED) {
      writer.uint32(72).int32(statusPrettyToNumber(message.status));
    }
    if (message.clusterId !== "") {
      writer.uint32(82).string(message.clusterId);
    }
    if (message.userId !== "") {
      writer.uint32(90).string(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WhereRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWhereRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.name = reader.string();
          break;
        case 3:
          message.nodeSize = reader.string();
          break;
        case 4:
          message.numberOfNodes = reader.int32();
          break;
        case 5:
          message.clusterName = reader.string();
          break;
        case 6:
          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 7:
          message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 8:
          message.destroyedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 9:
          message.status = statusPrettyFromJSON(reader.int32());
          break;
        case 10:
          message.clusterId = reader.string();
          break;
        case 11:
          message.userId = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WhereRequest {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      name: isSet(object.name) ? String(object.name) : "",
      nodeSize: isSet(object.nodeSize) ? String(object.nodeSize) : "",
      numberOfNodes: isSet(object.numberOfNodes) ? Number(object.numberOfNodes) : 0,
      clusterName: isSet(object.clusterName) ? String(object.clusterName) : "",
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? fromJsonTimestamp(object.updatedAt) : undefined,
      destroyedAt: isSet(object.destroyedAt) ? fromJsonTimestamp(object.destroyedAt) : undefined,
      status: isSet(object.status) ? statusPrettyFromJSON(object.status) : StatusPretty.STATUS_PRETTY_UNSPECIFIED,
      clusterId: isSet(object.clusterId) ? String(object.clusterId) : "",
      userId: isSet(object.userId) ? String(object.userId) : "",
    };
  },

  toJSON(message: WhereRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.name !== undefined && (obj.name = message.name);
    message.nodeSize !== undefined && (obj.nodeSize = message.nodeSize);
    message.numberOfNodes !== undefined && (obj.numberOfNodes = Math.round(message.numberOfNodes));
    message.clusterName !== undefined && (obj.clusterName = message.clusterName);
    message.createdAt !== undefined && (obj.createdAt = message.createdAt.toISOString());
    message.updatedAt !== undefined && (obj.updatedAt = message.updatedAt.toISOString());
    message.destroyedAt !== undefined && (obj.destroyedAt = message.destroyedAt.toISOString());
    message.status !== undefined && (obj.status = statusPrettyToJSON(message.status));
    message.clusterId !== undefined && (obj.clusterId = message.clusterId);
    message.userId !== undefined && (obj.userId = message.userId);
    return obj;
  },

  fromPartial(object: DeepPartial<WhereRequest>): WhereRequest {
    const message = createBaseWhereRequest();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.nodeSize = object.nodeSize ?? "";
    message.numberOfNodes = object.numberOfNodes ?? 0;
    message.clusterName = object.clusterName ?? "";
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.destroyedAt = object.destroyedAt ?? undefined;
    message.status = object.status ?? StatusPretty.STATUS_PRETTY_UNSPECIFIED;
    message.clusterId = object.clusterId ?? "";
    message.userId = object.userId ?? "";
    return message;
  },
};

function createBaseWhereResponse(): WhereResponse {
  return { serviceGroups: [] };
}

export const WhereResponse = {
  encode(message: WhereResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.serviceGroups) {
      ShowResponse.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WhereResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWhereResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.serviceGroups.push(ShowResponse.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): WhereResponse {
    return {
      serviceGroups: Array.isArray(object?.serviceGroups)
        ? object.serviceGroups.map((e: any) => ShowResponse.fromJSON(e))
        : [],
    };
  },

  toJSON(message: WhereResponse): unknown {
    const obj: any = {};
    if (message.serviceGroups) {
      obj.serviceGroups = message.serviceGroups.map((e) => e ? ShowResponse.toJSON(e) : undefined);
    } else {
      obj.serviceGroups = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<WhereResponse>): WhereResponse {
    const message = createBaseWhereResponse();
    message.serviceGroups = object.serviceGroups?.map((e) => ShowResponse.fromPartial(e)) || [];
    return message;
  },
};

function createBaseClusterPriceHourly(): ClusterPriceHourly {
  return { total: 0, nodes: 0, volumes: 0, loadBalancer: 0 };
}

export const ClusterPriceHourly = {
  encode(message: ClusterPriceHourly, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.total !== 0) {
      writer.uint32(8).int64(message.total);
    }
    if (message.nodes !== 0) {
      writer.uint32(16).int64(message.nodes);
    }
    if (message.volumes !== 0) {
      writer.uint32(24).int64(message.volumes);
    }
    if (message.loadBalancer !== 0) {
      writer.uint32(32).int64(message.loadBalancer);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClusterPriceHourly {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClusterPriceHourly();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.total = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.nodes = longToNumber(reader.int64() as Long);
          break;
        case 3:
          message.volumes = longToNumber(reader.int64() as Long);
          break;
        case 4:
          message.loadBalancer = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ClusterPriceHourly {
    return {
      total: isSet(object.total) ? Number(object.total) : 0,
      nodes: isSet(object.nodes) ? Number(object.nodes) : 0,
      volumes: isSet(object.volumes) ? Number(object.volumes) : 0,
      loadBalancer: isSet(object.loadBalancer) ? Number(object.loadBalancer) : 0,
    };
  },

  toJSON(message: ClusterPriceHourly): unknown {
    const obj: any = {};
    message.total !== undefined && (obj.total = Math.round(message.total));
    message.nodes !== undefined && (obj.nodes = Math.round(message.nodes));
    message.volumes !== undefined && (obj.volumes = Math.round(message.volumes));
    message.loadBalancer !== undefined && (obj.loadBalancer = Math.round(message.loadBalancer));
    return obj;
  },

  fromPartial(object: DeepPartial<ClusterPriceHourly>): ClusterPriceHourly {
    const message = createBaseClusterPriceHourly();
    message.total = object.total ?? 0;
    message.nodes = object.nodes ?? 0;
    message.volumes = object.volumes ?? 0;
    message.loadBalancer = object.loadBalancer ?? 0;
    return message;
  },
};

function createBaseVolume(): Volume {
  return { size: 0 };
}

export const Volume = {
  encode(message: Volume, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.size !== 0) {
      writer.uint32(8).int64(message.size);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Volume {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVolume();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.size = longToNumber(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Volume {
    return { size: isSet(object.size) ? Number(object.size) : 0 };
  },

  toJSON(message: Volume): unknown {
    const obj: any = {};
    message.size !== undefined && (obj.size = Math.round(message.size));
    return obj;
  },

  fromPartial(object: DeepPartial<Volume>): Volume {
    const message = createBaseVolume();
    message.size = object.size ?? 0;
    return message;
  },
};

function createBaseCreateSecretRequest(): CreateSecretRequest {
  return { name: "", type: "", values: {}, namespace: "" };
}

export const CreateSecretRequest = {
  encode(message: CreateSecretRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.type !== "") {
      writer.uint32(18).string(message.type);
    }
    Object.entries(message.values).forEach(([key, value]) => {
      CreateSecretRequest_ValuesEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    if (message.namespace !== "") {
      writer.uint32(34).string(message.namespace);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateSecretRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateSecretRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.type = reader.string();
          break;
        case 3:
          const entry3 = CreateSecretRequest_ValuesEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.values[entry3.key] = entry3.value;
          }
          break;
        case 4:
          message.namespace = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateSecretRequest {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      type: isSet(object.type) ? String(object.type) : "",
      values: isObject(object.values)
        ? Object.entries(object.values).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      namespace: isSet(object.namespace) ? String(object.namespace) : "",
    };
  },

  toJSON(message: CreateSecretRequest): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined && (obj.type = message.type);
    obj.values = {};
    if (message.values) {
      Object.entries(message.values).forEach(([k, v]) => {
        obj.values[k] = v;
      });
    }
    message.namespace !== undefined && (obj.namespace = message.namespace);
    return obj;
  },

  fromPartial(object: DeepPartial<CreateSecretRequest>): CreateSecretRequest {
    const message = createBaseCreateSecretRequest();
    message.name = object.name ?? "";
    message.type = object.type ?? "";
    message.values = Object.entries(object.values ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.namespace = object.namespace ?? "";
    return message;
  },
};

function createBaseCreateSecretRequest_ValuesEntry(): CreateSecretRequest_ValuesEntry {
  return { key: "", value: "" };
}

export const CreateSecretRequest_ValuesEntry = {
  encode(message: CreateSecretRequest_ValuesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateSecretRequest_ValuesEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateSecretRequest_ValuesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateSecretRequest_ValuesEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: CreateSecretRequest_ValuesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(object: DeepPartial<CreateSecretRequest_ValuesEntry>): CreateSecretRequest_ValuesEntry {
    const message = createBaseCreateSecretRequest_ValuesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseCreateOrUpdateSecretsRequest(): CreateOrUpdateSecretsRequest {
  return { id: "", secrets: [] };
}

export const CreateOrUpdateSecretsRequest = {
  encode(message: CreateOrUpdateSecretsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    for (const v of message.secrets) {
      CreateSecretRequest.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrUpdateSecretsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrUpdateSecretsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.secrets.push(CreateSecretRequest.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateOrUpdateSecretsRequest {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      secrets: Array.isArray(object?.secrets) ? object.secrets.map((e: any) => CreateSecretRequest.fromJSON(e)) : [],
    };
  },

  toJSON(message: CreateOrUpdateSecretsRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    if (message.secrets) {
      obj.secrets = message.secrets.map((e) => e ? CreateSecretRequest.toJSON(e) : undefined);
    } else {
      obj.secrets = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<CreateOrUpdateSecretsRequest>): CreateOrUpdateSecretsRequest {
    const message = createBaseCreateOrUpdateSecretsRequest();
    message.id = object.id ?? "";
    message.secrets = object.secrets?.map((e) => CreateSecretRequest.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateOrUpdateSecretsResponse(): CreateOrUpdateSecretsResponse {
  return {};
}

export const CreateOrUpdateSecretsResponse = {
  encode(_: CreateOrUpdateSecretsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrUpdateSecretsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrUpdateSecretsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): CreateOrUpdateSecretsResponse {
    return {};
  },

  toJSON(_: CreateOrUpdateSecretsResponse): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<CreateOrUpdateSecretsResponse>): CreateOrUpdateSecretsResponse {
    const message = createBaseCreateOrUpdateSecretsResponse();
    return message;
  },
};

function createBaseSecretMetaData(): SecretMetaData {
  return { labels: {} };
}

export const SecretMetaData = {
  encode(message: SecretMetaData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.labels).forEach(([key, value]) => {
      SecretMetaData_LabelsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretMetaData {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretMetaData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          const entry1 = SecretMetaData_LabelsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.labels[entry1.key] = entry1.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SecretMetaData {
    return {
      labels: isObject(object.labels)
        ? Object.entries(object.labels).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: SecretMetaData): unknown {
    const obj: any = {};
    obj.labels = {};
    if (message.labels) {
      Object.entries(message.labels).forEach(([k, v]) => {
        obj.labels[k] = v;
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<SecretMetaData>): SecretMetaData {
    const message = createBaseSecretMetaData();
    message.labels = Object.entries(object.labels ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseSecretMetaData_LabelsEntry(): SecretMetaData_LabelsEntry {
  return { key: "", value: "" };
}

export const SecretMetaData_LabelsEntry = {
  encode(message: SecretMetaData_LabelsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretMetaData_LabelsEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretMetaData_LabelsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SecretMetaData_LabelsEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: SecretMetaData_LabelsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(object: DeepPartial<SecretMetaData_LabelsEntry>): SecretMetaData_LabelsEntry {
    const message = createBaseSecretMetaData_LabelsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseShowSecretResponse(): ShowSecretResponse {
  return { name: "", type: "", values: {}, namespace: "", metadata: undefined };
}

export const ShowSecretResponse = {
  encode(message: ShowSecretResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.type !== "") {
      writer.uint32(18).string(message.type);
    }
    Object.entries(message.values).forEach(([key, value]) => {
      ShowSecretResponse_ValuesEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    if (message.namespace !== "") {
      writer.uint32(34).string(message.namespace);
    }
    if (message.metadata !== undefined) {
      SecretMetaData.encode(message.metadata, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ShowSecretResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseShowSecretResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          message.type = reader.string();
          break;
        case 3:
          const entry3 = ShowSecretResponse_ValuesEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.values[entry3.key] = entry3.value;
          }
          break;
        case 4:
          message.namespace = reader.string();
          break;
        case 5:
          message.metadata = SecretMetaData.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ShowSecretResponse {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      type: isSet(object.type) ? String(object.type) : "",
      values: isObject(object.values)
        ? Object.entries(object.values).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      namespace: isSet(object.namespace) ? String(object.namespace) : "",
      metadata: isSet(object.metadata) ? SecretMetaData.fromJSON(object.metadata) : undefined,
    };
  },

  toJSON(message: ShowSecretResponse): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.type !== undefined && (obj.type = message.type);
    obj.values = {};
    if (message.values) {
      Object.entries(message.values).forEach(([k, v]) => {
        obj.values[k] = v;
      });
    }
    message.namespace !== undefined && (obj.namespace = message.namespace);
    message.metadata !== undefined &&
      (obj.metadata = message.metadata ? SecretMetaData.toJSON(message.metadata) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<ShowSecretResponse>): ShowSecretResponse {
    const message = createBaseShowSecretResponse();
    message.name = object.name ?? "";
    message.type = object.type ?? "";
    message.values = Object.entries(object.values ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    message.namespace = object.namespace ?? "";
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? SecretMetaData.fromPartial(object.metadata)
      : undefined;
    return message;
  },
};

function createBaseShowSecretResponse_ValuesEntry(): ShowSecretResponse_ValuesEntry {
  return { key: "", value: "" };
}

export const ShowSecretResponse_ValuesEntry = {
  encode(message: ShowSecretResponse_ValuesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ShowSecretResponse_ValuesEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseShowSecretResponse_ValuesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ShowSecretResponse_ValuesEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: ShowSecretResponse_ValuesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(object: DeepPartial<ShowSecretResponse_ValuesEntry>): ShowSecretResponse_ValuesEntry {
    const message = createBaseShowSecretResponse_ValuesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseSecretListRequest(): SecretListRequest {
  return { id: "", labelSelectors: [] };
}

export const SecretListRequest = {
  encode(message: SecretListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    for (const v of message.labelSelectors) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretListRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.labelSelectors.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SecretListRequest {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      labelSelectors: Array.isArray(object?.labelSelectors) ? object.labelSelectors.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: SecretListRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    if (message.labelSelectors) {
      obj.labelSelectors = message.labelSelectors.map((e) => e);
    } else {
      obj.labelSelectors = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<SecretListRequest>): SecretListRequest {
    const message = createBaseSecretListRequest();
    message.id = object.id ?? "";
    message.labelSelectors = object.labelSelectors?.map((e) => e) || [];
    return message;
  },
};

function createBaseSecretListResponse(): SecretListResponse {
  return { secrets: [] };
}

export const SecretListResponse = {
  encode(message: SecretListResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.secrets) {
      ShowSecretResponse.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretListResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretListResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.secrets.push(ShowSecretResponse.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SecretListResponse {
    return {
      secrets: Array.isArray(object?.secrets) ? object.secrets.map((e: any) => ShowSecretResponse.fromJSON(e)) : [],
    };
  },

  toJSON(message: SecretListResponse): unknown {
    const obj: any = {};
    if (message.secrets) {
      obj.secrets = message.secrets.map((e) => e ? ShowSecretResponse.toJSON(e) : undefined);
    } else {
      obj.secrets = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<SecretListResponse>): SecretListResponse {
    const message = createBaseSecretListResponse();
    message.secrets = object.secrets?.map((e) => ShowSecretResponse.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSecretShowRequest(): SecretShowRequest {
  return { id: "", secretName: "", namespace: "" };
}

export const SecretShowRequest = {
  encode(message: SecretShowRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.secretName !== "") {
      writer.uint32(18).string(message.secretName);
    }
    if (message.namespace !== "") {
      writer.uint32(26).string(message.namespace);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretShowRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretShowRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = reader.string();
          break;
        case 2:
          message.secretName = reader.string();
          break;
        case 3:
          message.namespace = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SecretShowRequest {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      secretName: isSet(object.secretName) ? String(object.secretName) : "",
      namespace: isSet(object.namespace) ? String(object.namespace) : "",
    };
  },

  toJSON(message: SecretShowRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.secretName !== undefined && (obj.secretName = message.secretName);
    message.namespace !== undefined && (obj.namespace = message.namespace);
    return obj;
  },

  fromPartial(object: DeepPartial<SecretShowRequest>): SecretShowRequest {
    const message = createBaseSecretShowRequest();
    message.id = object.id ?? "";
    message.secretName = object.secretName ?? "";
    message.namespace = object.namespace ?? "";
    return message;
  },
};

function createBaseSecretShowResponse(): SecretShowResponse {
  return { name: "", data: {} };
}

export const SecretShowResponse = {
  encode(message: SecretShowResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    Object.entries(message.data).forEach(([key, value]) => {
      SecretShowResponse_DataEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretShowResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretShowResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string();
          break;
        case 2:
          const entry2 = SecretShowResponse_DataEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.data[entry2.key] = entry2.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SecretShowResponse {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      data: isObject(object.data)
        ? Object.entries(object.data).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: SecretShowResponse): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    obj.data = {};
    if (message.data) {
      Object.entries(message.data).forEach(([k, v]) => {
        obj.data[k] = v;
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<SecretShowResponse>): SecretShowResponse {
    const message = createBaseSecretShowResponse();
    message.name = object.name ?? "";
    message.data = Object.entries(object.data ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseSecretShowResponse_DataEntry(): SecretShowResponse_DataEntry {
  return { key: "", value: "" };
}

export const SecretShowResponse_DataEntry = {
  encode(message: SecretShowResponse_DataEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SecretShowResponse_DataEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSecretShowResponse_DataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = reader.string();
          break;
        case 2:
          message.value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SecretShowResponse_DataEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: SecretShowResponse_DataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(object: DeepPartial<SecretShowResponse_DataEntry>): SecretShowResponse_DataEntry {
    const message = createBaseSecretShowResponse_DataEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

export type ApiDefinition = typeof ApiDefinition;
export const ApiDefinition = {
  name: "Api",
  fullName: "ServiceGroup.Api",
  methods: {
    /**
     * create a ServiceGroup, the base element for an
     * application created with App Lattice.
     */
    create: {
      name: "create",
      requestType: CreateRequest,
      requestStream: false,
      responseType: CreateResponse,
      responseStream: false,
      options: {},
    },
    destroy: {
      name: "destroy",
      requestType: DestroyRequest,
      requestStream: false,
      responseType: DestroyResponse,
      responseStream: false,
      options: {},
    },
    update: {
      name: "update",
      requestType: UpdateRequest,
      requestStream: false,
      responseType: ShowResponse,
      responseStream: false,
      options: {},
    },
    show: {
      name: "show",
      requestType: ShowRequest,
      requestStream: false,
      responseType: ShowResponse,
      responseStream: false,
      options: {},
    },
    showStream: {
      name: "showStream",
      requestType: ShowRequest,
      requestStream: false,
      responseType: ShowResponse,
      responseStream: true,
      options: {},
    },
    where: {
      name: "where",
      requestType: WhereRequest,
      requestStream: false,
      responseType: WhereResponse,
      responseStream: false,
      options: {},
    },
    createOrUpdateSecrets: {
      name: "createOrUpdateSecrets",
      requestType: CreateOrUpdateSecretsRequest,
      requestStream: false,
      responseType: CreateOrUpdateSecretsResponse,
      responseStream: false,
      options: {},
    },
    secretList: {
      name: "secretList",
      requestType: SecretListRequest,
      requestStream: false,
      responseType: SecretListResponse,
      responseStream: false,
      options: {},
    },
    secretShow: {
      name: "secretShow",
      requestType: SecretShowRequest,
      requestStream: false,
      responseType: SecretShowResponse,
      responseStream: false,
      options: {},
    },
  },
} as const;

export interface ApiServiceImplementation<CallContextExt = {}> {
  /**
   * create a ServiceGroup, the base element for an
   * application created with App Lattice.
   */
  create(request: CreateRequest, context: CallContext & CallContextExt): Promise<DeepPartial<CreateResponse>>;
  destroy(request: DestroyRequest, context: CallContext & CallContextExt): Promise<DeepPartial<DestroyResponse>>;
  update(request: UpdateRequest, context: CallContext & CallContextExt): Promise<DeepPartial<ShowResponse>>;
  show(request: ShowRequest, context: CallContext & CallContextExt): Promise<DeepPartial<ShowResponse>>;
  showStream(
    request: ShowRequest,
    context: CallContext & CallContextExt,
  ): ServerStreamingMethodResult<DeepPartial<ShowResponse>>;
  where(request: WhereRequest, context: CallContext & CallContextExt): Promise<DeepPartial<WhereResponse>>;
  createOrUpdateSecrets(
    request: CreateOrUpdateSecretsRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<CreateOrUpdateSecretsResponse>>;
  secretList(
    request: SecretListRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<SecretListResponse>>;
  secretShow(
    request: SecretShowRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<SecretShowResponse>>;
}

export interface ApiClient<CallOptionsExt = {}> {
  /**
   * create a ServiceGroup, the base element for an
   * application created with App Lattice.
   */
  create(request: DeepPartial<CreateRequest>, options?: CallOptions & CallOptionsExt): Promise<CreateResponse>;
  destroy(request: DeepPartial<DestroyRequest>, options?: CallOptions & CallOptionsExt): Promise<DestroyResponse>;
  update(request: DeepPartial<UpdateRequest>, options?: CallOptions & CallOptionsExt): Promise<ShowResponse>;
  show(request: DeepPartial<ShowRequest>, options?: CallOptions & CallOptionsExt): Promise<ShowResponse>;
  showStream(request: DeepPartial<ShowRequest>, options?: CallOptions & CallOptionsExt): AsyncIterable<ShowResponse>;
  where(request: DeepPartial<WhereRequest>, options?: CallOptions & CallOptionsExt): Promise<WhereResponse>;
  createOrUpdateSecrets(
    request: DeepPartial<CreateOrUpdateSecretsRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<CreateOrUpdateSecretsResponse>;
  secretList(
    request: DeepPartial<SecretListRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<SecretListResponse>;
  secretShow(
    request: DeepPartial<SecretShowRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<SecretShowResponse>;
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export type ServerStreamingMethodResult<Response> = { [Symbol.asyncIterator](): AsyncIterator<Response, void> };
