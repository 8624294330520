import { ShowResponse } from '../../tsProtoCodegen/user';

const getUser = (user: ShowResponse | null): ShowResponse => {
  const error =
    'There is an issue with the way your account has been configured. Please contact support.';

  if (!user) {
    throw new Error(error);
  }

  return user;
};
export default getUser;
