import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

// define custom styles for funky variant
const bigText = {
  container: {
    height: '100%',
    padding: 4,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '5px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'brand.purple.100',
      borderRadius: '24px',
    },
  },
};
const variants = {
  bigText: definePartsStyle(bigText),
  bigTextDashed: definePartsStyle({
    ...bigText,
    container: {
      ...bigText.container,
      backgroundColor: 'none',
      borderStyle: 'dashed',
      borderColor: 'brand.purple.200',
      borderWidth: 2,
    },
  }),
  outline: definePartsStyle({
    container: {
      h: '100%',
      backgroundColor: 'none',
      overflow: 'auto',
      borderColor: 'brand.purple.600',
      '&::-webkit-scrollbar': {
        width: '5px',
        height: '6px',
      },
      '&::-webkit-scrollbar-track': {
        width: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'brand.purple.100',
        borderRadius: '24px',
      },
    },
  }),
  bigTextClickable: definePartsStyle({
    ...bigText,
    container: {
      ...bigText.container,
      bg: 'brand.purple.500',
      transition: '300ms',
      _hover: {
        bg: 'brand.purple.700',
      },
    },
  }),
};
export const cardTheme = defineMultiStyleConfig({
  baseStyle: {
    container: {
      color: 'brand.purple.200',
      backgroundColor: 'brand.purple.800',
    },
    body: {
      fontSize: 'lg',
    },
  },
  variants,
});
