import { createAsyncThunk } from '@reduxjs/toolkit';
import { PaymentMethod } from './types';
import billingClient from '../../billingClient';
import { ClientError, Status } from 'nice-grpc-common';

export const fetchPaymentMethodsThunk = createAsyncThunk<
  PaymentMethod[] | null,
  string,
  { rejectValue: string }
>('paymentMethod/fetchPaymentMethods', async (stripeCustomerId) => {
  try {
    return [
      await billingClient.showDefaultPaymentMethod({
        stripeCustomerId,
      }),
    ];
  } catch (e) {
    if (e instanceof ClientError && e.code === Status.NOT_FOUND) {
      return [];
    }

    throw e;
  }
});

export default fetchPaymentMethodsThunk;
