import { ShowResponse } from '../../../tsProtoCodegen/ServiceGroup';
import grpcClient from '../grpcClient';
import metadataWithJwt from '../../../utils/metadataWithJwt';

export type FetchStatus = {
  initiated: boolean | null;
  success: boolean | null;
  error: string | null;
};

export type ServiceGroupFetchRes = {
  status: FetchStatus;
  serviceGroup: ShowResponse | null;
};

export const defaultValue: ServiceGroupFetchRes = {
  status: {
    initiated: false,
    success: null,
    error: null,
  },
  serviceGroup: null,
};

const serviceGroupFetch = async (
  id: string,
  cbf: (serviceGroup?: ShowResponse, error?: string) => void
): Promise<void> => {
  const metadata = metadataWithJwt();

  try {
    const serviceGroup = await grpcClient.show({ id }, { metadata });

    cbf(serviceGroup);
  } catch (e) {
    cbf(undefined, (e as Error).message);
  }
  // for await (const serviceGroup of grpcClient.showStream(
  //   { id },
  //   { metadata }
  // )) {
  //   cbf(serviceGroup);
  // }
};

export default serviceGroupFetch;
