import React, { ReactElement } from 'react';
import centsToDollars from '../../../utils/centsToDollars';
import { Balance } from '../useBalance';

const RemainingFunds: React.FC<Balance> = ({ loading, error, data }): ReactElement => {
  if (loading) return <div>Loading...</div>;

  if (error) return <div>{error}</div>;

  if (!data) throw new Error('Something weird happened');

  const { balance } = data;

  return <div><strong>Remaining Funds: </strong>{centsToDollars(balance).pretty}</div>;
};

export default RemainingFunds;
