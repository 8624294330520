import React, { ReactElement } from 'react';
import { Control, UseFormRegister, useFieldArray } from 'react-hook-form';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select
} from '@chakra-ui/react';
import { CreateRequest, PortProtocol } from '../../../../tsProtoCodegen/service';
import { colors } from '../../../../theme/theme';

const PortMappings: React.FC<{ control: Control<CreateRequest>, register: UseFormRegister<CreateRequest> }> = ({ control, register }): ReactElement => {

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'portMappings',
  });

  return (
    <>
      {fields.map((field, index) => {
        return (
          <Box
            key={field.id}
            border='1px solid'
            p={2}
            mt={2}
            mb={2}
            borderColor={colors.brand.purple[900]}
            borderRadius={5}
          >
            <Grid gap={2} templateColumns='repeat(12, 1fr)' >
              <GridItem colSpan={8}>
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input
                    type="text"
                    id={`portMappings.${index}.name`}
                    {...register(`portMappings.${index}.name`, { required: true })}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={4}>
                <FormControl>
                  <FormLabel>Protocol</FormLabel>

                  <Select
                    defaultValue='TCP'
                    id={`portMappings.${index}.protocol`}
                    {...register(`portMappings.${index}.protocol`, { required: true })}
                  >
                    <option value="TCP">TCP</option>
                    <option value="UPD">UPD</option>
                  </Select>
                </FormControl>
              </GridItem>

              <GridItem colSpan={6}>
                <FormControl>
                  <FormLabel>Destination Port</FormLabel>
                  <Input
                    placeholder='port'
                    type="number"
                    id={`portMappings.${index}.port`}
                    {...register(`portMappings.${index}.port`, { required: true })}
                  />
                </FormControl>
              </GridItem>

              <GridItem colSpan={6}>
                <FormControl>
                  <FormLabel>Container Port</FormLabel>
                  <Input
                    placeholder='target port'
                    type="number"
                    id={`portMappings.${index}.target`}
                    {...register(`portMappings.${index}.target`, { required: true })}
                  />
                </FormControl>
              </GridItem>

            </Grid>
            <Box textAlign='right'>
              <Button variant='link' type="button" onClick={() => remove(index)}>Remove</Button>
            </Box>
          </Box>
        );
      })}
      <Button borderRadius='full' onClick={() => append({ target: 0, port: 0, protocol: PortProtocol.TCP, name: '' })}>
        +
      </Button>
      <button type='submit' />
    </>
  );
};

export default PortMappings;
