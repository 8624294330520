import React, { ReactElement } from 'react';
import copy from '../../../utils/copy';
import { Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const ErrorElem: React.FC<{ error: string | null }> = ({ error }): ReactElement => {
  if (!error) return <></>;

  if (error.includes('Balance too low')) {
    return <>Cannot create {copy.entities.ServiceGroup} because of low balance. <Button><Link to='/billing'>Deposit Funds</Link></Button></>;

  }


  return <>There was an issue creating the {copy.entities.ServiceGroup}. Please try submitting the form again.</>;
};

export default ErrorElem;
