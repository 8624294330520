import React, { ReactElement } from 'react';
import Create from './Create';
import List from './List';
import { PaymentMethodState } from './redux/types';

const PaymentMethods: React.FC<{
  stripeCustomerId: string,
  paymentMethodState: PaymentMethodState,
}> = (params): ReactElement => {
  const { stripeCustomerId, paymentMethodState: { paymentMethods, loading, error } } = params;

  if (loading) return <div>Loading payment methods...</div>;

  if (paymentMethods === null || paymentMethods.length === 0) {
    return <Create stripeCustomerId={stripeCustomerId as string} />;
  }

  if (error) return <div>{error}</div>;

  return <List paymentMethods={paymentMethods} />;
};

export default PaymentMethods;

